<template>
  <div>我的群组</div>
</template>

<script>
export default {
  name: "MyGroup"
}
</script>

<style scoped>

</style>
