<template>
  <div class="template">
    <el-row class="el-rows" justify="center" type="flex">
      <el-col :lg="10" :md="14" :sm="18" :xl="13" :xs="22">
        <el-autocomplete
          v-model="searchContent"
          :fetch-suggestions="querySearch"
          class="inline-input"
          clearable
          placeholder="请输入内容"
          @select="valueFun($event)"
          @keyup.enter.native="handleSearch"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSearch"
          ></el-button>
          <!-- 搜索历史记录  开始-->
          <template slot-scope="{ item }" class="muban">
            <div v-if="isShowHistory && item.id == historyRecordId - 1" class="history">
              <div class="history-text" @click.stop="stop">搜索记录</div>
              <div class="delete-history" @click="deleteHistory">清空记录</div>
            </div>
            <div v-if="isShowHistory && item.id == historyRecordId - 1">
              <div class="history-text">
                用于撑开选项，防止历史记录选项被挡住
              </div>
            </div>
            <div class="name">
              <div>{{ item.value }}</div>
            </div>
          </template>
          <!-- 搜索历史记录  结束-->
        </el-autocomplete>
      </el-col>
    </el-row>
    <!-- 模板标题 开始 -->
    <!--<div class="template-box">-->
    <!--  <el-row class="el-rows" justify="center" type="flex">-->
    <!--    &lt;!&ndash; <el-col :xs="24" :sm="20" :md="18" :lg="16" :xl="12"> &ndash;&gt;-->
    <!--    <el-col class="el_col" :lg="12" :md="16" :sm="20" :xl="10" :xs="24">-->
    <!--      <div class="templateTitle">-->
    <!--        <div-->
    <!--          v-for="(item, index) in templateTitleList"-->
    <!--          :key="index"-->
    <!--          :class="{ active: item.id == activeId ,hoverActive: hoverActiveIndex===index}"-->
    <!--          :style="{ color: item.id == activeId ? '#18c631' : '' }"-->
    <!--          class="templateTitleItem"-->
    <!--          @click="jumpTemplate(item.id)"-->
    <!--          @mousemove="cgHoverActiveIndex(index)"-->
    <!--        >-->
    <!--          {{ item.text }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </el-col>-->
    <!--  </el-row>-->
    <!--</div>-->
    <!-- 模板标题 结束 -->
    <!-- 模板内容 开始 -->
    <!--<div class="templateContent-box">
      <TemplateContent ></TemplateContent>
    </div>-->
    <TemplateContent></TemplateContent>
    <!-- 模板内容 开始 -->

  </div>
</template>

<script>
import TemplateContent from '@/components/TemplateContent';

export default {
  name: 'Template',
  components: {TemplateContent},
  data() {
    return {

      historyRecordList: [],
      searchContent: '',
      isShowHistory: true, //是否显示 搜索记录
      isClickHistory: false, //是否点击清空记录
      historyRecordId: 1,
      // templateTitleList: [],
      // activeId: 3, //选中模板的id
      // hoverActiveIndex: -1,//移入标题的索引
    };
  },
  created() {
    // this.getTemplateTitle();

  },
  methods: {
    /**
     * 查询 搜索
     * @param queryString 查询字符串
     * @param cb 调用 callback 返回建议列表的数据
     */
    querySearch(queryString, cb) {
      // console.log('点击3', this.searchContent);
      // console.log('historyRecordList ==>', historyRecordList);
      this.isShowHistory = this.searchContent?.trim().length === 0;
      let historyRecordList = this.historyRecordList;
      let results = queryString
        ? historyRecordList.filter(this.createFilter(queryString))
        : historyRecordList;
      // console.log('results ==>', results);
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      // console.log('点击2');
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSearch() {
      console.log('点击1');
      if (!this.searchContent.trim()) return alert('输入不能为空');
      if (
        !this.historyRecordList.some(
          (item) => item.value === this.searchContent
        )
      ) {
        // this.historyRecordList.splice(1,0,{ value: this.searchContent,id:this.historyRecordId++ });
        this.historyRecordList.unshift({
          value: this.searchContent,
          id: this.historyRecordId++,
        });
        localStorage.setItem(
          'historyRecordList',
          JSON.stringify(this.historyRecordList)
        );
      } else {
        console.log('历史记录中已存在:', this.searchContent);
      }
    },
    /**
     * 点击历史记录选项
     */
    valueFun(e) {
      console.log('点击', e);
      if (this.isClickHistory) {
        this.searchContent = '';
        this.isClickHistory = false;
      }
    },
    /**
     * 阻止事件冒泡
     */
    stop() {},
    /**
     * 清除历史记录
     */
    deleteHistory() {
      this.historyRecordList = [];
      localStorage.removeItem('historyRecordList');
      this.isClickHistory = true;
      console.log('列表 ==>', this.historyRecordList);
    },
  },
  mounted() {
    let historyRecordList =
      JSON.parse(localStorage.getItem('historyRecordList')) || [];
    if (historyRecordList.length != 0) {
      this.historyRecordId = historyRecordList[0].id + 1;
    }
    this.historyRecordList = historyRecordList;
  },
};
</script>
<style lang="scss" scoped>
.inline-input {
  width: 100%;

  .css {
    width: 100px;
  }
}

// .template{
//   /deep/ .el-scrollbar__view {
//     position: relative;
//   }
// }
.history {
  width: 100%;
  height: 44px;
  line-height: 34px;
  font-size: 14px;
  box-sizing: border-box;
  padding: 0 20px;
  padding-top: 10px;
  display: flex;
  background-color: white;
  position: absolute;
  top: 0px;
  left: 0;

  .history-text {
    color: #b5b7b7;
    flex: 1;
  }

  .delete-history {
    margin-left: auto;
    color: #337ab7;

    &:hover {
      color: #3baff3;
    }
  }
}

.el-rows {
  background-color: #E9EEF3;
  padding: 20px 0;
  margin-top: -20px;

}

.template-box {
  .el-rows {
    background-color: #E9EEF3;
    padding-bottom: 2px;

    .el-col {
      width: 70% !important;
    }

    .templateTitle {
      height: 30px;
      line-height: 30px;
      background-color: #E9EEF3;
      text-align: center;
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: space-between;

      .templateTitleItem {
        flex: 1;
      }
    }

    .templateContent {
      background-color: white;
    }
  }

}

.active {
  &:after {
    content: '';
    display: block;
    width: 80%;
    height: 2px;
    transform: translate(12.5%);
    background-color: #18c631;
  }
}
</style>

