import Vue from 'vue'
import VueRouter from 'vue-router'
import XiaoShenQi from '@/views/XiaoShenQi'
// import Home from '@/views/XiaoShenQi/childComps/Home'
import FirstHome from '@/views/XiaoShenQi/childComps/FirstHome' //首页
import Template from '@/views/XiaoShenQi/childComps/Template'
import ReleaseSolitaire from '@/views/Solitaire/childComps/ReleaseSolitaire'
import MySolitaire from '@/views/Solitaire/childComps/MySolitaire'
import MyGroup from '@/views/Solitaire/childComps/MyGroup'
import CreateSolitairePage from '@/views/Solitaire/childComps/CreateSolitairePage'
import TypeTemplate from '@/views/TemplateRouter/TypeTemplate'
// import ScanCodeToLogIn from '@/views/XiaoShenQi/childComps/ScanCodeToLogIn'
import Solitaire from '@/views/Solitaire'
import SolitaireDetails from '@/components/SolitaireDetails'
// import {getLocalStorage} from '@/assets/js/tools/common'; // 管理我的接龙页面
// import { templateTitleData } from '@/tempData/templateData' // 模板标题子路由路径
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'FirstHome',
    component: FirstHome,
  },
  {
    path: '/xiaoshenqi',
    name: 'XiaoShenQi',
    component: XiaoShenQi,
    redirect: {name: 'Template'}, //重定向路由(指向子路由第一个路由 Home)
    children: [
      // {
      //   path: '/',
      //   name: 'Home',
      //   component: Home,
      // },
      // {
      //   path: '/scanCodeToLogIn',
      //   name: 'ScanCodeToLogIn',
      //   component: ScanCodeToLogIn,
      // },
      {
        path: 'template',
        name: 'Template',
        component: Template,
        // redirect: { name: 'TemplateTitle', params: { id: 3 } }, //重定向路由(指向子路由第一个路由 All)
        // 路由级别代码分割
        // 这会为此路由生成一个单独的块 (Template.[hash].js)
        // 这是在访问路由时延迟加载的
        // children: templateTitleData,
        // children: [
        //   {
        //     path:'templateTitle/:id',
        //     name:'TemplateTitle',
        //     component:TemplateTitle
        //   },
        // ]
      },
    ],
  },
  {
    path: '/solitaire',
    name: 'Solitaire',
    redirect: {name: 'ReleaseSolitaire'},
    component: Solitaire,
    children: [
      {
        path: 'releasesolitaire',
        name: 'ReleaseSolitaire',
        component: ReleaseSolitaire,
        meta: {
          keepAlive: true
        }
      },
      {
        path: 'mysolitaire',
        name: 'MySolitaire',
        component: MySolitaire,
        meta: {
          keepAlive: true
        }
      },
      {
        path: 'mygroup',
        name: 'MyGroup',
        component: MyGroup,
        meta: {
          keepAlive: true
        }
      },
    ]
  },
  {
    path: '/typetemplate/:solitaireType',
    name: 'TypeTemplate',
    component: TypeTemplate,
  },
  {
    path: '/createSolitairePage/:solitaireType',
    name: 'CreateSolitairePage',
    component: CreateSolitairePage,
  },
  {
    path: '/SolitaireDetails/:solitaireType',
    name: 'SolitaireDetails',
    component: SolitaireDetails,
  }
]
const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
})


// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }
export default router
