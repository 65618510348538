<template>
  <div class="main-content">
    <div class="release-solitaire">
      <div class="title">发布接龙</div>
      <div class="typeTemplate">
        <div class="template"
             @click="jumpXzPage(item.solitaireType)"
             :style="{borderTop:`4px solid ${item.color}`}"
             v-for="(item,index) in solitaireTypeList"
             :key="index">
          <img :src="item.image" alt="">
          <div class="name">{{ item.title }}</div>
          <div class="tips">{{ item.tips }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SOLITAIRE_TYPE_LIST} from '@/tempData/solitaireTempData';

export default {
  name: 'ReleaseSolitaire',
  data(){
    return {
      solitaireTypeList:SOLITAIRE_TYPE_LIST,
    }
  },
  methods:{
    /**
     * 点击发布接龙模板
     */
    jumpXzPage(solitaireType){
      console.log(solitaireType);
      this.$router.push({name:'TypeTemplate',params:{solitaireType}})
    },
  },

}
</script>

<style lang="scss" scoped>
.main-content {
  .release-solitaire {
    .title {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .typeTemplate {
      display: flex;
      flex-wrap: wrap;
      margin-left: 18px;
      .template {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 225px;
        height: 160px;
        background: #fff;
        border-radius: 2px;
        overflow: hidden;
        margin: 0 16px 24px 0;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 8px 15px #b8b8b8;
        }

        &.fk {
          border-top: 4px solid #5a89f1;
        }

        &.dk {
          border-top: 4px solid #34bcff;
        }

        &.tb {
          border-top: 4px solid #9690ff;
        }

        &.tp {
          border-top: 4px solid #42d4b0;
        }

        &.hd {
          border-top: 4px solid #6398f6;
        }

        img {
          width: 50px;
          height: 50px;
          margin-bottom: 16px;
        }

        .name {
          font-size: 14px;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.85);
          line-height: 22px;
        }

        .tips {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 20px;
          margin: 3px 0 8px;
        }
      }
    }
  }
}
</style>
