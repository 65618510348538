<!--该插件的官网地址 https://github.com/kutlugsahin/vue-smooth-dnd -->
<!--参考的使用文档   https://juejin.cn/post/6989082141933961246-->
<template>
  <div class="simple-page">
    <Container @drop="onDrop" :drag-handle-selector="selector">
      <Draggable v-for="(item,index) in list" :key="item.id">
        <slot name="contentIntroduction" :item="item" :index="index"></slot>
      </Draggable>
    </Container>
  </div>
</template>

<script>
import {Container, Draggable} from 'vue-smooth-dnd'


export default {
  name: 'Drag',
  components: {Container, Draggable},
  props: {
    selector: { //可代表整个拖拽元素进行移动元素选择器  如 .move
      type: String
    },
    list: {
      type: Array
    }
  },
  data() {
    return {}
  },
  methods: {
    /**
     * 拖动位置，并且排序成功执行
     */
    onDrop(dropResult) {
      console.log('dropResult ==>', dropResult)
      let removedIndex = dropResult.removedIndex
      let addedIndex = dropResult.addedIndex
      if (addedIndex === removedIndex) return //移动的位置不变
      //修改
      this.$emit('cgSort', {removedIndex, addedIndex})
    },
  }
}
</script>

<style scoped>

</style>
