<template>
  <div class="list">
    <template v-if="solitaireObj.solitaireType !== 4">
      <!--按名单-->
      <div class="left">按名单考试</div>
      <div class="right">

        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link isnameclock_text" style="font-size: 16px">
            {{ solitaireObj.isnameclock === 3 ? '不启用' : solitaireObj.isnameclock === 2 ? '设定编号' : '设定姓名' }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="3">不启用</el-dropdown-item>
            <el-dropdown-item :command="1">设定姓名</el-dropdown-item>
            <el-dropdown-item :command="2">设定编号</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>


        <!--不启用-->
        <!--<div class="notEnabled" v-if="solitaireObj.isnameclock === 3">-->
        <!--  不启用-->
        <!--</div>-->
        <div class="setName" v-if="solitaireObj.isnameclock === 1">
          <!--<div class="typeText">设定姓名</div>-->
          <div class="textarea">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 6}"
              placeholder="请输入名单"
              :value="solitaireObj.namelist"
              @input="cgNamelistFun">
            </el-input>
          </div>
        </div>
        <div class="setNumber" v-else-if="solitaireObj.isnameclock === 2">
          <div class="content">
            <div class="contentText">编号范围</div>
            <div class="box">
              <el-input v-model="solitaireObj.numberLimits" placeholder="请输入编号范围"></el-input>
            </div>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
export default {
  name: 'ListOfName',
  data() {
    return {
      solitaireObj: {},
      pureNameOrNumberName: false,//false：不是设定姓名 pureName：纯姓名 numberName：姓名加编号
    }
  },
  mounted() {
    this.solitaireObj = this.$store.state.solitaireObj
    if (this.solitaireObj.isnameclock === 1) {
      this.pureNameOrNumberName = this.isNameAndNumber(this.solitaireObj.namelist) ? 'numberName' : 'pureName'
    }
  },
  methods: {

    /**
     * 判断是否姓名加编号
     */
    isNameAndNumber(namelist) {
      return /[&#＆＃]/ig.test(namelist)
    },
    /**
     * 输入考试名单 输入框时触发 input事件
     */
    cgNamelistFun(e) {
      // console.log("e ==>",e)
      let solitaireObj = this.solitaireObj
      let _isNameAndNumber = this.isNameAndNumber(e)//是否是姓名加编号
      if (solitaireObj.isnameclock === 1) {
        if (_isNameAndNumber && this.pureNameOrNumberName === 'pureName') {//第一次检测到姓名加编号
          this.addBasicInformation(1, '编号', true)//添加《编号》的信息项
          this.pureNameOrNumberName = 'numberName'
        } else if (!_isNameAndNumber && this.pureNameOrNumberName === 'numberName') {//第一次检测到纯姓名
          this.delBasicInformatio(1)//删除《编号》的信息项
          this.pureNameOrNumberName = 'pureName'
        }
      }
      this.solitaireObj.namelist = e
    },
    /**
     * 添加基本信息（姓名 | 编号）
     * @start 从第几个开始添加
     * @typeText 添加的信息内容
     * @requiredFields  添加的信息内容是否必填
     */
    addBasicInformation(start, typeText, requiredFields) {
      this.solitaireObj.basicInfoObj.basicInfoList.splice(start, 0, {default: true, typeText, value: '', requiredFields, type: typeText})
      // this.list.splice(start, 0, {default: true, typeText, value: '', requiredFields, type: typeText})

    },
    // 删除《编号》基本信息
    delBasicInformatio(start) {
      // console.log("this.list ==>",this.list)
      this.solitaireObj.basicInfoObj.basicInfoList.splice(start, 1)
    },

    handleCommand(newIsnameclock) {
      console.log('newIsnameclock ==>', newIsnameclock)
      if (this.solitaireObj.isnameclock === newIsnameclock) return;
      let solitaireObj = this.solitaireObj
      // this.solitaireObj.isnameclock = isnameclock

      let oldIsnameclock = solitaireObj.isnameclock
      // let basicInfoList = solitaireObj.basicInfoObj.basicInfoList
      let isNameAndNumber = this.isNameAndNumber(solitaireObj.namelist) //是否姓名加编号

      // let optype = this.data.optype
      if (newIsnameclock === 1) {//点击设定姓名
        if (oldIsnameclock === 3) {//不启用名单转跳过来的
          this.delBasicInformatio(0)//删除《姓名》信息项（主要是修改，是否必填（requireFields） 的值）
          this.addBasicInformation(0, '姓名', true)//添加《姓名》信息项
          if (isNameAndNumber) {//姓名加编号
            this.addBasicInformation(1, '编号', true)//添加《编号》信息项
          }
        } else if (oldIsnameclock === 2 && !isNameAndNumber) {//启用编号跳转过来的
          //且姓名列表是纯姓名的话，删除《编号》信息项
          this.delBasicInformatio(1)
        }
        // console.log('solitaireObj.elementConfiglist ==>', solitaireObj.elementConfiglist)
        this.pureNameOrNumberName = isNameAndNumber ? 'numberName' : 'pureName'

      } else if (newIsnameclock === 2) {//点击设定编号
        if (oldIsnameclock === 1 && !isNameAndNumber) {//启用姓名名单转跳过来的
          //纯姓名名单列表跳转过来时，需要中添加《编号》信息项
          this.addBasicInformation(1, '编号', true)//添加《编号》信息项
        } else if (oldIsnameclock === 3) {//不启用名单跳转过来的
          this.delBasicInformatio(0)//删除《姓名》信息项（主要是修改，是否必填（requireFields） 的值）
          this.addBasicInformation(0, '姓名', true)//添加《姓名》信息项
          this.addBasicInformation(1, '编号', true)//添加《编号》信息项
        }


      } else {//点击不启用
        // 不管是从哪里跳转过来的都有姓名这个信息项(但不是必填)
        //启用姓名名单转跳过来的 且 姓名加编号名单列表跳转过来时，需要删除《编号》表单项 或者  //启用编号名单跳转过来的
        this.delBasicInformatio(0)//删除《姓名》信息项（主要是修改，是否必填（requireFields） 的值）
        this.addBasicInformation(0, '姓名', false)//添加《姓名》信息项
        if ((oldIsnameclock === 1 && isNameAndNumber) || oldIsnameclock === 2) {
          this.delBasicInformatio(1)//删除《编号》信息项
        }
      }
      // }
      solitaireObj.isnameclock = newIsnameclock
    },
  }

}
</script>

<style lang="scss" scoped>
.list {
  background-color: #ebedf0;
  text-align: left;
  //width: 80%;
  padding: 20px;
  margin: 20px auto auto;
  display: flex;

  .left {
    margin-right: 20px;
  }

  .right {
    flex: 1;

    .isnameclock_text {
      font-size: 16px;
      cursor: pointer;
    }

    .setName {
      .textarea {
        margin-top: 20px;
        width: 100%;
      }
    }

    .setNumber {
      .content {
        display: flex;
        margin-top: 10px;

        .contentText {
          margin-right: 20px;
          line-height: 40px;
          //flex: 1;
        }
      }
    }

    .box {
      //width: 100%;
      //box-sizing: border-box;
      flex: 1;
      //min-height: 16px;
      //line-height: 16px;
      //padding: 6px 10px;
      //border: 1px solid silver;
      ////margin-top: 14px;
      //border-radius: 4px;
    }
  }
}
</style>
<style>
.el-dropdown {
  vertical-align: top;
}
</style>
