<template>
  <div class="publish">
    <el-container>
      <!-- 顶部导航栏 -->
      <el-header>
        <div class="header">
          <div class="header-img">
            <el-image
              style="width: 40px; height: 40px"
              :src="urlList[0]"
              fit="contain"></el-image>
          </div>
          <div class="business">
            <el-menu default-active="1" class="el-menu-demo" mode="horizontal">
              <el-submenu index="1">
                <template slot="title">联系客服</template>
                <div class="lxkf">
                  <el-image
                    :src="urlList[1]"
                    fit="contain"></el-image>
                </div>
              </el-submenu>
              <el-submenu index="2">
                <template slot="title">小程序</template>
                <div class="lxkf">
                  <el-image
                    :src="urlList[2]"
                    fit="contain"></el-image>
                </div>
              </el-submenu>
              <el-submenu index="3">
                <template slot="title"><img :src="userInfo.headimgurl" alt="" /><span class="nameSpan">{{ userInfo.nickname }}</span></template>
                <!--<el-menu-item index="3-1">帮助文档</el-menu-item>-->
                <!--<el-menu-item index="3-2">产品交流</el-menu-item>-->
                <el-menu-item index="3-1" @click="signOutFun">退出</el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
        </div>
      </el-header>
      <el-container class="container">
        <!-- 左侧导航 -->
        <el-aside width="150px" class="aside">
          <div
            class="type"
            :class="{ active: activeIndex === index }"
            v-for="(item, index) in typeList"
            @click="clickType(index)"
            :key="index"
          >
            {{ item }}
          </div>
          <!-- <div>回收站</div> -->
        </el-aside>
        <!-- 右侧（左侧导航内容） -->
        <el-main class="main">
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import {getAppletCodeListAPI} from '../../api/firstHome/firstHome';
import {jielongSmallLogo} from '../../assets/js/staticData/imageRequest';
import {getLocalStorage} from '../../assets/js/tools/common';

export default {
  name: 'Solitaire',
  data() {
    return {
      urlList: [],
      typeList: ['发布接龙', '我的接龙', '我的群组', '模板中心'],
      activeIndex: 0, //选中的索引值
      userInfo: '',//用户信息
    };
  },
  created() {
    this.init()
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      // console.log('router ==>', this.$route.name)
      let userInfo = getLocalStorage('userInfo')
      this.userInfo = userInfo
      this.cgHighlight()
      let AppletCodeList = await getAppletCodeListAPI()
      let urlList = [jielongSmallLogo, '', '']
      AppletCodeList.forEach((item) => {
        if (item.type === 'wxkf') {
          urlList[1] = item.childList[0].bigimg
        } else if (item.type === 'wxxcx') {
          urlList[2] = item.childList[0].bigimg
        }
      })
      // console.log('urlList ==>',urlList)
      this.urlList = urlList

    },
    /**
     * 退出登录
     */
    signOutFun() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.removeItem('userInfo')
        this.$router.push({name: 'Template'})
      }).catch(() => {

      });
    },
    /**
     * 修改高亮的位置
     */
    cgHighlight() {
      //修改高亮的位置
      let routeName = this.$route.name
      switch (routeName) {
        case 'ReleaseSolitaire':
          this.activeIndex = 0;
          break;
        case 'MySolitaire':
          this.activeIndex = 1;
          break;
        case 'MyGroup':
          this.activeIndex = 2;
          break;
      }
    },
    /**
     * 点击功能类型
     */
    clickType(index) {
      if (index === this.activeIndex) return;
      if (index !== 3) {
        this.activeIndex = index;
      }
      switch (index) {
        case 0: //发布接龙
          this.releaseSolitaire();
          break;
        case 1: //我的接龙
          this.mySolitaire();
          break;
        case 2: //我的群组
          this.myGroup();
          break;
        case 3: //模板中心
          this.templateCenter();
          break;
        default:
          break;
      }
    },
    /**
     * 发布接龙
     */
    releaseSolitaire() {
      this.$router.replace({name: 'ReleaseSolitaire'})
    },
    /**
     * 我的接龙
     */
    mySolitaire() {
      this.$router.replace({name: 'MySolitaire'})
    },
    /**
     * 我的群组
     */
    myGroup() {
      this.$router.replace({name: 'MyGroup'})
    },
    /**
     * 模板中心
     */
    templateCenter() {
      this.$router.push({name: 'Template'})
    },
  },
  watch: {
    $route() {
      this.cgHighlight()
    }
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.publish {
  width: 100%;

  .header {
    margin: 0 32px;
    height: 60px;
    line-height: 60px;
    display: flex;
    align-items: center;

    .header-img {
      width: 40px;
      height: 40px;

      img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
      }
    }

    .business {
      margin-left: auto;

      .el-menu-demo {
        img {
          width: 30px;
          height: 30px;
        }

        .nameSpan {
          margin-left: 10px;
        }
      }
    }
  }

  .aside {
    height: calc(100vh - 60px);

    .type {
      width: 100%;
      height: 50px;
      font-size: 14px;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
      user-select: none;

      &.active {
        color: white;
        background-color: rgb(24, 198, 49);
      }

      &:not(.active):hover {
        color: rgb(24, 198, 49);
      }
    }
  }

  .main {
    background-color: rgb(227, 230, 232);
    width: calc(100vw - 150px) !important;
    box-sizing: border-box !important;
    padding-right: 0 !important;

    .main-content {
      .release-solitaire {
        .title {
          font-weight: bold;
          margin-bottom: 10px;
        }

        .typeTemplate {
          display: flex;
          flex-wrap: wrap;

          .template {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 225px;
            height: 160px;
            background: #fff;
            border-radius: 2px;
            overflow: hidden;
            margin: 0 16px 24px 0;
            cursor: pointer;

            &:hover {
              box-shadow: 0px 8px 15px #b8b8b8;
            }

            &.fk {
              border-top: 4px solid #5a89f1;
            }

            &.dk {
              border-top: 4px solid #34bcff;
            }

            &.tb {
              border-top: 4px solid #9690ff;
            }

            &.tp {
              border-top: 4px solid #42d4b0;
            }

            &.hd {
              border-top: 4px solid #6398f6;
            }

            img {
              width: 50px;
              height: 50px;
              margin-bottom: 16px;
            }

            .name {
              font-size: 14px;
              font-weight: 700;
              color: rgba(0, 0, 0, 0.85);
              line-height: 22px;
            }

            .tips {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.45);
              line-height: 20px;
              margin: 3px 0 8px;
            }
          }
        }
      }
    }
  }
}

.lxkf-box {
  color: #2e6eff;
}

.lxkf {
  width: 200px;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
  }
}

</style>
<style>
.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
</style>
