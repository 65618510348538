<template>
  <div class="templateLibrary">
    <div class="title" id="ybfw">强大的模板库</div>
    <div class="moreTemplate" @click="jumpTmplate">
      <div>查看更多模板</div>
    </div>
    <div class="template-list-box">
      <div class="template-list" @mouseover="stopTimer" @mouseleave="executeTimer">
        <transition-group name="fade">
          <div class="template-item" v-show="showIndex === index" v-for="(item,index) in templateList" :key="index">
            <!--<div class="template-item" v-for="(item,index) in templateList" :key="index" >-->
            <div class="template-small-item" v-for="(element,i) in item" :key="i">
              <div class="type-title">{{ element.type }}</div>
              <div class="template-link-list">
                <div class="template-link-item ellipsisOne link" @click="jumpTmplate" v-for="(smallItem,smallIndex) in element.childList" :key="smallIndex">{{ smallItem.title }}</div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
      <div class=" controller-box ">
        <div class="controller" @click="cgShowIndex(index)" :class="{active:showIndex===index}" v-for="(item,index) in templateList" :key="index"></div>
      </div>
      <!--<transition name="el-fade-in-linear">-->
      <!--  <div v-show="show" class="transition-box">.el-fade-in-linear</div>-->
      <!--</transition>-->
    </div>
  </div>
</template>

<script>
import {getLoadWebTemplateAPI} from '@/api/firstHome/firstHome';
import {deepCopy, setLocalStorage} from '@/assets/js/tools/common';

export default {
  name: 'TemplateLibrar',
  data() {
    return {
      showIndex: 0,
      templateList: [],
      Ttimer: null,//定时器
    }
  },
  created() {
    getLoadWebTemplateAPI().then((res) => {
      let templateList = [];
      let id = 0;
      res.forEach((item) => {
        if (id % 4 === 0) {
          templateList.push([])
        }
        templateList[Math.floor(id / 4)].push(item)
        id++
      })
      this.templateList = deepCopy(templateList)
      this.executeTimer()
    })

  },
  methods: {
    /**
     * 修改显示模板库列表的索引
     */
    cgShowIndex(index) {
      this.showIndex = index
    },
    /**
     * 清除定时器
     */
    stopTimer() {
      clearInterval(this.Ttimer)
    },
    /**
     * 执行定时器
     */
    executeTimer() {
      clearInterval(this.Ttimer)
      this.Ttimer = setInterval(() => {
        // console.log('模板的定时执行')
        // if (this.showIndex === 1) return;
        this.showIndex = this.showIndex >= this.templateList.length - 1 ? 0 : ++this.showIndex;
      }, 3000)
      setLocalStorage('Ttimer', this.Ttimer)
    },
    /**
     * 跳转到模板中心页面
     */
    jumpTmplate() {
      this.$router.push({name: 'Template'})
    },
  }
}
</script>

<style lang="scss" scoped>
.templateLibrary {
  .title {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    padding-top: 100px;
  }

  .moreTemplate {
    margin: 50px 0 100px;
    display: flex;
    justify-content: center;

    div {
      text-align: center;
      color: #1aa0ff;
      cursor: pointer;
      font-size: 20px;
    }
  }

  .template-list-box {
    width: 100%;

    .template-list {
      width: 100%;
      height: 300px;
      //overflow: hidden;
      position: relative;

      .template-item {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        //transition: all .5s;
        .template-small-item {
          width: 23%;
          margin: 1%;
          padding: 20px;
          box-sizing: border-box;
          box-shadow: 0 0 10px #bec5c3;

          .type-title {
            font-size: 24px;
            font-weight: bold;
          }

          .template-link-list {
            margin-top: 40px;

            .link {
              margin: 20px 0;
              cursor: pointer;
              transition: all .5s;

              &:hover {
                color: #18c631;
                font-size: 18px;
              }
            }
          }

        }
      }
    }

    .controller-box {
      margin-top: 50px;
      display: flex;
      justify-content: center;

      .controller {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: silver;
        margin: 20px;
        cursor: pointer;
        transition: background-color 1s;
      }

      .active {
        background-color: #18c631;
      }
    }
  }
}

//设置动画
//.fade-enter-active{
//  transition: all 1s
//}
//.fade-enter-to{
//  transition: all 1s
//}

.fade-enter-active {
  transition: all 3s ease;
}

.fade-leave-active {
  transition: all .5s;
}

.fade-enter, .fade-leave-to
  /* .slide-fade-leave-active for below version 2.1.8 */
{
  //transform: translateX(10px);
  opacity: 0;
  //width: 0;
  //height: 0;
  //box-shadow: 0 0 0;
}
</style>
