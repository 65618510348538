import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'// 引入标准化样式
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/icon/iconfont.css';// 引入阿里巴巴图标
import 'animate.css';// 引入 animate 动画库
import 'vant/lib/index.css';
import 'viewerjs/dist/viewer.css'

import ElementUI from 'element-ui'// 完整引入 Element
import {Base64} from 'js-base64'
import Vant from 'vant';
import VueViewer from 'v-viewer'//预览图片的插件
import VueCropper from 'vue-cropper';//图片剪切 https://github.com/xyxiao001/vue-cropper


import {getLocalStorage} from '@/assets/js/tools/common';
import {getConfigurationAPI} from './api/firstHome/firstHome';
// 网址：https://mirari.cc/2017/08/27/Vue图片浏览组件v-viewer，支持旋转、缩放、翻转等操作/

//全局路由守卫
router.beforeEach(async (to, from, next) => {
  //清除定时器 （首页的 简单三步 的定时器）
  let timer = getLocalStorage('timer')
  if (typeof timer === 'number') {
    clearInterval(timer)
    localStorage.removeItem('timer')
  }
  //清除定时器（首页的 强大的模板库 的定时器）
  let Ttimer = getLocalStorage('Ttimer')
  if (typeof Ttimer === 'number') {
    clearInterval(Ttimer)
    localStorage.removeItem('Ttimer')
  }

  if (!store.state.configuration.isThereData) {//如果没有数据就得请求数据
    await getConfigurationAPI()//获取项目配置
  }
  if (!getLocalStorage('userInfo') && !['Template', 'FirstHome'].includes(to.name)) {  // 检查用户是否已登录    避免无限重定向
    // 将用户重定向到登录页面
    next({name: 'FirstHome'})
  } else {
    next()
  }
})

Vue.use(ElementUI)
  .use(Base64)
  .use(Vant)
  .use(VueViewer)
  .use(VueCropper)


Vue.config.productionTip = false
Vue.prototype.Base64 = Base64;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
