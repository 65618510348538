<template>
  <div class="first_home">
    <component v-bind:is="currentTabComponent"></component>
  </div>
</template>

<script>
import FirstHomePC from '../../../components/FirstHome/FirstHomePC.vue'
import FirstHomeAPP from '../../../components/FirstHomeAPP/FirstHomeAPP'

export default {
  name: 'FirstHome',
  components: {FirstHomePC,FirstHomeAPP},
  data() {
    return {
      isPC: true,//是否为PC端
      currentTabComponent: '',
    }
  },
  created() {
    this.isPC = this.$store.state.isPC //是否为PC端
    this.currentTabComponent = this.isPC ? FirstHomePC : FirstHomeAPP
  },
  mounted() {
  },
  methods: {
  },
  watch: {}
}
</script>

<style lang="scss" scoped>

</style>
