import {OSSHOST, ossHostUpload} from '../constant';
import {request} from '../request';
import {deepCopy} from '@/assets/js/tools/common';
import {verifyOSSSignature} from '@/api/OSS/uploadFileToOSS';

/**
 * 上传文件
 */
 export function uploadFiles(files) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let {OSSAccessKeyId, Signature, dir, expire, policy} = deepCopy(this.OSSSignatureObj)
    let validationResults = await verifyOSSSignature.call(this, expire)//验证签名是否过期
    if (validationResults.isExpired) {//签名过期
      this.OSSSignatureObj = validationResults
      OSSAccessKeyId = validationResults.OSSAccessKeyId
      Signature = validationResults.Signature
      dir = validationResults.dir
      policy = validationResults.policy
    }
    console.log("files ==>",files)
    let suffix = files.file.name.match(/\.([^.]*?)$/)[0] //文件的后缀名
    let key = `${dir}/${new Date().getTime()}${suffix}`//文件的路径（不包括协议域名）
    this.OSSObj = {OSSAccessKeyId, signature: Signature, key, policy}
    console.log("this.OSSObj ==>",this.OSSObj)
    console.log("files.file ==>",files.file)
    let formData = new FormData();

    // 文件对象
    formData.append('key', this.OSSObj.key);
    formData.append('policy', this.OSSObj.policy);
    formData.append('OSSAccessKeyId', this.OSSObj.OSSAccessKeyId);
    formData.append('success_action_status', '200');
    formData.append('signature', this.OSSObj.signature);
    formData.append('file', files.file);

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      // onUploadProgress: function (progressEvent) {
      //   console.log("进度", progressEvent.loaded / progressEvent.total);
      // },
    }
    request.post(ossHostUpload, formData, config)
      .then((res) => {
        console.log('上传成功返回的结果 res ==>', res)
        // console.log('上传成功后的路径 path ==>', `${OSSHOST}/${this.OSSObj.key}`)
        resolve(`${OSSHOST}/${this.OSSObj.key}`)
      })
      .catch(() => {
        reject(false)
        console.log('上传文件失败')
      })
  })
}
