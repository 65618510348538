<template>
  <div class="my_solitaire">
    <!--<div class="title">我的接龙</div>-->
    <!--导航栏开始-->
    <div class="tabs">
      <el-tabs :value="activeType" @tab-click="cgActiveType">


        <el-tab-pane :label="item.label" :name="item.name" v-for="(item,index) in tabList" :key="index">

          <div class="solitaire_list_box">
            <el-scrollbar class="scroll-wrap" ref="celia" :id="'resultScroll'+index">
              <div
                class="solitaire_list"
                :ref="i===0&&index===0?'item':''"
                v-for="(element,i) in solitaireList"
                :key="i"
                @click="jumpSolitaireDetails(i)">
                <el-row class="solitaire_item">
                  <el-col :span="6">
                    <div class="ellipsisOne title_text">{{ element.title }}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="appletCode ellipsisOne">[ 小程序码 ]</div>
                  </el-col>
                  <el-col :span="3">{{ element.solitaireTypeText }}</el-col>
                  <el-col :span="2">
                    <span :style="{color:element.status===1?'#18c631':'red'}">{{ element.statusText }}</span>
                  </el-col>
                  <el-col :span="3">
                    <div class="publisher">
                      <el-avatar :size="22" :src="element.headimgurl"></el-avatar>
                      <div class="publisher_name ellipsisOne">{{ element.nickname }}</div>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="ellipsisOne">{{ element.showCreateTime }}</div>
                  </el-col>
                  <el-col :span="4">
                    <div class="actionEvent">
                      <span
                        v-if="element.solitaireType===7 && (element.solitaireRole ===1 || element.solitaireRole ===2)"
                        @click.stop="jumpCreatePage(i)"
                        class="el-icon-edit-outline">
                      </span>
                      <!--<span class="el-icon-delete"></span>-->
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-scrollbar>
          </div>

        </el-tab-pane>


      </el-tabs>
    </div>
    <!--导航栏结束-->
    <!--   <div class="pagination">
         &lt;!&ndash;@size-change="handleSizeChange"&ndash;&gt;
         &lt;!&ndash;@current-change="handleCurrentChange"&ndash;&gt;
          <el-pagination
            :current-page="1"
            background
            :page-size="20"
            layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
       </div>-->
  </div>
</template>

<script>
import {solitaireTypeTextList, tabList} from '@/assets/js/staticData/mySolitaire';
import {getMySolitaireListAPI} from '@/api/mySolitaire';
import {formatTimeFn, getDaysByEndTimeFn} from '@/assets/js/tools/time';
import {getLocalStorage} from '@/assets/js/tools/common';

export default {
  name: 'MySolitaire',
  data() {
    return {
      activeType: 'mySolitaire',
      activeTypeIndex: 0,//类型的索引，用于获取真实的dom
      tabList,
      solitaireTypeTextList,
      solitaireList: [],
      itemHeight: -1,//每一行详情的高度
      viewClientHeight: 0,//信息详情的视图高度
      scrollbarHeight: 0,//包裹详情信息列表的盒子真实高度
      bottomCount: 3,//在底部还有bottomCount 个详情信息列表未展示时触发事件
      whetherToLoadData: true,//加载接龙数据时，在接龙获取数据前不能再请求

    }
  },
  created() {
    this.init()
  },
  mounted() {
    for (let i = 0; i < 3; i++) {
      document
        .getElementById(`resultScroll${i}`)
        .addEventListener('scroll', this.handleScroll, true);
    }
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      let userInfo = getLocalStorage('userInfo')
      console.log('userInfo ==>', userInfo)
      if (!userInfo) {
        await this.$router.replace({name: 'Template', query: {isShowSunCode: true}})
        return;
      }
      this.userInfo = userInfo
      let params = {
        userId: userInfo.userId,
        type: this.activeType,

        pcSolitaireType: 1, // 只获取信息收集接龙
      }
      let solitaireList = await getMySolitaireListAPI(params);
      solitaireList = this.processMySolitaireList(solitaireList)
      // console.log('solitaireList ==>', solitaireList)
      this.tabList[this.activeTypeIndex].solitaireList.push(...solitaireList)
      this.solitaireList = this.tabList[this.activeTypeIndex].solitaireList;
      this.$nextTick(() => {
        let index = this.activeTypeIndex
        this.itemHeight = this.$refs.item[0].clientHeight//详情信息的盒子高度
        this.viewClientHeight = this.$refs.celia[index].$refs.wrap.clientHeight;//滚动条外容器的高度
        this.scrollbarHeight = this.$refs.celia[index].$refs.resize.clientHeight//包裹详情信息列表的盒子高度
      })
    },
    /**
     *到底部时触发
     */
    handleScroll() {
      if (!this.whetherToLoadData) return;//请求数据期间不能再次请求数据
      let scrollTop = this.$refs.celia[this.activeTypeIndex].$refs.wrap.scrollTop;//距离顶部的距离

      //包裹列表盒子的高度-可视列表的盒子的高度（包裹列表盒子的父盒子）-滚动的距离 <= 小盒子的高度*几个（底部剩n个时触发）
      if ((this.scrollbarHeight - this.viewClientHeight - scrollTop) <= this.itemHeight * this.bottomCount) {
        // console.log('this.whetherToLoadData ==>', this.whetherToLoadData)
        this.downloadDataFun()
      }
    },

    /**
     * 加载列表数据
     */
    async downloadDataFun() {
      let currentTypeInfo = this.tabList[this.activeTypeIndex]
      this.whetherToLoadData = false;
      if (!currentTypeInfo.isContinueLoading) return //数据已全部加载完成，无需继续加载
      let solitaireList = currentTypeInfo.solitaireList
      let dataId = solitaireList[solitaireList.length - 1].dataId
      console.log('dataId ==>', dataId)
      let params = {
        userId: this.userInfo.userId,
        type: this.activeType,
        dataId,

        pcSolitaireType: 1, // 只获取信息收集接龙
      }
      let newSolitaireList = await getMySolitaireListAPI(params);
      newSolitaireList = this.processMySolitaireList(newSolitaireList)
      let index = this.activeTypeIndex
      if (newSolitaireList.length < 20) {
        this.tabList[index].isContinueLoading = false
      }
      this.tabList[index].solitaireList.push(...newSolitaireList)
      this.$nextTick(() => {//等数据渲染完之后再获取更新数据后的  包裹详情信息列表的盒子高度
        this.whetherToLoadData = true;
        this.scrollbarHeight = this.$refs.celia[index].$refs.resize.clientHeight//包裹详情信息列表的盒子高度
        console.log('this.scrollbarHeight ==>', this.scrollbarHeight)
      })

    },
    /**
     * 跳转到详情页面
     */
    jumpSolitaireDetails(index) {
      let {solitaireType, userId} = this.solitaireList[index]
      let solitaireId = this.solitaireList[index].id
      let query = {userId, solitaireId, solitaireType}
      console.log("query ==>",query)
      this.$store.commit('cgQuery', query)
      this.$router.push({name: 'SolitaireDetails', params: {userId, solitaireId, solitaireType}})
    },
    /**
     * 跳转到创建页面
     */
    jumpCreatePage(index) {
      let solitaireType = this.solitaireList[index].solitaireType
      let userId = this.solitaireList[index].userId
      let solitaireId = this.solitaireList[index].id
      let query = {userId, solitaireId}
      // console.log("query ==>",query)
      this.$store.commit('cgQuery', query)
      this.$router.push({name: 'CreateSolitairePage', params: {solitaireType}})
    },
    /**
     * 修改导航栏
     */
    async cgActiveType(tab) {
      if (tab.name === this.activeType) return;
      this.activeType = tab.name;
      this.activeTypeIndex = ['mySolitaire', 'myParticaipate', 'myManager'].indexOf(this.activeType)
      let index = this.activeTypeIndex;
      this.whetherToLoadData = true;//允许加载
      console.log('this.activeType ==>', this.activeType)
      console.log(this.tabList[this.activeTypeIndex].isContinueLoading)
      if (this.tabList[index].solitaireList.length > 0) {//已有该类型的数据，无需再加载,只需赋值显示
        this.solitaireList = this.tabList[index].solitaireList;

        this.$nextTick(() => {
          this.scrollbarHeight = this.$refs.celia[index].$refs.resize.clientHeight//包裹详情信息列表的盒子高度
          console.log('this.scrollbarHeight ==>', this.scrollbarHeight)
        })
        return;
      }
      let params = {
        userId: this.userInfo.userId,
        type: this.activeType,

        pcSolitaireType: 1, // 只获取信息收集接龙
      }
      let solitaireList = await getMySolitaireListAPI(params);
      solitaireList = this.processMySolitaireList(solitaireList)
      console.log('solitaireList ==>', solitaireList)

      this.tabList[index].solitaireList.push(...solitaireList)
      this.solitaireList = this.tabList[index].solitaireList;
      this.$nextTick(() => {

        this.scrollbarHeight = this.$refs.celia[index].$refs.resize.clientHeight//包裹详情信息列表的盒子高度
        console.log('this.scrollbarHeight ==>', this.scrollbarHeight)
      })
    },
    /**
     * 处理mySolitaireList 里面的数据
     */
    processMySolitaireList(solitaireList) {
      if (solitaireList) {
        solitaireList.forEach((item) => {
          // 更改管理操作菜单接龙按钮文字
          let lisOvertime = getDaysByEndTimeFn(item.endTime)
          // 判断接龙状态
          if (item.status === 2 || lisOvertime) {
            item.status = 2 // 后台不会判断接龙是否过期, 故前端更新 status 状态
          }
          item.solitaireTypeText = solitaireTypeTextList[item.solitaireType - 1];
          item.statusText = item.status === 1 ? '进行中' : '已结束';
          item.showCreateTime = formatTimeFn(item.createTime)
        })
        return solitaireList
      }
    },
  }
}
</script>

<style lang="scss" scoped>
$themeColor: #18c631; //主题颜色
.my_solitaire {
  .tabs {
    margin: 0 20px;

    .solitaire_list_box {
      height: calc(100vh - 160px);
      overflow: hidden;

      &::-webkit-scrollbar { //修改滚动条的样式
        //display: none; /* Chrome Safari */
        width: 4px;
      }

      .solitaire_list {
        font-size: 14px;

        .solitaire_item {
          width: 100%;
          height: 55px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          padding: 10px;
          border-bottom: 1px solid silver;
          cursor: pointer;

          &:hover {
            background-color: rgba(24, 198, 49, 0.2);
          }

          .title {
            flex: 10;
            text-align: left;
            display: flex;


          }

          .title_text {
            text-align: left;

          }

          .appletCode {
            color: $themeColor;
          }

          .publisher {
            display: flex;
            //justify-content: center;
            padding-left: 10px;
            box-sizing: border-box;

            .publisher_name {
              margin-left: 10px;
              display: flex;
              align-items: center;
            }
          }

          .actionEvent {
            span {
              margin: 10px;
            }
          }
        }
      }
    }
  }

  .pointer {
    cursor: pointer;
  }

  .pagination {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    //background-color: #2e6eff;
  }
}
</style>

<style>
.el-tabs__item.is-active {
  color: #18c631 !important;
}

.el-tabs__active-bar {
  background-color: #18c631 !important;
}

.el-tabs__item:hover {
  color: #18c631 !important;
}

.scroll-wrap {
  height: 100%;
}
</style>
