<template>
  <div class="questionAndInfo">
    <div class="type-box" v-for="(item,index) in addInfoAndQuestionConfig" :key="index">
      <div class="type-title">{{ item.title }}</div>
      <div class="type-content">
        <div class="content-item" @click="addTopicCommandFun(item.title==='问题类型'?item.title:'基本信息',element.itemTitle)" v-for="(element,i) in item.list" :key="i">
          <!--<span class="iconfont icon-duoxuankuang"></span>-->
          <span class="type-question">{{ element.itemTitle }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionAndInfo',
  data() {
    return {
      addInfoAndQuestionConfig: []
    }
  },
  mounted() {
    this.addInfoAndQuestionConfig = this.$store.state.solitaireObj.addInfoAndQuestionConfig

  },
  methods: {
    /**
     * 添加题目或者添加基本信息（在线考试）
     */
    addTopicCommandFun(bigType, smallType) {
      console.log('bigType ==>', bigType)
      console.log('smallType ==>', smallType)
      this.$emit('addTopicCommandEvent', {bigType, smallType})

    },
  },
}
</script>

<style lang="scss" scoped>
.questionAndInfo {
  width: 250px;
  box-sizing: border-box;
  text-align: left;
  padding: 5px 20px;
  background-color: #ebedf0;

  .type-box {
    margin: 14px 0;

    .type-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .type-content {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;

      .content-item {
        width: 45%;
        padding: 10px 0;
        border: 1px solid silver;
        border-radius: 2px;
        font-size: 12px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:nth-of-type(2n-1) {
          margin-right: 10px;
        }

        .type-question {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
