<template>
  <div class="navigationBar-box" :class="{bgc:isShowBgc}">
    <div class="navigationBar">
      <div class="logo">
        <a href="">
          <el-image
            style="width: 120px;display: block"
            :src="url"
            fit="contain"></el-image>
        </a>
      </div>
      <div class="login">
        <div class="text">登录</div>
      </div>
      <div class="iconfont icon-liebiao more"></div>
      <!--<div class="registered">免费注册</div>-->
    </div>
  </div>
</template>

<script>
// import {OSSHOST} from '@/utils/constant';
import {jlLogoNoPinYin, jlLogoNoPinYinG} from '@/assets/js/staticData/imageRequest';

export default {
  name: 'NavigationBar',
  data() {
    return {
      // url: 'https://oss.jielongshenqi.com/data/jl/img1/logo1.png',
      url: jlLogoNoPinYin,
      isShowBgc: false,//是否显示导航栏的背景色
    }
  },
  created() {
    // window.addEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.isShowBgc = scrollTop !== 0
      this.url = this.isShowBgc ? jlLogoNoPinYinG : jlLogoNoPinYin
    },
    /**
     * 跳转模板
     */
    jumpTemplate(label) {
      if (label === 'mbzx') {
        this.$router.push({name: 'Template'});
      }
    },
  }
}
</script>

<style lang="scss" scoped>
$QQWXbgc: rgba(255, 255, 255, .15);
.bgc {
  background-color: white;
}

.cgColor {
  color: #1a1a1a;
}

.add-border {
  border: 1px solid #e2e2e2 !important;
}

.navigationBar-box {
  width: 100vw;
  padding: 10px 20px;
  box-sizing: border-box;
  //background-color: white;
  display: flex;
  //position: fixed;
  //top: 0;
  //left: 0;
  //z-index: 100;
  color: white;


  .navigationBar {
    width: 100%;
    display: flex;

    .logo {
      display: flex;
      align-items: center;
    }

    .login, {
      margin-left: auto;
      font-size: 16px;
      //line-height: 24px;
      //display: flex;
      //justify-content: center;
      //align-items: center;
      .text {

      }
    }

    .more {
      margin-left: 10px;
    }
  }
}

</style>
