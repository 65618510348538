/**
 * 获取参与记录列表
 */
import {loadParticipate} from '@/api'
import {getLocalStorage, parseJSON} from '@/assets/js/tools/common'
import {replaceDomainName, whetherCanModifyRecord} from '@/components/SolitaireDetails/utils/index'
import Vue from 'vue'

export function getParticipateRecordList(sizeCount = 20, bysort = '', byfield = '') {
  return new Promise((resolve, reject) => {
    // 如果接龙已归档则不获取参与记录列表
    loadParticipate({
      solitaireId: this.obj.id,
      userId: getLocalStorage('userInfo').userId, // 必须传 userId 否则将获取不到用户是否已点赞的信息
      sizeCount: sizeCount,
      participateRecordId: this.obj.paginationId || '', // 必须传 参与记录Id 否则将无法分页, 会导致现有代码报错
      orderbysort: bysort, // 排序参数，desc 倒序 asc 升序
      orderbyfield: byfield, // 排序字段(id 或 key2)
      participateName: this.obj.participateName,
    }).then(res => {
      const {checkResult: {allowed, resultMessage, resultObject}} = res.data
      if (!allowed) {
        this.$message.error(resultMessage)
        return
      }

      // console.log('参与记录对象:', this.obj.solitaireId, res)
      // console.log('获取参与记录列表 res:', res)
      // console.log('参与记录列表:', resultObject.participateRecordList)
      let list = resultObject.participateRecordList

      list.forEach(item => {
        // 判断是否可以修改参与记录
        whetherCanModifyRecord.call(this, item)

        item.solitaireData = parseJSON(item.solitaireData)

        item.solitaireData.forEach(data => {
          data.elementConfigInfo = parseJSON(data.elementConfigInfo)
          if (data.elementType === '录音' && data.content1 !== '') {data.content1.isplay = false} // 添加录音播放状态
          // 把旧域名替换为OSS域名
          replaceDomainName(data.elementType, data.content1)
        })

        // 把编号名称和编号串化并用括号括起来
        if (item.solitaireData && item?.solitaireData[1]?.elementConfigInfo.validationFormat === '编号') {
          item.solitaireData[0].content2 = `(${item.solitaireData[1].elementConfigInfo.fieldTips}${item.solitaireData[1].content1})`
        }

        // 格式化时间(年份等于当前年份则不显示年份)
        item.updateTime = Number(item.updateTime.substring(0, 4)) === new Date().getFullYear() ? item.updateTime.substring(5, 16) : item.updateTime.substring(0, 16)
      })
      if (list.length > 0) {
        // 获取发货管理需要处理的数据
        this.obj.participateIdArr = list.map(item => item.id).join()
        // 把接龙名单添加到接龙对象里面
        this.obj.participateList = [...this.obj.participateList, ...list]
        this.obj.paginationId = byfield === 'key2' ? list[list.length - 1].key2 : list[list.length - 1].createTime // 分页Id 数据不用从逻辑层发送到视图层, 所以不用 setData
      } else if (list.length < 20) {
        this.obj.preventLoading = true // 数据到底了, 阻止没有意义的下拉
      }
      this.obj.participateList.forEach((item) => {
        item.solitaireData?.forEach((element) => {
          if (element.elementType === '单选') {
            if (typeof element.elementConfigInfo.defaultValue === 'string') {
              element.elementConfigInfo.defaultValue = JSON.parse(element.elementConfigInfo.defaultValue)
            }
          }
        })
      })
      // console.log('this.obj.participateList ==>', this.obj.participateList)
      // this.obj.reverseOrder = true // 默认倒序
      /*this.setData({
        // reverseOrder: this.obj.reverseOrder,
        // byNumber: this.obj.byNumber
      })*/
      this.obj = Vue.observable(this.obj)
      resolve(this.obj)
    }).catch(err => {
      console.log('获取参与记录列表失败:', err)
      reject(err)
    })
  })
}
