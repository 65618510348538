<template>
  <div class="center-content-box">
    <!--<div class="position">-->
    <!--  <QuestionAndInfo></QuestionAndInfo>-->
    <!--</div>-->
    <CoverPicture></CoverPicture>
    <SolitaireIntroduction></SolitaireIntroduction>
    <div class="mgt20">
      <ListOfName></ListOfName>
    </div>
    <div class="mgt20">
      <TopicContent ref="basicInformation" legendTitle="基本信息"></TopicContent>
    </div>
    <div class="mgt20">
      <TopicContent  ref="examQuestions" legendTitle="考试问题"></TopicContent>
    </div>
  </div>
</template>

<script>
import CoverPicture from '@/components/createSolitaire/centerContent/CoverPicture';
import SolitaireIntroduction from '@/components/createSolitaire/centerContent/SolitaireIntroduction';
import ListOfName from '@/components/createSolitaire/centerContent/ListOfName';
import TopicContent from '@/components/createSolitaire/centerContent/TopicContent';
import QuestionAndInfo from '@/components/createSolitaire/QuestionAndInfo';

export default {
  name: 'index',
  components: {CoverPicture, SolitaireIntroduction, ListOfName, TopicContent, QuestionAndInfo},
}
</script>

<style lang="scss" scoped>
.center-content-box {
  position: relative;
  height: calc(100vh - 100px);
  overflow-y: auto;
  .position{
    position: absolute;
    left: -50px;
    top: 0;
    z-index: 100;
  }
  &::-webkit-scrollbar { //修改滚动条的样式
    //display: none; /* Chrome Safari */
    width: 4px;
  }
  //padding-bottom: 40px;
  .introduction {
    margin-top: 20px;
  }

  .mgt20 {
    margin-top: 20px;
  }
}

</style>
