<!--图片的截取组件-->
<!--插件说明地址 https://github.com/xyxiao001/vue-cropper-->
<template>
  <div class="cropper_box" v-if="shearImg.length>0">
    <div class="header">
      <div class="text">截取图片</div>
      <div class="el-icon-circle-close icon" @click="cancel" ></div>
    </div>
    <div class="cropper">
      <vueCropper
          ref="cropper"
          :img="shearImg"
          :outputSize="1"
          outputType="jpeg"
          :info="true"
          :full="true"
          :fixedBox="true"
          :original="true"
          :autoCrop="true"
          :autoCropWidth="cropWidth"
          :autoCropHeight="cropHeight"
          :centerBox="true"
          :high="true"
          :infoTrue="true"
          @realTime='realTime'
      ></vueCropper>
    </div>
    <div class="operate">
      <!--      <div class="cancel">取消</div>-->
      <!--      <div class="areYouSure" @click="screenshot">确定</div>-->
      <el-button type="primary" class="box cancel " @click="cancel" plain>取消</el-button>
      <el-button type="primary" class="box areYouSure" @click="screenshot">确定</el-button>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Cropper',
  props: {
    shearImg: {//临时图片
      type: String,
      value: ''
    },
    cropWidth: { //剪切框的宽度
      type: Number,
      value: 500
    },
    cropHeight: {//剪切框的高度
      type: Number,
      value: 400
    }
  },

  data() {
    return {
      shearBlobUrl: '',
    }
  },
  methods: {
    /**
     * 实时预览文件
     */
    realTime(data) {
      // console.log('data ==>', data)
      this.shearBlobUrl = data.url
    },
    /**
     * 取消截图
     */
    cancel(){
      this.$emit('cancel')
    },
    /**
     * 确定截图
     */
    screenshot() {
      this.$refs.cropper.getCropBlob(data => {
        console.log('确定截图之后的blob数据 ==>', data)
        // this.$emit('cutThePicture', {shearBlobUrl})
        this.$emit('cutThePicture', {shearBlobData: data})
      })

    },
  },
}
</script>

<style lang="scss" scoped>
$themeColor: #06c421;
.cropper_box {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: white;

  .header {
    //width: 100%;
    height: 60px;
    padding: 0 50px;
    display: flex;
    align-items: center;

    .icon {
      font-size: 16px;
      margin-left: auto;
      margin-right: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: font-size .3s;

      &:hover {
        font-size: 18px;
        color: #070707;
        cursor: pointer;
      }
    }
  }

  .cropper {
    //width: 100%;
    height: 80%;
    padding: 30px 50px;
    border-bottom: 1px #e5e5e5 solid;
    border-top: 1px #e5e5e5 solid;
  }

  .operate {
    display: flex;
    justify-content: flex-end;
    padding: 10px 50px;

    font-size: 16px;
  }

  .box {
    padding: 8px 16px;
  }

  .cancel {
    border: 1px solid #7e7d7d;
    color: #7e7d7d;
    margin-right: 10px;
    border-radius: 4px;
    background-color: white;

    &:hover {
      border: 1px solid $themeColor;
      color: $themeColor;
      background-color: transparent;
    }
  }

  .areYouSure {
    color: white;
    background-color: $themeColor;
    border: 1px solid $themeColor;
    border-radius: 4px;

    &:hover {
      background-color: #01e121;
      border: 1px solid #01e121;
    }
  }
}


</style>
