import {request} from '@/utils/request';
// import {URL} from '@/utils/constant';
import {getWhatDay} from '@/assets/js/tools/time';
import {getLocalStorage} from '@/assets/js/tools/common';
import {getSignatureAPI} from '@/api/fileUpload/uploadTheFile'


/**
 * 获取oss签名
 */
export function getOSSSignatureAPI(solitaireId, folderType = 'fmt') {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    let YMD = getWhatDay().split('-')
    let ymd = `${YMD[0]}/${YMD[1]}/${YMD[2]}`
    let {userId} = getLocalStorage('userInfo')
    // let timestamp = new Date().getTime()
    // let suffix = '.png'
    let solitairePage = 'fb'
    let dir = `data/solitaire/user/${solitaireId === '' ? '' : solitaireId + '/'}${ymd}/${solitairePage}/${userId}/${folderType}`
    // let key = `${dir}/${timestamp}${suffix}`
    // console.log('路径 ==>', `${dir}/${timestamp}${suffix})
    let result = await getSignatureAPI(dir).catch((err) => {
      console.log("获取签名失败 err ==>", err)
    })//获取OSS上传签名
    console.log("修改后的请求签名  ==>", result)
    console.log("获取签名的参数 dir ==>",dir)
    let ossSign = result?.data?.checkResult?.resultObject.ossSign
    // console.log("签名权限 ossSign ==>",ossSign)
    resolve(ossSign)
  })
}

/**
 * 验证 oss 签名 是否过期
 * @param expire oss签名的有效期
 * @return OSSSignatureObj 重新获取的OSS签名
 */
export function verifyOSSSignature(expire) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    let newExpire = new Date().getTime() / 1000
    if (expire > newExpire) {//签名还没有过期
      resolve({isExpired: false})
    } else {//签名已过期
      console.log('this.clickCurrentRef ==>', this.clickCurrentRef)
      let _ref = this.clickCurrentRef || 'fmt'
      if (_ref.includes('smlPic')) {
        _ref = 'xt'
      } else if (_ref.includes('smlFj')) {
        _ref = 'fj'
      }
      let OSSSignatureObj = await getOSSSignatureAPI(this.solitaireObj.id, _ref)
      OSSSignatureObj.isExpired = true
      resolve(OSSSignatureObj)
    }
  })
}


/**
 * 提交文件信息
 * @param args = {fileSize, solitaireId, userId, fileKey, fileName, fileType, elementRalationId}
 */
function submitFileInfo(args) {
  console.log('提交文件信息给后台:', args)
  request('/solitaireMgr/configAPI!uploadtemp.do', args).then(res => {
    let {checkResult: {allowed}} = res.data
    if (allowed) {
      // console.log('提交文件信息成功:', res)
    } else {
      console.log('提交文件信息失败.res:', res)
    }
  }, err => {
    console.log('提交文件信息失败.err:', err)
  }).catch(err => {
    console.log('提交文件信息失败.catch:', err)
  })
}
