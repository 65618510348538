<template>
  <div class="foot-box">
    <div class="qunjielong-list" v-if="!label">
      <div class="qunjielong-item" @click="$router.push({name:'Template'})" v-for="(item,index) in qunjielongList" :key="index">{{ item.title }}</div>
    </div>
    <div class="feet-box">
      <div class="screen6">
        <div class="content">
          <ul class="left">
            <li>
              <p>地 址：</p>
              <p>{{configuration.address}}</p>
            </li>
            <li>电 话：{{configuration.phone}}</li>
            <li>传 真：{{configuration.facsimile}}</li>
            <li>邮 编：{{configuration.postcode}}</li>
            <li>邮 箱：{{configuration.mailbox}}</li>
          </ul>
          <!--<div class="right">
            <div class="box">
              <img src="./images/screen6/public-bg.jpg" alt="">
              <p>微信公众号</p>
            </div>
          </div>-->
          <div class="right">
            <div class="box" v-for="(item,index) in appletCodeList" v-show="item.type!=='wxkf'" :key="index">
              <el-image
                style="width: 80px; height: 80px;border-radius: 4px;"
                :src="item.childList[0].bigimg"
                fit="contain"></el-image>
              <p>{{ item.childList[0].title }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="right_top" v-if="!label">
        <div class="box" @mouseover="activeIndex=index" @mouseleave="activeIndex=-1" v-for="(item,index) in appletCodeList " :key="index" v-show="item.type!=='gfgzh'">
          <el-image
            style="width: 100px; height: 100px;margin: 5px 0;"
            :src="item.childList[0].smallimg"
            fit="contain"></el-image>
          <!--<p>{{ item.title }}</p>-->
        </div>
        <div class="showQrCode-box">
          <div class="package-box" :style="{top:index===0?'100px':index===1?'210px':'320px'}" v-for="(item,index) in appletCodeList" :key="index" v-show="item.type!=='gfgzh'&& index===activeIndex">
            <!--<el-image-->
            <!--  style="width: 220px; height: 220px; position: absolute;top: 0;right: -100px "-->
            <!--  :src="blankBackground"-->
            <!--  fit="contain"></el-image>-->
            <div class="showQrCode">
              <el-image
                style="width: 120px; height: 120px;"
                :src="item.childList[0].bigimg"
                fit="contain"></el-image>
              <div class="description">{{ item.childList[0].text1 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="text">{{configuration.recordNumberInfo}}</div>
      <div class="bah">备案号：
        <a href="https://beian.miit.gov.cn">{{configuration.recordNumber}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import {qrCodeList, qunjielongList} from '@/assets/js/staticData/firstHome';
import {getAppletCodeListAPI} from '@/api/firstHome/firstHome';
import {getCurrentDomainNameInfo} from '../../assets/js/staticData/firstHome';

export default {
  name: 'Foot',
  props: ['label'],
  data() {
    return {
      configuration: {},//项目配置信息
      activeIndex: -1,
      qrCodeList,
      qunjielongList,
      appletCodeList: [],
      currentDomainNameInfo:'',//当前域名的备案号信息
    }
  },
  created() {
    this.processAppletCodeListFun()//获取小程序码、微信客服二维码等图片列表以及数据的处理
    this.currentDomainNameInfo = getCurrentDomainNameInfo()//获取当前域名的备案号信息
    this.configuration = this.$store.state.configuration//项目配置信息
  },
  methods: {
    /**
     * 获取小程序码、微信客服二维码等图片列表以及数据的处理
     */
    async processAppletCodeListFun() {
      this.appletCodeList = await getAppletCodeListAPI()
      this.appletCodeList.forEach((item) => {
        if (item.type === 'wxkf') {
          // console.log('item ==>', item)
          this.$emit('getKefuQrCode', {kefuQrCode: item.childList[0].bigimg})
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.foot-box {
  .footer {
    background-color: #353535;
    text-align: center;
    color: #888888;
    min-width: 1200px;

    .text {
      padding-top: 10px;
    }

    .bah {
      padding: 10px 0;
    }

    a {
      color: #888888;
    }

    a:hover {
      color: #5cb429;
    }
  }
}

.qunjielong-list {
  width: 100%;
  min-width: 1300px;
  max-width: 1400px;
  margin: 20px auto;
  font-size: 16px;
  color: #313131;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .qunjielong-item {
    margin: 5px 8px;
    cursor: pointer;
  }

}

.feet-box {
  background-color: #353535;
  color: #c3c3c3;
  border-bottom: 1px solid #464646;

  .screen6 {
    width: 1200px;
    margin: 0 auto;
    padding: 30px 0;


    .content {
      overflow: hidden;
      display: flex;

      .left {
        width: 700px;

        li {
          display: flex;
          font-size: 16px;
          padding-bottom: 10px;

          //p {
          //  font-size: 24px;
          //}
        }
      }

      .right {
        margin-left: auto;
        //margin-right: 200px;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .box {
          margin-left: 20px;

          p {
            font-size: 14px;
            text-align: center;
            margin-top: 15px;
            //color: #343434;
          }
        }

        .mgr {
          margin-right: 45px;
        }

        img {
          width: 180px;
        }
      }
    }
  }

  .right_top {
    //background: #1a59b7;
    color: #ffffff;
    overflow: hidden;
    z-index: 1;
    position: fixed;
    text-align: center;
    border-radius: 4px;
    right: 0;
    top: 180px;
    padding: 5px;

    .box {
      width: 120px;

      p {
        margin: 10px 0;
      }
    }


  }

  .showQrCode-box {
    .package-box {
      //width: 200px;
      //height: 300px;
      padding: 10px 20px;
      background-color: white;
      position: fixed;
      right: 135px;
      box-sizing: border-box;
      display: flex;
      border-radius: 6px;
      box-shadow: 0 0 15px #b2b2b2;

      &:after {
        content: "";
        width: 0;
        height: 0;
        display: block;
        border: 8px transparent solid;
        border-radius: 3px;
        border-top-color: white;
        border-right-color: white;
        position: absolute;
        top: 160px;
        left: calc(100% - 10px);
        transform: rotate(45deg);
      }

      .showQrCode {
        padding: 10px;
        background-color: white;

        //transform: translate(-25%,-25%);

        .description {
          color: #1a1a1a;
          text-align: center;
          margin-top: 8px;
        }
      }
    }
  }

}


</style>
