<template>
  <div class="SolitairePartInRecord">
    <div class="part-in-record" v-if="participateList.length > 0" style="overflow:auto">
      <div class="part-in-record-title">
        <h3>反馈记录 {{ obj.participateCount }}</h3>
      </div>
      <div
        class="part-in-record-content"
        v-infinite-scroll="load"
        :infinite-scroll-disabled="disabled"
      >
        <div class="content-item" v-for="(item, i) in participateList" :key="i">
          <div class="item-left">
            <img :src="item.headimgurl" alt="用户头像">
          </div>
          <div class="item-right">
            <div class="item-right-list" v-for="(list, j) in item.solitaireData" :key="j">
              <div v-if="list.elementConfigInfo.validationFormat === '编号'"></div>
              <div v-else-if="list.elementType === '位置' || list.content1.length === 0"></div>
              <div v-else-if="list.elementConfigInfo.validationFormat === '名称'">
                <div class="recordInformation">
                  <div class="left">
                    <div class="nickname">
                      <span>{{ list.content1 }}</span><span>{{ list.content2 }}</span>
                    </div>
                    <div class="date-time">{{ item.updateTime }}</div>
                  </div>
                  <div class="right" v-if="solitaireRole">
                    <el-button type="text" @click="handleEditLogisticsInfo(i)">发货管理</el-button>
                  </div>
                </div>
              </div>
              <div v-else-if="list.elementType === '上传图片'" class="element">
                <div class="element-name">{{ list.elementType }}：</div>
                <SmallImg :list="list.content1"/>
              </div>
              <div v-else-if="list.elementType === '上传视频'" class="element">
                <div class="element-name">{{ list.elementType }}：</div>
                <div class="video-wrap">
                  <video
                    controls
                    width="auto"
                    height="400"
                    class="video"
                    :src="list.content1.tempFilePath"
                  >您的浏览器不支持 video 标签。
                  </video>
                </div>
              </div>
              <div v-else-if="list.elementType === '上传文件'" class="element">
                <div class="element-name">{{ list.elementType }}：</div>
                <div class="annex" v-for="(a, i) in list.content1" :key="i">
                  <el-link
                    type="primary"
                    :href="a.path"
                    target="_blank">
                    <i class="el-icon-paperclip">{{ a.name }}</i>
                  </el-link>
                </div>
              </div>
              <div v-else-if="list.elementType === '录音'" class="element element-flex">
                <div class="element-name">{{ list.elementType }}：</div>
                <div class="element-content">
                  <audio :src="list.content1.tempFilePath" controls="controls"></audio>
                </div>
              </div>
              <div v-else-if="list.elementType === '签名'" class="element">
                <div class="element-name">{{ list.elementType }}：</div>
                <div class="big-img">
                  <el-image class="big-img-inner" :src="list.content1" fit="contain"></el-image>
                </div>
              </div>
              <div v-else-if="list.elementType === '数字'" class="element element-flex">
                <div class="element-name">{{ list.elementType }}：</div>
                <div class="element-content">{{ list.content1 }} <span>{{ list.elementConfigInfo.validationFormat }}</span></div>
              </div>
              <div v-else-if="list.elementType === '多选'" class="element element-flex">
                <div class="element-name">{{ list.elementType }}：</div>
                <div class="element-content">{{ list.content1.toString() }}</div>
              </div>
              <!--<div v-else-if="list.elementType === '单选'" class="element"></div>
              <div v-else-if="list.elementType === '日期'" class="element"></div>
              <div v-else-if="list.elementType === '位置'" class="element"></div>
              <div v-else-if="list.elementType === '下拉选择'" class="element"></div>
              <div v-else-if="list.elementType === '省\/市\/区'" class="element"></div>-->
              <div v-else class="element element-flex">
                <div class="element-name">{{ list.elementType }}：</div>
                <div class="element-content">{{ list.content1 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="bottomLoadingPrompt" v-if="loading">加载中...</p>
      <p class="bottomLoadingPrompt" v-if="noMore">没有更多了</p>
    </div>
    <el-dialog :title="`发货管理${editLogistics?'(修改)':''}`" width="30%" top="5vh" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="form" label-width="auto">
        <el-form-item label="发货状态">
          <el-select v-model="form.status" placeholder="请选择发货状态">
            <el-option label="未发货" :value="1"></el-option>
            <el-option label="已发货" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物流品牌">
          <el-input v-model="form.logisticsCompany"></el-input>
        </el-form-item>
        <el-form-item label="发货单号">
          <el-input v-model="form.deliveryNumber"></el-input>
        </el-form-item>
        <el-form-item label="收货人" prop="receiveName">
          <el-input v-model="form.receiveName"></el-input>
        </el-form-item>
        <el-form-item label="收货人电话" prop="receivePhone">
          <el-input v-model="form.receivePhone"></el-input>
        </el-form-item>
        <el-form-item label="收货地址" prop="receiveAddress">
          <el-input v-model="form.receiveAddress"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="激活状态">
          <el-select v-model="form.field1" placeholder="请选择激活状态">
            <el-option label="未激活" value="1"></el-option>
            <el-option label="已激活" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用情况">
          <el-input v-model="form.field2"></el-input>
        </el-form-item>
        <el-form-item label="激活时间">
          <el-form-item>
            <el-date-picker type="date" placeholder="选择日期" v-model="form.field3"></el-date-picker>
          </el-form-item>
        </el-form-item>
        <el-form-item label="停机时间">
          <el-form-item>
            <el-date-picker type="date" placeholder="选择日期" v-model="form.field4"></el-date-picker>
          </el-form-item>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormSubmit('form')">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getParticipateRecordList} from '@/components/SolitaireDetails/utils/getParticipateRecordList'
import {getLocalStorage} from '@/assets/js/tools/common'
import SmallImg from '@/components/SolitaireDetails/components/SmallImg'
import {editLogisticsInfo, loadLogisticsList} from '@/components/SolitaireDetails/utils/api'
import dayjs from 'dayjs'

export default {
  name: 'SolitairePartInRecord',
  components: {SmallImg},
  props: {
    spr: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      obj: {},
      participateList: [],
      userId: getLocalStorage('userInfo').userId,
      loading: false,
      preventLoading: false,

      // 表单对话框
      dialogFormVisible: false,
      form: {
        id: '',
        status: '',
        logisticsCompany: '',
        deliveryNumber: '',
        receiveName: '',
        receivePhone: '',
        receiveAddress: '',
        remark: '',
        field1: '',
        field3: '',
        field2: '',
        field4: '',
      },
      rules: {
        receiveName: [
          {required: true, message: '请输入收货人', trigger: 'blur'},
        ],
        receivePhone: [
          {required: true, message: '请输入收货人电话', trigger: 'blur'},
        ],
        receiveAddress: [
          {required: true, message: '请输入收货地址', trigger: 'blur'},
        ],
      },
      index: '', // 点击发货管理按钮的下标 用于设置提交成功后得到的数据
      editLogistics: false,
    }
  },
  computed: {
    noMore() {
      return this.preventLoading // 数据到底了, 阻止没有意义的触底加载
    },
    disabled() {
      return this.loading || this.noMore
    },
    solitaireRole() {
      return this.obj.solitaireRole === 1 || this.obj.solitaireRole === 2
    },
  },
  methods: {
    // 提交表单
    dialogFormSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('提交表单:', this.form)
          // 年月日时分秒
          this.form.field3 = dayjs(this.form.field3).format('YYYY-MM-DD HH:mm:ss')
          this.form.field4 = dayjs(this.form.field4).format('YYYY-MM-DD HH:mm:ss')
          console.log('提交表单:', this.form)
          editLogisticsInfo(this.form).then(res => {
            console.log('提交表单成功:', res)
            const {checkResult: {allowed, resultMessage, resultObject}} = res.data
            if (allowed) {
              this.$message({
                message: '提交成功',
                type: 'success',
              })
              this.dialogFormVisible = false
              // 把提交的表单添加到接龙参与记录中
              this.form.id = resultObject
              this.participateList[this.index].logisticsList = JSON.parse(JSON.stringify(this.form))
            } else {
              this.$message({
                message: resultMessage,
                type: 'error',
              })
            }
          }).catch(err => {
            console.log('提交表单失败:', err)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 点击发货管理
    handleEditLogisticsInfo(i) {
      this.dialogFormVisible = true
      this.index = i
      const {id: participateId, solitaireId, logisticsList, editLogistics} = this.participateList[i]
      const keyInfo = {userId: this.userId, solitaireId, participateId}
      // 重置表单
      for (const formKey in this.form) {
        this.form[formKey] = ''
      }
      this.form.status = 1 // 默认未发货
      this.form.field1 = '1' // 默认未激活
      // 已有发货管理记录 填充表单
      if (logisticsList) {
        for (const formKey in this.form) {
          this.form[formKey] = logisticsList[formKey]
        }
      }
      // 合并表单
      this.form = {...keyInfo, ...this.form}
      // 编辑物流
      this.editLogistics = editLogistics || false
    },
    // 触底加载
    async load() {
      this.loading = true
      await this.getParticipateRecord()
      this.loading = false
    },
    // 获取参与记录
    async getParticipateRecord() {
      await getParticipateRecordList.call(this).then(async res => {
        this.obj = res
        this.preventLoading = res.preventLoading
        this.participateList = res.participateList
        // 获取参与记录成功后, 获取发货管理的数据
        const participateIdArr = res.participateIdArr
        // 获取发货管理的数据
        if (participateIdArr.length > 0) {
          await loadLogisticsList({participateIdArr}).then(res => {
            const {checkResult: {allowed, resultMessage, resultObject}} = res.data
            if (allowed) {
              const {logisticsList} = resultObject
              // 遍历参与记录列表, 将发货管理的数据合并到参与记录列表中
              this.participateList.forEach(item => {
                const {id: participateId} = item
                const logisticsInfo = logisticsList.find(logistics => logistics.participateId === participateId)
                if (logisticsInfo) {
                  item.logisticsList = logisticsInfo
                  item.editLogistics = true
                }
              })
            } else {
              this.$message({
                message: resultMessage,
                type: 'error',
              })
            }
          }).catch(err => {
            console.log('获取发货管理的数据失败:', err)
          })
        }
      }).catch(err => {
        console.log('获取参与记录失败:', err)
      })
    },
  },
  async created() {
    this.obj = JSON.parse(JSON.stringify(this.spr))
    this.obj.participateList = [] // 第一次进入页面, 参与记录为空
    this.obj.preventLoading = false // 第一次进入页面, 可能还有参与记录
    await this.getParticipateRecord()
  },
}
</script>

<style lang="scss" scoped>
.SolitairePartInRecord {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.part-in-record {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;

  .bottomLoadingPrompt {
    text-align: center;
    color: #999;
    font-size: 14px;
  }
}

.part-in-record-title {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 999;
  // 底部阴影
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.content-item {
  display: flex;
  margin: 0 20px;
  padding: 20px 0 0;
  border-top: 1px solid #dedede;

  &:first-child {
    border-top: none;
  }

  .item-left {
    width: 50px;
    height: 50px;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .item-right {
    flex: 1;

    .item-right-list {

      .recordInformation {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .left {
          .date-time {
            color: grey;
            margin-top: 5px;
          }
        }

        .right {

        }
      }

      .element {
        margin-bottom: 20px;

        .element-name {
          margin-bottom: 10px;
          color: grey;
        }

        .big-img {
          border-radius: 5px;
          overflow: hidden;
        }

        .video-wrap {
          .video {
            width: 100%;
            height: 400px;
            border-radius: 5px;
          }
        }
      }

      .element-flex {
        display: flex;
        align-items: center;

        .element-name {
          margin-bottom: 0;
          color: grey;
          max-width: 30%;
        }

        .element-content {
          flex: 1;
        }
      }
    }
  }
}
</style>
