<template>
  <div class="small-img" ref="smallImg">
    <el-image
      v-for="(item, i) in list" :key="i"
      class="small-img-inner"
      :src="item"
      fit="cover"
    ></el-image>
  </div>
</template>

<script>
export default {
  name: 'SmallImg',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
.small-img {
  font-size: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  .small-img-inner {
    aspect-ratio: auto 1 / 1;
    border-radius: 5px;
  }
}
</style>
