import {request} from '@/utils/request';
import {api} from '@/utils/constant';
import {PARTENCODE} from '../utils/constant';

/**
 *  获取模板标题
 */
export function templateTitlelistFun(type) {
  // console.log('this ==>', this)
  return new Promise((resolve, resject) => {
    // console.log('this', this)
    request({
      url: `/solitaireMgr/templateAPI!loadTemplateType.do`,
      params: {
        partenCode: PARTENCODE,
      },
    })
      .then((res) => {
        console.log('获取模板标题 ==>', res);
        if (res.data.checkResult.allowed) {
          let templateTitleList = res.data.checkResult.resultObject.templateTypeList
          if (type !== 'APP') {//不是移动端（是PC端）时
            templateTitleList.unshift({text: '全部', id: 3})
          }
          resolve(templateTitleList)
        } else {
          console.log('获取模板标题数据失败！')
          resject(false)
        }
      })
  })
}

export function templateContentlist(id) {
  let params
  if (id == 3) {
    params = {sizeCount: 1000}
  } else {
    params = {
      templateTypeId: id,
      sizeCount: 10000,
    }
  }
  return new Promise(() => {
    request({
      url: `/solitaireMgr/templateAPI!loadSolitaireTemplate.do`,
      params: params,
    })
      .then((res) => {
        console.log('获取模板内容的数据 ==>', res)
      })
  })
}

export const SOLITAIRE_TYPE_LIST = [
  {
    title: '信息收集',
    tips: '一次性快速收集信息',
    solitaireType: 1,
    // image: require('@/assets/img/fk.png'),
    color: '#5a89f1',
  },
  {
    title: '打卡接龙',
    tips: '周期性多次收集信息',
    solitaireType: 2,
    // image: require('@/assets/img/dk.png'),
    color: '#34bcff',
  },
  {
    title: '问卷调查',
    tips: '表单 问卷 登记 填表',
    solitaireType: 3,
    // image: require('@/assets/img/tb.png'),
    color: '#9690ff',
  },
  {
    title: '投票评选',
    tips: '单选 多选 图文 匿名 ',
    solitaireType: 4,
    // image: require('@/assets/img/tp.png'),
    color: '#42d4b0',
  },
  {
    title: '活动报名',
    tips: '报名 聚会 订餐 招募',
    solitaireType: 5,
    // image: require('@/assets/img/hd.png'),
    color: '#6398f6',
  },
]
