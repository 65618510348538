<template>
  <div>
    <div class="template-box">
      <el-row class="el-rows" justify="center" type="flex">
        <!-- <el-col :xs="24" :sm="20" :md="18" :lg="16" :xl="12"> -->
        <el-col class="el_col" :lg="12" :md="16" :sm="20" :xl="10" :xs="24">
          <div class="templateTitle">
            <div
              v-for="(item, index) in templateTitleList"
              :key="index"
              :class="{ active: item.id == activeId ,hoverActive: hoverActiveIndex==index}"
              :style="{ color: item.id == activeId ? '#18c631' : '' }"
              class="templateTitleItem"
              @click="jumpTemplate(item.id)"
              @mouseover="cgHoverActiveIndex(index)"
              @mouseout="hoverActiveIndex =-1"
            >
              {{ item.text }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="template-content-box">
      <el-row class="el-rows" justify="center" type="flex">
        <el-col :lg="14" :md="16" :sm="20" :xl="14" :xs="24">
          <div v-if="loading" class="template-content">
            <div
              v-for="(item, index) in showTemplateList"
              :key="index"
              class="template"
              @mouseenter="mouseEnter(index)"
              @mouseleave="mouseleave(index)"
            >
              <div class="image-box">
                <img :src="item.showPicture" alt="" />
                <div class="mask">
                  <div
                    :style="{ bottom: bottomArr[index] + 'px' }"
                    class="mask-item-box"
                  >
                    <div class="mask-item">
                      <div class="ckxq" @click="viewDetails(index)">查看详情</div>
                      <div class="symb" @click="useTemplate(index)">使用模板</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="title ellipsisOne">{{ item.title }}</div>
              <div class="templateData">
                <div class="type" :class="typeClass[item.solitaireType]">{{ typeName[item.solitaireType] }}</div>
                <!--<div class="browseCount">-->
                <!--  <i class="el-icon-view" style="margin-right: 5px"></i>{{ item.browseCount }}-->
                <!--</div>-->
              </div>
            </div>

          </div>
          <!-- 这是骨架屏 开始 -->
          <div v-if="!loading" style="display: flex; width: 100%">
            <el-skeleton
              v-for="(item, index) in 4"
              :key="index"
              :count="4"
              animated
              style="width: 100%; display: flex"
            >
              <template slot="template">
                <el-skeleton-item
                  style="width: 90%; height: 140px; margin: 20px auto 0"
                  variant="image"
                />
                <div style="padding: 14px">
                  <el-skeleton-item style="width: 100%" variant="h3" />
                  <div
                    style="
                    display: flex;
                    align-items: center;
                    justify-items: space-between;
                    margin-top: 16px;
                    height: 16px;
                  "
                  >
                    <el-skeleton-item style="margin-right: 16px" variant="text" />
                    <el-skeleton-item style="width: 30%" variant="text" />
                  </div>
                </div>
              </template>
            </el-skeleton>
          </div>
          <!-- 骨架屏  结束 -->
          <div class="paging">
            <el-pagination
              :background="true"
              :current-page="currentPage"
              :hide-on-single-page="true"
              :layout="assemblyArrStr"
              :page-size="pageSize"
              :total="templateList.length"
              class="pagination"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import {getTemplateContent} from '@/api/template';
import {templateTitlelistFun} from '@/tempData/templateData';

export default {
  name: 'TemplateContent',
  data() {
    return {
      templateList: [], //全部模板列表
      currentPage: 1, //当前页面的页数
      pageSize: 20, //每一页的个数
      showTemplateList: [], //显示在页面模板列表
      assemblyArrStr: 'total, prev, pager, next', //分页选择器显示的内容
      loading: false, //是否加载完数据
      bottomArr: [],//每个(查看详情，使用模板)盒子隐藏在底部距离的数组 默认是-300
      mouseEnterIndex: -1, //鼠标移入模板的索引值
      typeClass: ['fk', 'dk', 'tb', 'tp', 'hd'], // 类型样式
      typeName: ['反馈', '打卡', '填表', '投票', '活动'], // 类型名称

      templateTitleList: [],
      activeId: 3, //选中模板的id
      hoverActiveIndex: -1,//移入标题的索引
    };
  },
  created() {
    this.getTemplateTitle();
    // this.getTemplateContent();
  },
  mounted() {},
  methods: {
    /**
     * 修改移入标题高亮的索引
     */
    cgHoverActiveIndex(index) {
      // console.log(index)
      if (this.hoverActiveIndex === index) return;
      this.hoverActiveIndex = index
    },
    /**
     *  获取模板标题列表
     */
    getTemplateTitle() {
      templateTitlelistFun.call(this).then((res) => {
        // console.log("获取模板数据返回来的 ==>", res);
        if (res) {
          this.templateTitleList = res
          this.getTemplateContent();
        }
      });
    },
    /**
     * 跳转模板
     */
    jumpTemplate(id) {
      if (id === this.activeId) return;
      this.activeId = id;
      // this.$router.push({name: 'TemplateTitle', params: {id}});

    },
    /**
     * 鼠标移入模板  点击查看详情触发
     */
    viewDetails(index) {
      console.log('点击查看详情 ==>', index);
    },
    /**
     * 鼠标移入模板  点击使用模板
     */
    useTemplate(index) {
      console.log('点击使用模板 ==>', index);
    },
    /**
     * 鼠标进入模板时触发
     */
    mouseEnter(index) {
      this.bottomArr.splice(index, 1, 0);
      this.mouseEnterIndex = index;
    },
    /**
     * 鼠标离开模板时触发
     */
    mouseleave(index) {
      this.bottomArr.splice(index, 1, -300);
      this.mouseEnterIndex = -1;
    },
    /**
     * 修改页数渲染数据
     */
    handleCurrentChange(e) {
      console.log('e ==>', e);
      this.showTemplateList = this.templateList.slice(this.pageSize * (e - 1), this.pageSize * e);
      this.currentPage = e
    },
    /**
     * 获取对应模板内容
     */
    getTemplateContent() {
      getTemplateContent(this.activeId).then((templateList) => {
        if (templateList) {
          this.templateList = templateList;
          this.showTemplateList = templateList.slice(0, this.pageSize);
          if (this.templateList.length / this.pageSize > 7) {//大于7页时显示直接跳转到第几页的组件
            this.assemblyArrStr = 'total, prev, pager, next, jumper';//显示分页组件数组
          }
          let bottomArr = [];
          templateList.slice(0, this.pageSize).forEach(() => {
            bottomArr.push(-300);
          });
          this.bottomArr = bottomArr;
          this.loading = true;
        }
      });
    },
  },
  computed: {},
  watch: {
    $route() {
      if (this.$route.name === 'Home') {
        this.activeId = 3
      }
    },
    activeId(){
      this.getTemplateContent()
    }
  }
};
</script>
<style lang="scss" scoped>
.template-box {
  .el-rows {
    background-color: #E9EEF3;
    padding-bottom: 2px;

    .el-col {
      width: 70% !important;
    }

    .templateTitle {
      height: 30px;
      line-height: 30px;
      background-color: #E9EEF3;
      text-align: center;
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: space-between;

      .templateTitleItem {
        flex: 1;
      }
    }

    .templateContent {
      background-color: white;
    }
  }

}

.template-content-box {
  width: 100vw;
  // padding-top: 20px;
  background-color: #e3e6e8;

  .el-rows {
    .template-content {
      display: flex;
      flex-wrap: wrap;
      //justify-content: space-between;

      .template {
        width: 18%;
        margin: 20px 1% 0;
        background-color: white;
        cursor: pointer;

        &.replace {
          background-color: rgb(227, 230, 232);
        }

        .image-box {
          width: 100%;
          position: relative;

          img {
            width: 100%;
          }

          .mask {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            overflow: hidden;

            .mask-item-box {
              position: absolute;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.7);
              transition: bottom .5s;
              display: flex;
              align-items: center;
              overflow: hidden;

              .mask-item {
                width: 100%;
                height: 36px;
                display: flex;
                justify-content: center;

                div {
                  width: 30%;
                  height: 36px;
                  line-height: 36px;
                  text-align: center;
                  border-radius: 10px;
                  padding: 0 5px;
                  font-size: 14px;
                }

                .ckxq {
                  background-color: white;
                  margin-right: 5px;
                }

                .symb {
                  background-color: rgb(24, 198, 49);
                  color: white;
                  margin-left: 5px;
                }
              }
            }
          }
        }

        .title {
          margin: 10px;
          text-align: left;
        }

        // 骨架屏样式
        .titles {
          margin-top: 10px;
          margin-left: 10px;
          width: 200px;
          height: 20px;
          background-color: rgb(242, 242, 239);
        }

        .templateData {
          margin: 0 10px 10px 10px;
          display: flex;

          .browseCount {
            color: silver;
            font-size: 14px;
            margin-top: 3px;
            margin-left: auto;
          }

          // 骨架屏样式
          .browseCounts {
            width: 60px;
            height: 20px;
            margin-left: auto;
            margin-top: 10px;
            background-color: rgb(242, 242, 239);
          }

          // 骨架屏样式
          .types {
            width: 50px;
            height: 18px;
            margin-top: 10px;
            background-color: rgb(242, 242, 239);
          }

          .type {
            width: 40px;
            height: 18px;
            line-height: 18px;
            text-align: center;
            padding: 2px 5px;
            font-size: 14px;
            border-radius: 10px;
            color: white;

            &.fk {
              background-color: #5a89f1;
            }

            &.dk {
              background-color: #34bcff;
            }

            &.tb {
              background-color: #9690ff;
            }

            &.tp {
              background-color: #42d4b0;
            }

            &.hd {
              background-color: #6398f6;
            }
          }
        }
      }
    }

    .paging {
      margin: 20px 0;
    }
  }
}

.active {
  &:after {
    content: '';
    display: block;
    width: 80%;
    height: 2px;
    transform: translate(12.5%);
    background-color: #18c631;
  }
}
.hoverActive{
  color: #18c631;
}
</style>
<!--注意:修改element UI 组件内部样式时，不能有 scoped 这个属性 否则无效; 建议另起style-->
<!--<style>
.paging .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(24, 198, 49) !important;
}

.paging .el-pagination.is-background .el-pager li:not(.active):hover {
  color: rgb(24, 198, 49) !important;
}
</style>-->
