/**
 * @fileoverview tokenHandler
 * @author <小黄>
 * @date 2023-02-21
 * @version 1.0.0
 * @description requset.js 每次请求都会调用 requestHeaderHook，用于添加请求头
 */

import {getLocalStorage, setLocalStorage} from '@/assets/js/tools/common'
import axios from 'axios'

/**
 * 请求头钩子，每次调用 requset.js 都会触发
 * @param header
 * @return {Promise<unknown>}
 */
export const requestHeaderHook = (header) => {
  return new Promise(resolve => {
    header = addFromWechatMiniProgramHeader(header)
    header = accessTokenHook(header)
    resolve(header)
  })
}

/**
 * 标识请求来自小程序
 */
const addFromWechatMiniProgramHeader = (header) => {
  header['X-From-PC'] = 'true'
  return header
}

/**
 * 用于添加鉴权请求头
 */
const accessTokenHook = (header) => {
  return new Promise(resolve => {
    if (checkToken()) {
      resolve(addAuthHeader(header))
    } else {
      console.warn('token已过期，重新获取token')
      getAccessToken().then(() => {
        resolve(addAuthHeader(header)) // 重新获取token成功，添加鉴权请求头
      }).catch(() => {
        resolve(header) // 重新获取token失败，不添加鉴权请求头
      })
    }
  })
}

/**
 * 异步获取token
 */
const getAccessToken = () => {
  return new Promise((resolve, reject) => {
    let {userId} = getLocalStorage('userInfo')
    // 使用原生的 axios 获取 token
    axios({
      url: 'https://www.xiaoshenqi.cn/DCM/appservlet_json/qqPay!reflashUserInfo.do',
      params: {userId},
    }).then(res => {
      let {checkResult: {allowed, resultObject}} = res.data
      if (allowed) {
        const {accessToken, expireTime} = resultObject
        if (accessToken && expireTime) {
          console.log('获取token成功：', expireTime, accessToken)
          setToken(accessToken, expireTime)
          resolve()
        } else {
          console.warn('获取token失败：', resultObject)
          reject()
        }
      } else reject()
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * 判断是否添加鉴权请求头
 */
const addAuthHeader = (header) => {
  const token = getToken()
  if (token.accessToken) {
    header.Authorization = token.accessToken
  }
  return header
}

/**
 * 判断token是否过期，过期时间减去5分钟
 * @returns {boolean}
 */
const checkToken = () => {
  try {
    const {expireTime} = getToken()
    if (expireTime) {
      const now = Math.floor(new Date().getTime() / 1000)
      return expireTime - 300 >= now
    } else {
      return false
    }
  } catch (e) {
    console.error('判断token是否过期失败：', e)
    return false
  }
}

/**
 * 把 token 存入缓存
 * @param accessToken token
 * @param expireTime 过期时间，时间戳，单位秒，格式如：1676271827
 */
export const setToken = (accessToken, expireTime) => {
  if (!accessToken || !expireTime) return
  accessToken = 'Bearer ' + accessToken
  setLocalStorage('token', {accessToken, expireTime})
}

/**
 * 从缓存中获取 token
 * @returns {any}
 */
export const getToken = () => {
  return getLocalStorage('token')
}
