<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  }
}
</script>

<style lang="scss">
$themeColor:#18c631;
#app {
  width: 100%;
}
// 两行省略
.ellipsisTwo {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

// 一行省略
.ellipsisOne {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
}

.el-header,
.el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-main {
  width: 100%;
  padding: 20px 0 !important;
  overflow: hidden !important;
  color: #333;
  text-align: center;
}
.box-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  text-decoration: none;
}
</style>
