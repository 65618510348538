<template>
  <div class="create">
    <!--布局容器-->
    <el-container>
      <el-header>
        <Head>
          <template v-slot:createSolitairePage>
            <!--个人信息开始-->
            <div class="head_right_info">
              <el-menu default-active="1" class="el-menu-demo" mode="horizontal">
                <el-submenu index="1">
                  <template slot="title">联系客服</template>
                  <el-image
                    :src="src"
                    fit="contain"></el-image>
                </el-submenu>
              </el-menu>
              <!--<div class="btn-box">
                <el-button class="fabu" plain>立即发布</el-button>
              </div>-->
            </div>
            <!--个人信息结束-->
          </template>
        </Head>
      </el-header>
      <!--容器头部结束-->
      <!--容器的内容开始-->
      <!--<el-main>-->
      <div class="main-content">
        <FKCY class="FKCY-Father" :id="id" :type="type"></FKCY>
        <!--<el-row>
          <el-col class="el-col" :xs="15" :sm="14" :md="12" :lg="10" :xl="9"></el-col>
        </el-row>-->
      </div>
      <!--</el-main>-->
      <!--容器的内容结束-->
    </el-container>
  </div>
</template>

<script>
import {kefuUrl} from '@/assets/js/staticData/imageRequest'
import Head from '@/components/Head'
import FKCY from '@/components/SolitaireDetails/FKCY'

export default {
  name: 'SolitaireDetails',
  components: {Head, FKCY},
  props: {
    id: Number,
    type: Number,
  },
  data() {
    return {
      src: kefuUrl,
      solitaireType: 0,//接龙类型
      solitaireObj: {},//接龙对象
    }
  },
}
</script>

<style lang="scss" scoped>
$themeColor: #18c631;
.el-container {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}
.head_right_info {
  display: flex;

  .btn-box {
    margin-left: 20px;

    .el-btn {
      background-color: #ebedf0;

      &:hover {
        border-color: $themeColor;
        color: $themeColor;
      }
    }

    .fabu {
      background-color: $themeColor;
      color: white;

      &:hover {
        border-color: $themeColor;
      }
    }
  }
}

// el-main里面的内容
.main-content {
  background-color: #f5f5f5;
  min-height: calc(100vh - 60px);
  overflow: hidden;
  .FKCY-Father {
    width: 100vw;
    height: calc(100vh - 60px);
  }
}
</style>
