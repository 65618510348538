<template>
  <div class="cover_picture" @mouseover="mouseover" @mouseleave='mouseleave'>

    <!--获取临时文件-->
    <!--<input type="file" @change="handleUpload" ref="input" accept="image/*" />-->


    <!--<div class="cover_picture" >-->
    <el-image
        style="width: 100%;"
        @error='loadImageError'
        :src="coverImg"
        fit="contain">
      <div slot="error" class="image-slot">
        <i class="el-icon-picture-outline"></i>
      </div>
    </el-image>
    <!--  <el-image
        style="width: 100%;"
        :src="coverImg"
        fit="contain">
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>-->
    <!--<img :src="solitaireObj.coverImg" alt="">-->
    <div class="operating" v-if="isShowOperate">
      <div class="reUpload ">
        <el-upload
            class="avatar-uploader"
            ref="uploadCover"
            accept="image/*"
            :action="action"
            :show-file-list="false"
            :data="OSSObj"
            :on-change="cgFileStatus"
            :limit="1"
            :http-request="uploadCustomizeFunc"
            list-type="picture"
            :auto-upload="true">
          <div class="upload-text">重新上传</div>
        </el-upload>
        <!-- <el-upload
           class="avatar-uploader"
           ref="uploadCover"
           accept="image/*"
           :action="action"
           :show-file-list="false"
           :data="OSSObj"
           :on-change="cgFileStatus"
           :limit="1"
           list-type="picture"
           :auto-upload="false">
           重新上传
         </el-upload>-->
      </div>
      <div class="deleteCoverPicture public" v-if="isShowDeleteCoverImgBtn" @click="deleteCoverPicture">删除</div>
    </div>
    <!--    剪切图片的组件-->
    <Cropper
        :shearImg='shearImg'
        :cropWidth="600"
        :cropHeight="360"
        @cutThePicture="getShearBlobUrl"
        @cancel=' cancelCutThePicture'
    ></Cropper>
  </div>
</template>
<script>
import Cropper from '../../public/Cropper';
import {ossHostUpload} from '@/utils/constant';
import {OSSHOST} from '@/utils/constant';
import {defaultCoverImage} from '@/assets/js/staticData/createSolitairePage';
import {listenSolitaireChangeObj} from '@/utils/public/publicFunc';
import {uploadFiles} from '@/utils/public/customizeUploadFile';
import {spliceOSSHOST} from '@/assets/js/tools/minFunc';
import {deepCopy} from '@/assets/js/tools/common';

export default {
  name: 'CoverPicture',
  components: {Cropper},
  data: function () {
    return {
      coverImg: '',
      solitaireConfig: {},//接龙初始化模板
      solitaireObj: {},//接龙对象
      isShowOperate: false,//是否显示操作
      OSSSignatureObj: {},//oss的上传签名数据
      OSSObj: {},//上传OSS文件的参数
      action: ossHostUpload,//上传OSS图片的域名
      temporaryURL: '',//封面图的上传后临时路径路径
      isShowDeleteCoverImgBtn: false,//是否显示删除自定义封面图
      shearImg: '',//需要剪切图片的路径
      file: '',//上传图片的对象
    }
  },
  created() {
    this.solitaireConfig = this.$store.state.solitaireConfig
    console.log('this.solitaireConfig ==>', deepCopy(this.solitaireConfig))
    console.log('this.solitaireConfig ==>', this.solitaireConfig.coverImg)
    this.solitaireConfig.coverImg = this.solitaireConfig.coverImg || defaultCoverImage
    this.solitaireObj = this.$store.state.solitaireObj
    this.coverImg = spliceOSSHOST(this.solitaireObj.coverImg)
    //如果是使用默认的封面图，则不显示删除这个按钮
    this.isShowDeleteCoverImgBtn = !this.solitaireObj.coverImg.includes(defaultCoverImage)
    console.log('this.isShowDeleteCoverImgBtn ==>', this.isShowDeleteCoverImgBtn)

  },
  methods: {
    /**
     * 取消截图
     */
    cancelCutThePicture() {
      this.shearImg = ""
    },
    /**
     * 获取剪切后的图片
     */
    async getShearBlobUrl(data) {
      console.log('data ==>', data)
      this.shearImg = '';//主要作用是隐藏 切图组件
      let file = this.file;//上传图片所需要的文件对象
      let {shearBlobData} = data
      file.file = new File([shearBlobData], file.file.name, {type: file.file.type})
      console.log(' shearBlobData ==>', shearBlobData)
      console.log(' file.file ==>', file.file)
      let url = await uploadFiles.call(this, file).catch(() => {
        console.log("上传图片失败 ")
        this.$refs.uploadCover.clearFiles();//清除上传的历史记录
      })
      if (url) {
        //将blob 的对象 转换成blob链接访问
        this.coverImg = window.webkitURL.createObjectURL(shearBlobData)
        console.log('图片临时路径 ==>', this.coverImg)
        console.log('远程图片的路径 url ==>', url)
        this.url = data.shearBlobUrl
        this.solitaireObj.coverImg = url;//成功上传后再把图片赋值在 solitaireObj上（更改封面图的图片）
        this.isShowDeleteCoverImgBtn = true //显示 删除 自定义封面图的按钮
        this.$refs.uploadCover.clearFiles()
      }
    },
    /**
     * 上传文件自定义方法
     *
     * 不用组件的自动上传文件，是因为上传多个文件时，所传的参数无法改变
     */
    async uploadCustomizeFunc(file) {
      console.log('file ==>', file)
      this.file = file
      this.$refs.uploadCover.clearFiles();//清除上传的历史记录
    },
    /**
     * 加载图片失败的事件
     */
    loadImageError(e) {
      console.log('触发事件 ==》', e)

      this.coverImg = spliceOSSHOST(this.solitaireObj.coverImg)
    },
    mouseover() {
      this.isShowOperate = true;
    },
    mouseleave() {
      this.isShowOperate = false;
    },
    /**
     * 删除封面图（使用默认的封面图）
     */
    deleteCoverPicture() {
      this.$confirm('是否删除自定义封面图，使用默认封面图？')
          .then(() => {
            this.solitaireObj.coverImg = `${OSSHOST}${defaultCoverImage}`
            this.coverImg = spliceOSSHOST(this.solitaireConfig.coverImg);
            console.log('this.solitaireConfig.coverImg ==>', this.solitaireConfig.coverImg)
            console.log('this.coverImg ==>', this.coverImg)
            this.isShowDeleteCoverImgBtn = false;
          })
    },
    /**
     * 文件状态改变时的钩子
     */
    async cgFileStatus(e) {
      console.log('选择封面图上传 cgFileStatus==>', e)
      if (e.status === 'ready') {
        let imgSize = this.$store.state.solitaireObj.imgSize
        if ((e.raw.size / 1024 / 1024) > imgSize) {
          this.$message({
            message: `上传的图片超过${imgSize}M大小限制`,
            type: 'error'
          })
          return;
        }
        this.shearImg = e.url //临时图片

      }
    },
  },
  watch: {
    ...listenSolitaireChangeObj
  }
}
</script>

<style lang="scss" scoped>
.cover_picture {
  //width: 90%;
  position: relative;
  font-size: 0;
  img {
    width: 100%;
  }

  .operating {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    font-size: 16px;
    color: white;

    .deleteCoverPicture {
      margin-left: 20px;
    }

    .public {
      padding: 8px 10px;
      background-color: rgba(24, 26, 27, .5);
      border-radius: 6px;
      cursor: pointer;
    }

    .upload-text {
      padding: 8px 10px;
      background-color: rgba(24, 26, 27, .5);
      border-radius: 6px;
      cursor: pointer;
    }
  }

}
</style>
