/**
 * 深拷贝
 */
// import * as stream from 'stream';

export function deepCopy(obj) {
  let objType = valueTypeFun(obj)
  if (objType === 'Array' || objType === 'Object') {
    let newObj = JSON.parse(JSON.stringify(obj))
    return newObj
  }
  console.warn(`${obj}不是数组或对象`)
  return obj
}

/**
 * 解析JSON字符串
 * @return JSONstr 如果是JSON格式字符串，返回解析后的数据，否则返回原数据
 */
export function JSONparse(JSONstr) {
  if (typeof JSONstr === 'string' && JSONstr !== '') {
    try {
      return JSON.parse(JSONstr)
    } catch (e) {
      console.warn(`${JSONstr}不是JSON字符串类型`)
      return JSONstr;
    }
  }
  console.warn(`${JSONstr}不是字符串类型或者是空值`)
  return JSONstr;
}

/**
 * 解析 JSON 数据 转为 JS 对象
 * @param str 被解析的字符串
 * @returns {Object}
 */
export function parseJSON(str) {
  try {
    if (/^\[.*]$|^{.*}$/.test(str)) return JSON.parse(str)
    else return str
  } catch (e) {
    console.log('解析 JSON 数据 转为 JS 对象失败:', e)
    console.log('按照 /^\\[.*]$|^{.*}$/ 正则表达式未能解析JSON字符串:', str)
    return str
  }
}

/**
 * 判断值的类型(主要是用来识别 数组 还是 对象)
 */
export function valueTypeFun(value) {
  return Object.prototype.toString.call(value).slice(8, -1);
}

/**
 * 封装set localStorage
 */
export function setLocalStorage(key, obj) {
  if (typeof key !== 'string' || obj === undefined) {
    console.warn('自定义方法 调用setLocalStorage方法有误 第一参数:键名(字符串类型)，第二参数：存储的值，缺一不可')
    return;
  }
  let type = valueTypeFun(obj)//判断值的类型
  if (type === 'Object' || type === 'Array') {
    let objStr = JSON.stringify(obj)
    localStorage.setItem(key, objStr)
    return;
  }
  localStorage.setItem(key, obj)
}

/**
 * 封装set sessionStorage
 */
export function setSessionStorage(key, obj) {
  if (typeof key !== 'string' || obj === undefined) {
    console.warn('自定义方法 调用setSessionStorage方法有误 第一参数:键名(字符串类型)，第二参数:存储的值，缺一不可')
    return;
  }
  let type = valueTypeFun(obj)//判断值的类型
  if (type === 'Object' || type === 'Array') {
    let objStr = JSON.stringify(obj)
    sessionStorage.setItem(key, objStr)
    return;
  }
  sessionStorage.setItem(key, obj)
}

/**
 * 封装get localStorage
 */
export function getLocalStorage(key) {
  if (typeof key !== 'string') {
    console.warn('自定义方法 调用getLocalStorage方法有误 参数key的类型必须是string类型（获取值的键名）')
    return;
  }
  let value = localStorage.getItem(key)
  if (['undefined', null].includes(value)) {
    return false
  }
  return JSON.parse(value)
}

/**
 * 封装get sessionStorage
 */
export function getSessionStorage(key) {
  if (typeof key !== 'string') {
    console.warn('自定义方法 调用getLocalStorage方法有误 参数key的类型必须是string类型（获取值的键名）')
    return;
  }
  let value = sessionStorage.getItem(key)
  if (['undefined', null].includes(value)) {
    return false
  }
  return JSON.parse(value)
}

/**
 * 判断数组是否有重复
 */
export function filterDuplicates(arr) {
  let duplicates = []
  for (let i = 0; i < arr.length; i++) {
    if (!duplicates.includes(arr[i])) {
      duplicates.push(arr[i])
    } else {
      return `${arr[i]},是一个重复项`
    }
  }

  return false
}


/**
 * 防抖函数
 */
export function antiShakeFun() {
  let timer = null;
  return function (fn, during = 2000) {
    clearTimeout(timer)
    timer = setTimeout(() => {
      console.log('this123 ==>', this)
      fn()
    }, during)
  }
}

// 节流
