import Router from '@/router'
import {request} from '@/utils/request';
import {JSONparse} from '@/assets/js/tools/common';

//引入qs模块
import qs from 'qs';
import {OSSHOST} from '@/utils/constant';
/**
 * 获取接龙初始数据
 */
export function getSolitaireConfigAPI(solitaireType) {
  return new Promise((resolve) => {
    let params = {solitaireType};
    // console.log("参数  ==>",params)
    request({
      // url: '/solitaireMgr/configAPI!loadSolitaireConfig.do',
      url: `/solitaireMgr/configAPI!loadSolitaireConfig.do`,
      params,
    }).then((res) => {
      // console.log('获取接龙分类初始数据 ==>', res);
      if (res?.data?.checkResult?.allowed) {
        let solitaireConfig = res.data.checkResult.resultObject.solitaireConfig[0];
        solitaireConfig.basicInfoObj = JSONparse(solitaireConfig.basicInfoObj)
        solitaireConfig.contentElementList.forEach((item) => {
          if (!item.elementIcon.includes('https')) {
            item.elementIcon = OSSHOST + item.elementIcon
          }
        })
        resolve(solitaireConfig)
        // console.log("获取模板内容的数据 ==>", templateList);
      } else {
        resolve(false)
        console.log('获取模板内容失败，请检查 template.js文件 res ==>', res);
      }
    });
  })
}

/**
 * 获取发布接龙后的数据
 */

export function getSolitaireObjAPI(params) {

  console.log('params ==>', params)
  return new Promise((resolve, reject) => {
    if (!params.solitaireId) {
      Router.push({name: 'Template'})
      reject(false)
      return;
    }
    request({
      // url: '/solitaireMgr/solitaireAPI!loadSolitaire.do',
      url: `/solitaireMgr/solitaireAPI!loadSolitaire.do`,
      params,
    }).then((res) => {
      // console.log('获取发布接龙数据 res ==>', res)
      if (res.data?.checkResult?.allowed) {
        return resolve(res.data.checkResult.resultObject.content)
      } else {
        console.log('获取发布接龙数据有误')
        reject(false)
      }
    })
  })
}

/**
 * 发布接龙
 */
export function postReleaseSolitaireAPI(data) {
  console.log('data ==>', data)
  return new Promise((resolve, reject) => {
    /*  request({
        // url: '/solitaireMgr/solitaireAPI!createSolitaire.do',
        url: '/api/solitaireMgr/solitaireAPI!createSolitaire.do',
        methods: 'post',
        data: qs.stringify(data),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      }).then((res) => {
        console.log('请求发布接龙成功 res ==>', res)
        if (res.data?.checkResult?.allowed) {
          return resolve(res.data.checkResult.resultObject.content)
        } else {
          console.log('发布接龙失败')
          reject(false)
        }
      })*/
    request.post(`/solitaireMgr/solitaireAPI!createSolitaire.do`, qs.stringify(data))
      .then((res) => {
        console.log(' res ==>', res.data.checkResult)
        if (res.data.checkResult.allowed) {
          console.log('发布接龙成功')
          resolve(true)
        } else {
          reject(false)
          console.log('发布接龙失败')
        }
      })
      .catch(() => {
        reject(false)
        console.log('发布接龙失败')
      })
  })
}

