import {request} from '@/utils/request'

/**
 * 获取 上传文件 签名
 */
export function getSignatureAPI(dir){
   return request({
    url: `/solitaireMgr/configAPI!getOssSign.do`,
    params: {dir}
  })
}
