import { PARTENCODE} from '@/utils/constant';
import {deepCopy} from '@/assets/js/tools/common';
import {getDomainNameFun} from '@/assets/js/staticData/firstHome';
import {request} from '@/utils/request';
import {JSONparse} from '@/assets/js/tools/common';
import store from '@/store';
import {spliceOSSHOST} from '@/assets/js/tools/minFunc';
//在js文件引入store模块
let webType = getDomainNameFun()//获取当前域名

/**
 * 官网项目配置信息
 */
export function getConfigurationAPI() {
  return new Promise((resolve) => {
    request({
      url: `/solitaireMgr/webContentAPI!loadSolitaireWeb.do`,
      params: {partencode: PARTENCODE, webType},
    }).then((res) => {
      // console.log('首页 官网项目配置信息 ==>', res.data.checkResult?.resultObject?.weblist[0])
      if (res.data?.checkResult?.allowed) {
        let introductionTextlist = []//首页顶部 中间内容的介绍文本列表
        let configuration = res.data.checkResult.resultObject.weblist[0]
        let introductionTextArr = configuration.introductionText.split(/\n/)
        let reg = /【(.*?)】///解析首页顶部 中间内容的介绍文本的正则
        configuration.logoURL = spliceOSSHOST(configuration.logoURL)//首页导航栏在最顶部时的logo
        configuration.logoURL1 = spliceOSSHOST(configuration.logoURL1)//首页导航栏不在最顶部时的logo
        configuration.backgroundImage = spliceOSSHOST(configuration.backgroundImage)//首页顶部的背景图
        configuration.kefuQrCode = spliceOSSHOST(configuration.kefuQrCode)//微信客服二维码
        introductionTextArr.forEach((item) => {
          let matchingResults = item.match(reg)
          if (matchingResults) {//能匹配到包含 【】 的字符串
            introductionTextlist.push({isBig: true, title: matchingResults[1]})
            return;
          }
          introductionTextlist.push({isBig: false, title: item})
        })
        configuration.introductionTextlist = introductionTextlist//首页顶部 中间内容的介绍文本列表
        configuration.whySelectInfo = configuration.whySelectInfo.replace(/\n|\s/g, '')
        configuration.whySelectInfo = JSONparse(configuration.whySelectInfo)//将JSON字符串转化成对象
        configuration.isThereData = true//已有数据，标识下次不用再请求数据了
        // console.log('configuration.whySelectInfo  ==>', configuration.whySelectInfo)
        console.log('configuration132 ==>', configuration)
        store.commit('cgConfiguration',configuration);//调动mutations里面定义的方法
        return resolve(true)
      } else {
        console.log('获取 首页 导航栏 功能列表数据失败')
        resolve(false)
      }
    })
  })
}


/**
 * 获取 首页 导航栏 功能列表
 */
export function getNavigationFunctionListAPI() {
  return new Promise((resolve) => {
    request({
      url: `/solitaireMgr/webNavigationAPI!listWebNavigation.do`,
      params: {partencode: PARTENCODE, webType},
    }).then((res) => {
      console.log('首页 导航栏 功能列表 ==>', res.data.checkResult?.resultObject)
      if (res.data?.checkResult?.allowed) {
        let webNavigationList = deepCopy(res.data.checkResult.resultObject.webNavigationList)
        webNavigationList.sort((a, b) => a.sort - b.sort)//根据sort进行排序
        webNavigationList.forEach((item) => {//给图片加上域名
          if (item.eventType === 3) {//如果是二级导航栏事件
            item.isShow = false
            item.menuList.sort((a, b) => a.sort - b.sort)//根据sort进行排序
            item.menuList.forEach((element) => {
              element.imageUrl = spliceOSSHOST(element.imageUrl)
            })
          }
        })
        return resolve(webNavigationList)
      } else {
        console.log('获取 首页 导航栏 功能列表数据失败')
        resolve(false)
      }
    })
  })
}

/**
 * 获取首页模块1数据
 */
export function getLoadWebContent1API() {
  return new Promise((resolve) => {
    request({
      // url: '/solitaireMgr/solitaireAPI!mySolitaire.do',
      url: `/solitaireMgr/webContentAPI!loadWebContent1.do`,
      params: {partencode: PARTENCODE, webType},
    }).then((res) => {
      // console.log('获取首页模块1数据（接龙类型） res ==>', res.data.checkResult.resultObject)
      if (res.data?.checkResult?.allowed) {
        let typeList = deepCopy(res.data.checkResult.resultObject.typeList)
        typeList.forEach((item,) => {
          item.bigimg =  spliceOSSHOST(item.bigimg)
          item.smallimg =spliceOSSHOST(item.smallimg)
        })
        return resolve(typeList)
      } else {
        console.log('获取我的接龙列表数据失败')
        resolve(false)
      }
    })
  })
}

/**
 * 获取首页模块2数据
 */
export function getLoadWebContent2API() {
  return new Promise((resolve) => {
    request({
      url: `/solitaireMgr/webContentAPI!loadWebContent2.do`,
      params: {partencode: PARTENCODE, webType},
    }).then((res) => {
      // console.log('获取首页模块2数据（简单三步） res ==>', res.data.checkResult.resultObject)
      if (res.data?.checkResult?.allowed) {
        let id = 0
        let circuitList = deepCopy(res.data.checkResult.resultObject.content)
        circuitList.forEach((item) => {
          item.activeIndex = 0;
          item.childList.forEach((element) => {
            element.bigimg =   spliceOSSHOST(element.bigimg)
            element.smallimg = spliceOSSHOST(element.smallimg)
            element.id = ++id
          })
        })
        console.log('circuitList ==>', circuitList)
        return resolve(circuitList)
      } else {
        console.warn('获取我的接龙列表数据失败')
        resolve(false)
      }
    })
  })
}

/**
 * 获取模板库数据
 */
export function getLoadWebTemplateAPI() {
  return new Promise((resolve) => {
    request({
      url: `/solitaireMgr/webContentAPI!loadWebTemplate.do`,
      params: {partencode: PARTENCODE, webType},
    }).then((res) => {
      // console.log('获取首页获取模板库数据列表数据 res ==>', res.data.checkResult.resultObject)
      if (res.data?.checkResult?.allowed) {
        let templateList = deepCopy(res.data.checkResult.resultObject.content)

        // console.log('templateList ==>', templateList)
        return resolve(templateList)
      } else {
        console.warn('获取我的接龙列表数据失败 ==>', res)
        resolve(false)
      }
    })
  })
}

/**
 * 获取小程序码、微信客服二维码等图片列表
 */
export function getAppletCodeListAPI() {
  return new Promise((resolve) => {
    request({
      url: `/solitaireMgr/webContentAPI!loadWebCustomer.do`,
      params: {partencode: PARTENCODE, webType},
    }).then((res) => {
      // console.log('获取获取小程序码、微信客服二维码等图片列表 res ==>', res.data.checkResult.resultObject)
      if (res.data?.checkResult?.allowed) {
        let appletCodeList = deepCopy(res.data.checkResult.resultObject.content)
        appletCodeList.forEach((item) => {
          item.childList.forEach((element) => {
            element.bigimg =  spliceOSSHOST(element.bigimg)
            element.smallimg =spliceOSSHOST(element.smallimg)
          })
        })
        // console.log('appletCodeList ==>', appletCodeList)
        return resolve(appletCodeList)
      } else {
        // console.log('获取获取小程序码、微信客服二维码等图片列表')
        resolve(false)
      }
    })
  })
}
