/**
 * description: api接口的统一出口
 */

import {request} from '@/utils/request'

const api = {
  mySolitaire: '/solitaireMgr/solitaireAPI!mySolitaire.do',
  updateContentRowcolumn: '/solitaireMgr/solitaireAPI!updateContentRowcolumn.do',
  loadReviewConfig: '/solitaireMgr/configAPI!loadReviewConfig.do',
  saveCorrectContentRecord: '/solitaireMgr/correctAPI!saveCorrectContentRecord.do',
  loadParticipateCount: '/solitaireMgr/participateAPI!loadParticipateCount.do',
  myCollectSolitaire: '/solitaireMgr/collectAPI!myCollectSolitaire.do',
  myBrowseRecord8: '/solitaireMgr/browseAPI!myBrowseRecord8',
  uploadtemp: '/solitaireMgr/configAPI!uploadtemp.do',
  loadParticipationRecord2: '/solitaireMgr/participateAPI!loadParticipationRecord2.do',
  loadUserCalendarClock: '/solitaireMgr/participateAPI!loadUserCalendarClock.do',
  getParticipateNameCount: '/solitaireMgr/participateAPI!getParticipateNameCount.do',
  getMemeberUnParticipateDate: '/solitaireMgr/participateAPI!getMemeberUnParticipateDate.do',
  loadParticipateRecord: '/solitaireMgr/participateAPI!loadParticipateRecord.do',
  reflashUserInfo: '/appservlet_json/qqPay!reflashUserInfo.do',
  loadAchievementList: '/solitaireMgr/achievementAPI!loadAchievementList.do',
  deleteParticipateSolitaire: '/solitaireMgr/participateAPI!deleteParticipateSolitaire.do',
  productList: '/solitaireMgr/productAPI!productList.do',
  askPay: '/solitaireMgr/memberAPI!askPay.do',
  loadRoulette: '/solitaireMgr/rouletteAPI!loadRoulette.do',
  loadRouletteData: '/rouletteData/api!loadRouletteData.do',
  attendRoulette: '/solitaireMgr/rouletteAPI!attendRoulette.do',
  addRouletteObj: '/solitaireMgr/rouletteAPI!addRouletteObj.do',
  getLastRecord: '/solitaireMgr/participateAPI!getLastRecord.do',
  loadPriticipageNames: '/solitaireMgr/participateAPI!loadPriticipageNames.do',
  loadDownloadInfo: '/solitaireMgr/downloadAPI!loadDownloadInfo.do',
  cancelDownload: '/solitaireMgr/downloadAPI!cancelDownload.do',
  getServiceFeePercent: '/solitaireMgr/withdrawAPI!getServiceFeePercent.do',
  cancelRefund: '/solitaireMgr/refund!cancelRefund.do',
  loadPriticipateFeeNames: '/solitaireMgr/participateAPI!loadPriticipateFeeNames.do',
  loadParticipateFeeCount: '/solitaireMgr/participateAPI!loadParticipateFeeCount.do',
  getUserBalanceList: '/solitaireMgr/solitaireOrder!getUserBalanceList.do',
  loadParticipationRecord5: '/solitaireMgr/participateAPI!loadParticipationRecord5.do',
  listReceiveRefundApply: '/solitaireMgr/refund!listReceiveRefundApply.do',
  userInfo: '/wechat_json/wechatPay!userInfo.do',
  getUserUsableMoney: '/solitaireMgr/withdrawAPI!getUserUsableMoney.do',
  getWithdrawLimit: '/solitaireMgr/withdrawAPI!getWithdrawLimit.do',
  withDrawApprove: '/solitaireMgr/withdrawAPI!withDrawApprove.do',
  solitaireRemind: '/solitaireMgr/solitaireAPI!solitaireRemind.do',
  getUserParticipateMemeberCount: '/solitaireMgr/participateAPI!getUserParticipateMemeberCount.do',
  getuserphonenumber: '/appservlet_json/qqPay!getuserphonenumber.do',
  uploadDoc: '/documentMgr/documentAPI!uploadDoc.do',
  collectAction: '/solitaireMgr/collectAPI!collectAction.do',
  addBrowseRecord8: '/solitaireMgr/browseAPI!addBrowseRecord8.do',
  getPayOrderList: '/solitaireMgr/solitaireOrder!getPayOrderList.do',
  loadSmallVideo: '/smallvideo/api!loadSmallVideo.do',
  loadVideoTypeData: '/smallvideo/api!loadVideoTypeData.do',
  thumbsup: '/thumbsupMgr/api!thumbsup.do',
  getCustomerConfig: '/solitaireMgr/configAPI!getCustomerConfig.do',
  loadAppServletList: '/catApp/appServlet!loadAppServletList.do',
  getListByKey: '/banner_json/trafficAdvert!getListByKey.do',
  getBannerConfig: '/banner_json/banner2!getBannerConfig.do',
  loadPageApplicationList: '/appsMgr_json/page!loadPageApplicationList.do',
  grantSolitaireManager: '/solitaireMgr/solitaireAPI!grantSolitaireManager.do',
  loadSolitaireMember: '/solitaireMgr/solitaireAPI!loadSolitaireMember.do',
  loadSolitaireMember2: '/solitaireMgr/solitaireAPI!loadSolitaireMember2.do',
  createSolitaire: '/solitaireMgr/solitaireAPI!createSolitaire.do',
  refundOrderRefund: '/solitaireMgr/refundOrder!refund.do',
  editSolitaireTemplateJson: '/solitaireMgr/templateAPI!editSolitaireTemplateJson.do',
  loadSolitaireTemplate: '/solitaireMgr/templateAPI!loadSolitaireTemplate.do',
  loadUserInfoByopenid: '/catApp/catWechatAppServlet!loadUserInfoByopenid.do',
  getUserNameList: '/solitaireMgr/solitaireAPI!getUserNameList.do',
  msgSecurityCheck: '/userMgr/api!msgSecurityCheck.do',
  loadUserInfoByunionid: '/catApp/catWechatAppServlet!loadUserInfoByunionid.do',
  loadUserInfo: '/catApp/catWechatAppServlet!loadUserInfo.do',
  getBannerListByKey: '/banner_json/banner2!getBannerListByKey.do',
  getColumnInfolist: '/appsMgr_json/columnInfo!getInfoList.do',
  getServletUrlLink: '/solitaireMgr/solitaireAPI!getServletUrlLink.do',
  updateUserTuiguang: '/catApp/catWechatAppServlet!updateUserTuiguang.do',
  loadVoteRecord: '/solitaireMgr/voteAPI!loadVoteRecord.do',
  loadTemplateDetail: '/solitaireMgr/templateAPI!loadTemplateDetail.do',
  addBrowseRecord: '/solitaireMgr/browseAPI!addBrowseRecord.do',
  addTemplateBrowseRecord: '/solitaireMgr/templateAPI!addBrowseRecord.do',
  userSubscribeConfig: '/solitaireMgr/solitaireAPI!userSubscribeConfig.do',
  getUserSubscribe: '/solitaireMgr/solitaireAPI!getUserSubscribe.do',
  loadMyGroup: '/solitaireMgr/groupAPI!loadMyGroup.do',
  updateSolitaireStatus: '/solitaireMgr/solitaireAPI!updateSolitaireStatus.do',
  deleteSolitaire: '/solitaireMgr/solitaireAPI!deleteSolitaire.do',
  createMyGroup: '/solitaireMgr/groupAPI!createMyGroup.do',
  modifyNickname: '/solitaireMgr/groupAPI!modifyNickname.do',
  getQrCode: '/solitaireMgr/solitaireAPI!qrcode.do',
  saveUserNameList: '/solitaireMgr/solitaireAPI!saveUserNameList.do',
  deleteUserNameList: '/solitaireMgr/solitaireAPI!deleteUserNameList.do',
  loadTemplateType: '/solitaireMgr/templateAPI!loadTemplateType.do',
  loadSolitaireConfig: '/solitaireMgr/configAPI!loadSolitaireConfig.do',
  loadSolitaire: '/solitaireMgr/solitaireAPI!loadSolitaire.do',
  loadParticipate: '/solitaireMgr/participateAPI!loadParticipate.do',
  deleteParticipateRecord: '/solitaireMgr/participateAPI!deleteParticipateRecord.do',
  recordThumbsup: '/solitaireMgr/participateAPI!recordThumbsup.do',
  deleteComments: '/solitaireMgr/participateAPI!deleteComments.do',
  getSolitaireActivityFee: '/solitaireMgr/solitaireAPI!getSolitaireActivityFee.do',
  rollbackFeeCount: '/solitaireMgr/solitaireOrder!rollbackFeeCount.do',
  participateSolitaire: '/solitaireMgr/participateAPI!participateSolitaire.do',
  participateSolitaire2: '/solitaireMgr/participateAPI!participateSolitaire2.do',
  participateSolitaire5: '/solitaireMgr/participateAPI!participateSolitaire5.do',
  solitaireOrderAskPay5: '/solitaireMgr/solitaireOrder!askPay5.do',
  recordComments: '/solitaireMgr/participateAPI!recordComments.do',
  loadParticipateOperation: '/solitaireMgr/participateAPI!loadParticipateOperation.do',
  blackSolitaireMember: '/solitaireMgr/solitaireAPI!blackSolitaireMember.do',
  removeSolitaireManager: '/solitaireMgr/solitaireAPI!removeSolitaireManager.do',
  transSolitaire: '/solitaireMgr/solitaireAPI!transSolitaire.do',
  removeBlackMember: '/solitaireMgr/solitaireAPI!removeBlackMember.do',
  cancelFee: '/solitaireMgr/participateAPI!cancelFee.do',
  applyRefund: '/solitaireMgr/refund!applyRefund.do',
  loadSolitaireDownload: '/solitaireMgr/downloadAPI!loadSolitaireDownload.do',
  downloadSolitaire: '/solitaireMgr/downloadAPI!downloadSolitaire.do',
  downloadAttachment: '/solitaireMgr/downloadAPI!downloadAttachment.do',
  inviteAttentionGroup: '/solitaireMgr/groupAPI!inviteAttentionGroup.do',
  loadGroupInfo: '/solitaireMgr/groupAPI!loadGroupInfo.do',
  loadGroupSolitaire: '/solitaireMgr/groupAPI!loadGroupSolitaire.do',
  quitgroup: '/solitaireMgr/groupAPI!quitgroup.do',
  ungroup: '/solitaireMgr/groupAPI!ungroup.do',
  transGroupManager: '/solitaireMgr/groupAPI!transGroupManager.do',
  grantGroupManager: '/solitaireMgr/groupAPI!grantGroupManager.do',
  deleteGroupMember: '/solitaireMgr/groupAPI!deleteGroupMember.do',
  myDeleteSolitaire: '/solitaireMgr/solitaireAPI!myDeleteSolitaire.do',
  removeSolitaire: '/solitaireMgr/solitaireAPI!removeSolitaire.do',
  getWxUserInfo: '/solitaireMgr/qrcodeAPI!getWxUserInfo.do',
  getTweetsListNew: '/tweets_json/tweets!getTweetsListNew.do',
  loadParticipationRecord7: '/solitaireMgr/participateAPI!loadParticipationRecord7.do',
  participateSolitaire7: '/solitaireMgr/participateAPI!participateSolitaire7.do',
  loadParticipateRank: '/solitaireMgr/participateAPI!loadParticipateRank.do',
  uploadFilesToOSSAPI: 'https://jielongxiaoshenqi.oss-cn-shenzhen.aliyuncs.com',
  readExcelMember: '/solitaireMgr/configAPI!readExcelMember.do',
  uploadFilesToBackend: '/solitaireMgr/configAPI!upload.do',
  importAchievement: '/solitaireMgr/achievementAPI!importAchievement.do',
}
/**
 * 设置接龙完成情况一行几个框
 */
export const updateContentRowcolumn = params => request({url: api.updateContentRowcolumn, params})

/**
 * 获取接龙参与记录精简列表, 用于计算接龙完成情况
 */
export const loadPriticipageNames = params => request({url: api.loadPriticipageNames, params})

/**
 * 获取用户参与记录列表
 */
export const loadParticipate = params => request({url: api.loadParticipate, params})

/**
 * 获取接龙详情
 */
export const loadSolitaire = params => request({url: api.loadSolitaire, params})

/**
 * 统一导出
 */
export default {
  loadSolitaire,
  loadParticipate
}



