/**
 * 处理时间格式
 */
export function formatTimeFn(lt) {
  let date = new Date(lt) // 实例一个时间对象；
  let year = date.getFullYear() // 获取系统的年；
  let month = date.getMonth() + 1 // 获取系统月份，由于月份是从0开始计算，所以要加1
  let day = date.getDate() // 获取系统日
  let hour = date.getHours() // 获取系统时间
  let minute = date.getMinutes() // 分
  // let ldate = new Date(year, month, day);
  let ldate = new Date(year + '/' + month + '/' + day)
  //let second = date.getSeconds(); // 秒
  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day
  hour = hour < 10 ? '0' + hour : hour
  minute = minute < 10 ? '0' + minute : minute

  let ndate = new Date() // 实例一个时间对象；
  let nyear = ndate.getFullYear() // 获取系统的年；
  let nmonth = ndate.getMonth() + 1 // 获取系统月份，由于月份是从0开始计算，所以要加1
  let nday = ndate.getDate() // 获取系统日
  // let lndate = new Date(nyear, nmonth, nday);
  let lndate = new Date(nyear + '/' + nmonth + '/' + nday)

  let days = getDaysFn(ldate, lndate)
  let strTime = ''
  if (days == 0) {
    //如需要显示多少分钟之前，在这里进行处理
    strTime = ''
  } else if (days == 1) {
    strTime = '昨天 '
  } else if (days == 2) {
    strTime = '前天 '
  } else if (days > 2 && day < 8) {
    strTime = days + '天前 '
  } else {
    if (year == nyear) {
      strTime = month + '-' + day + ' '
    } else {
      strTime = year + '-' + month + '-' + day + ' '
    }
  }
  let strTime1 = strTime + hour + ':' + minute
  return strTime1
}

/**
 * 获得两个日期之间相差的天数
 */
export function getDaysFn(date1, date2) {
  var t1 = date1.getTime()
  var t2 = date2.getTime()
  var dateTime = 1000 * 60 * 60 * 24 //每一天的毫秒数
  var minusDays = Math.floor(((t2 - t1) / dateTime)) //计算出两个日期的天数差
  //console.log('获得两个日期之间相差的天数', minusDays)
  var days = Math.abs(minusDays) //取绝对值
  return days
}

/**
 * 获得两个日期之间相差的天数,转入一个时间，转入时间减去当前时，返回差比值
 */
export function getDaysByEndTimeFn(t) {
  if (t !== undefined && t !== '' && t != null) {
    let lt = t.toString()
    lt = lt.replace(/-/g, '/') // 需转换，ios new Date 只支持/
    let date = new Date(lt) // 实例一个时间对象；
    let year = date.getFullYear() // 获取系统的年；
    let month = date.getMonth() + 1 // 获取系统月份，由于月份是从0开始计算，所以要加1
    let day = date.getDate() // 获取系统日
    let hour = date.getHours() // 获取系统时间
    let minute = date.getMinutes() // 分
    let ldate = new Date(year + '/' + month + '/' + day + ' ' + hour + ':' + minute)
    let t2 = ldate.getTime()
    let ndate = new Date() // 当前时间
    let t1 = ndate.getTime()

    let lminus = Math.floor((t2 - t1)) // 计算出两个日期的天数差
    return lminus < 0
  } else {
    return true
  }
}


/**
 * 用于获取相对于今天的 howManyDays 天的日期
 * @param howManyDays 相对于今天的多少天(默认值 0 今天的日期)
 * @returns {string} 返回一个日期字符串 如: 2021-10-09
 */
export function getWhatDay(howManyDays = 0) {
  let t = new Date(+new Date() + 8 * 3600 * 1000) // 加上 8 个时区的时间, 否则 toISOString() 返回的时间不准确
  t.setDate(t.getDate() + howManyDays)
  return t.toISOString().split('T')[0]
}
