import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage
})

export default new Vuex.Store({
  state: {
    configuration:{isThereData:false},//项目配置 isThereData==>是否有数据
    solitaireConfig: {},
    solitaireObj: {},
    partenCode: 'wxwebJlxsq',//简码
    query: {},//路由需要传的参数
    loginInfo: {},//授权登录信息
    addTopicCommand: {},//添加题目的指令
    //访问的设备是否为PC端
    isPC: !(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)),
    imageReviewQueue:'',//图片审核队列
  },
  mutations: {
    cgConfiguration(state, newConfiguration) {
      state.configuration = newConfiguration
    },
    cgSolitaireObj(state, newSolitaireObj) {
      state.solitaireObj = newSolitaireObj
    },
    cgSolitaireConfig(state, newSolitaireConfig) {
      state.solitaireConfig = newSolitaireConfig
    },
    cgQuery(state, newQuery) {
      state.query = newQuery
    },
    cgLoginInfo(state, newLoginInfo) {
      state.loginInfo = newLoginInfo
    },
    cgAddTopicCommand(state, newAddTopicCommand) {
      state.addTopicCommand = newAddTopicCommand
    },
    cgImageReviewQueue(state, newImageReviewQueue) {
      state.imageReviewQueue = newImageReviewQueue
    },
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin]
})
