<template>
  <div class="SolitaireCompletionSituation">
    <!-- 标题 -->
    <div class="titleWrap">
      <h3>反馈完成情况（{{ obj.count }}/{{ obj.namelistArr.length }}）</h3>
      <el-dropdown @command="SCSHandleCommand">
        <span class="el-dropdown-link">一行 {{ obj.aFew }} 列<i class="el-icon-arrow-down el-icon--right"></i></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(index) in 7" :key="index" :command="index">一行 {{ index }} 列</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 内容 -->
    <div class="contentWrap">
      <div class="content" :style="contentStyle">
        <div
          class="item"
          :class="{ 'activeItem': item.done }"
          v-for="(item, index) in namelistArr"
          :key="index"
        >
          <div class="number">{{ item.id || index + 1 }}</div>
          <div class="name">{{ item.name }}</div>
          <i class="el-icon-check status" :class="{'activeStatus': item.done}"></i>
        </div>
      </div>
      <!-- 更多 -->
      <el-row class="moreContent" v-if="obj.namelistArr.length > obj.defaultNumber">
        <el-col :span="24" class="moreContentBtn">
          <template v-if="obj.showNumber < obj.namelistArr.length">
            <el-button type="info" plain @click="handleAddMore">显示更多</el-button>
          </template>
          <template v-else-if="obj.showNumber >= obj.namelistArr.length">
            <el-button type="info" plain @click="handleLessMore">收起更多</el-button>
          </template>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

import {getPriticipageNames} from '@/components/SolitaireDetails/utils/getPriticipageNames'
import {changeRowCol, setRowColParams} from '@/components/SolitaireDetails/utils'

export default {
  name: 'SolitaireCompletionSituation',
  props: {
    scs: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      obj: {
        // 组件外部传入
        id: '',
        isnameclock: '',
        namelistArr: [],
        rowcolumn: 7,
        count: 0,
        // 组件内部使用
        aFew: 7,
        showNumber: 49,
        defaultNumber: 49,
      },
      contentStyle: {
        gridTemplateColumns: 'repeat(7, 1fr)'
      },
      moreBtnText: '显示更多',
    }
  },
  computed: {
    namelistArr() {
      return this.obj.namelistArr.slice(0, this.obj.showNumber)
    },
  },
  async created() {
    this.obj = {...this.obj, ...this.scs}

    // 初始化一行几列
    setRowColParams.call(this, this.obj.rowcolumn)

    // 获取反馈接龙参与记录精简列表, 用于计算接龙完成情况
    await getPriticipageNames.call(this, this.obj.id)
  },

  methods: {
    SCSHandleCommand(command) {
      changeRowCol.call(this, command)
    },
    handleAddMore() {
      this.obj.showNumber += this.obj.defaultNumber
    },
    handleLessMore() {
      this.obj.showNumber = this.obj.defaultNumber
    },
  },
}
</script>

<style lang="scss" scoped>
.titleWrap {
  margin: 0 20px;
  // border-bottom: 1px solid #ebedf0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }
}

.contentWrap {
  padding: 0 20px 20px;

  .content {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 10px;

    .item {
      height: 68px;
      border: 2px solid #ccc;
      // aspect-ratio: auto 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      text-align: center;
      color: gray;
      border-radius: 5px;

      .status {
        width: 20px;
        height: 20px;
        position: absolute;
        top: -1px;
        right: -1px;
        border-radius: 0 4px 0 5px;
        color: transparent;
        transition: all 0.3s ease-in-out;

        &.activeStatus {
          background-color: #18c631;
          color: #fff;
        }
      }
    }

    .activeItem {
      border-color: #18c631;
      color: #18c631;
    }
  }

  .moreContent {
    margin: 10px 0 0;

    .moreContentBtn {
      text-align: center;
    }
  }
}
</style>
