<template>
  <div class="solitaire_introduction">
    <div class="title-box">
      <el-input :value="solitaireObj.title" :placeholder="solitaireConfig.title" @input="cgSolitaireObjFun"></el-input>
    </div>
    <div class="introduction-box">
      <!--拖拽组件 修改排序的功能-->
      <Drag
        v-slot:contentIntroduction="{item,index}"
        :selector="'.move'"
        :list='solitaireObj.contentElementRelationlist'
        @cgSort=cgContentIntroductionSort
      >
        <!--<div class="introduction-item" v-for="(item,index) in solitaireObj.contentElementRelationlist" :key="index">-->

        <div class="introduction-item">
          <div class="textarea" v-if="item.elementName==='文字'">
            <!--介绍接龙输入框-->
            <el-input
              type="textarea"
              placeholder="请添加内容说明"
              v-model="item.describtion1"
              :autosize="{ minRows: 4, maxRows: 8}">
            </el-input>
            <div class="operateList">
              <!--删除按钮-->
              <div><span @click="deleteTextBox(index)" class="el-icon-delete"></span></div>
              <!--移动按钮-->
              <div><span class="iconfont icon-move1 move"></span></div>
            </div>
          </div>

          <div class="big_picture" v-if="item.elementName==='大图'">
            <div class="image-box" @click="previewPicture(index)">
              <!--<img :src="item.describtion1" class="image" alt="">-->
              <el-image
                class="big_image"
                fit="cover"
                :src="item.describtion1"
                lazy />
            </div>
            <div class="operateList">
              <!--&lt;!&ndash; 删除按钮 &ndash;&gt;-->
              <!--<div class="el-icon-delete" @click="deleteTextBox(index)"></div>-->
              <!--&lt;!&ndash;移动按钮&ndash;&gt;-->
              <!--<div class="iconfont icon-move1 move"></div>-->
              <!--删除按钮-->
              <div><span @click="deleteTextBox(index)" class="el-icon-delete"></span></div>
              <!--移动按钮-->
              <div><span class="iconfont icon-move1 move"></span></div>
            </div>
          </div>

          <div class="small_picture-box" v-if="item.elementName==='小图'"
               v-loading="item.loading"
               element-loading-text="文件上传中..."
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
          >
            <div class="small_picture-list">
              <div class="small_picture-item" @mouseover="smallFileIndex=(index+'-'+i)" @mouseleave="smallFileIndex=''" v-for="(element,i) in item.describtion1" :key="i">
                <div class="picture-box">
                  <el-image
                    class="small_image"
                    fit="fill"
                    :src="element"
                    lazy>
                  </el-image>
                  <!--遮罩层-->
<!--                  <div class="no-mask-layer box-center" >
                    <div class="operation-box">
                      &lt;!&ndash;预览小图片&ndash;&gt;
                      <el-tooltip content="预览图片" placement="top" effect="light">
                        <div class="el-icon-view" @click="previewPicture(index,i)"></div>
                      </el-tooltip>
                      &lt;!&ndash;删除图片&ndash;&gt;
                      <el-tooltip content="删除图片" placement="top" effect="light">
                        <div class="el-icon-delete" @click="deleteSmallFile(index,i)"></div>
                      </el-tooltip>
                    </div>
                  </div>-->
                  <!--图片遮罩层（操作） -->
                  <pictureOperate
                  @previewPicture="previewPicture(index,i)"
                  @deleteEvent="deleteSmallFile(index,i)"
                  ></pictureOperate>
                </div>
              </div>
              <!--:preview-src-list="item.describtion1"-->
              <div class="small_picture-item add" @click="clickCurrentRef='smlPic'+index" v-if="item.describtion1.length<9">
                <el-upload
                  class="avatar-uploader"
                  :ref="'smlPic'+index"
                  :action="action"
                  :auto-upload="true"
                  :show-file-list="false"
                  :multiple="item.describtion1.length<=7"
                  accept="image/*"
                  :limit="9-item.describtion1.length"
                  :data="OSSObj"
                  :on-change="fileStatusCg"
                  :http-request="uploadCustomizeFunc"
                  list-type="picture"
                >
                  <div class="add-box">
                    <div>
                      <div class="iconfont icon-add-bold icon"></div>
                      <div class=" ">上传图片</div>
                    </div>
                  </div>
                </el-upload>
              </div>
            </div>
            <div class="operateList">
              <!--删除按钮-->
              <div><span @click="deleteTextBox(index)" class="el-icon-delete"></span></div>
              <!--移动按钮-->
              <div><span class="iconfont icon-move1 move"></span></div>
            </div>
          </div>

          <div class="video-box" v-if="item.elementName==='视频'">
            <video class="video" width="320" height="240" controls>
              <source :src="item.describtion1" type="video/mp4">
              您的浏览器不支持 视频播放。
            </video>
            <div class="operateList">
              <!--<div class="el-icon-delete" @click="deleteTextBox(index)"></div>-->
              <!--删除按钮-->
              <div><span @click="deleteTextBox(index)" class="el-icon-delete"></span></div>
              <!--移动按钮-->
              <div><span class="iconfont icon-move1 move"></span></div>
            </div>
          </div>

          <div class="uploadFiles-box" v-if="item.elementName==='附件'">
            <div class="uploadFiles-content" @click="clickCurrentRef='smlFj'+index">
              <el-upload
                class="avatar-uploader"
                :ref="'smlFj'+index"
                :action="action"
                :auto-upload="true"
                :show-file-list="false"
                :multiple="item.describtion1.length<=7"
                accept=""
                :limit="9-item.describtion1.length"
                :data="OSSObj"
                :http-request="uploadCustomizeFunc"
                list-type="picture"
              >
                <div class="uploadFiles"><span class="icon el-icon-folder-opened"></span>上传文件</div>
              </el-upload>

              <div class="uploadFiles-list">
                <div class="uploadFiles-item" @mouseover="smallFileIndex=(index+'-'+i)" @mouseleave="smallFileIndex=''" v-for="(element,i) in item.describtion1" :key="i">
                  <div class="a-box">
                    <a class="a" target="_blank" :href="element.path">{{ element.name }}</a>
                  </div>
                  <div class="deleted-icon el-icon-delete" v-show="smallFileIndex===(index+'-'+i)" @click="deleteSmallFile(index,i)"></div>
                </div>
              </div>
            </div>
            <div class="operateList">
              <!--删除按钮-->
              <div><span @click="deleteTextBox(index)" class="el-icon-delete"></span></div>
              <!--移动按钮-->
              <div><span class="iconfont icon-move1 move"></span></div>
            </div>
          </div>
        </div>
      </Drag>
    </div>


    <!--添加接龙介绍内容组件-->
    <div class="add_introduction">
      <div class="img_box" v-for="(item,index) in contentElementList" :key="index">
        <!--<div class="introduce-type" v-if="item.elementName ==='文字'">-->
        <!--<div class="introduce-type" v-if="['文字','附件'].includes(item.elementName)">-->
        <div class="introduce-type" @click="addIntroduction(item.ref,index)" v-if="item.elementName==='文字' || item.elementName==='附件'">
          <el-image
            style="width: 40px; height: 40px"
            :src="item.elementIcon"
            fit="contain"></el-image>
          <div>{{ item.elementName }}</div>
        </div>

        <!--<div class="introduce-type" v-else-if="item.elementName ==='大图'">-->
        <div class="introduce-type" v-else>
          <el-upload
            class="avatar-uploader"
            :ref="item.ref"
            :action="action"
            :auto-upload="true"
            :show-file-list="false"
            :multiple="item.elementName ==='小图'"
            :accept="item.typeSuffix"
            :limit="item.limit"
            :data="OSSObj"
            :on-change="fileStatusCg"
            :http-request="uploadCustomizeFunc"
            list-type="picture"
          >
            <!--:on-change="uploadFileStatusCg"-->
            <div @click="addIntroduction(item.ref,index)">
              <el-image
                style="width: 40px; height: 40px"
                :src="item.elementIcon"
                fit="contain">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>

              <div>{{ item.elementName }}</div>
            </div>
          </el-upload>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import Drag from '../../public/Drag'
import {listenSolitaireChangeObj} from '@/utils/public/publicFunc';
import {deepCopy} from '@/assets/js/tools/common';
import {uploadFiles} from '@/utils/public/customizeUploadFile';
import pictureOperate from '@/components/public/pictureOperate'

export default {
  name: 'SolitaireIntroduction',
  components: {Drag, pictureOperate},
  data() {
    return {
      solitaireConfig: {},
      solitaireObj: {},
      contentElementList: [],//反馈元素
      // action: ossHostUpload,//上传OSS图片的域名
      action: '',//上传OSS图片的域名
      OSSSignatureObj: {},//oss的上传签名数据
      OSSObj: {},//上传OSS文件的参数
      clickCurrentRef: '',//点击当前的 元素的ref
      XTTemporaryURLList: [],//小图的临时路径（上传成功便成为远程图片路径）
      fileList: [],//上传的文件列表
      smallFileIndex: '',// 小文件的索引组合 1-2（第2个接龙介绍内容的，第3个小文件）
      imageList: [],
      //临时路径列表
      temporaryUrlList: [],
    }
  },
  mounted() {
    this.solitaireConfig = this.$store.state.solitaireConfig
    this.solitaireObj = deepCopy(this.$store.state.solitaireObj)
    this.contentElementList = deepCopy(this.solitaireConfig.contentElementList)
    this.contentElementList.forEach((item) => {
      switch (item.elementName) {
        case '大图':
          item.ref = 'dt'
          item.typeSuffix = 'image/*' //上传的类型
          item.limit = 1 //一次最多可上传的个数
          break;
        case '小图':
          item.ref = 'xt'
          item.typeSuffix = 'image/*' //上传的类型
          item.limit = 9//一次最多可上传的个数
          item.loading = false
          break;
        case '视频':
          item.ref = 'sp'
          item.typeSuffix = 'video/mp4' //上传的类型
          item.limit = 1//一次最多可上传的个数
          break;
        case '附件':
          item.ref = 'fj'
          item.typeSuffix = '' //上传的类型（所有类型）
          item.limit = 1//一次最多可上传的个数
          break;
      }
    })
    console.log('solitaireObj.contentElementList ==>', this.solitaireObj)
    console.log('this.contentElementList ==>', this.contentElementList)

  },
  methods: {
    /**
     *修改 接龙内容介绍的顺序(拖拽)
     */
    cgContentIntroductionSort(data) {
      console.log('data ==>', data)
      let {removedIndex, addedIndex} = data
      let list = deepCopy(this.solitaireObj.contentElementRelationlist)
      let element = deepCopy(list[removedIndex])
      list.splice(removedIndex, 1)
      list.splice(addedIndex, 0, element)
      this.solitaireObj.contentElementRelationlist = list
    },
    /**
     * 预览图片
     */
    previewPicture(index, i) {

      let describtion1 = this.solitaireObj.contentElementRelationlist[index].describtion1
      this.imageList = typeof describtion1 === 'string' ? [describtion1] : describtion1
      this.$viewerApi({
        images: this.imageList,
        options: {
          initialViewIndex: i || 0,//从第几张图片开始看（索引）
        },
      })
    },
    /**
     * 添加小图片的状态修改时触发
     */
    fileStatusCg(e) {
      console.log('状态修改时触发 e ==>', e)
      if (e.status === 'ready') {
        let currentRef = this.clickCurrentRef
        if (currentRef.includes('smlPic') || currentRef === 'xt') {
          this.temporaryUrlList.push(e.url)
          let leng = this.solitaireObj.contentElementRelationlist.length
          //添加内容在介绍内容列表的索引
          let index = currentRef !== 'xt' ? Number(currentRef.slice(6)) : (leng - 1)
          this.solitaireObj.contentElementRelationlist[index].loading = true
        } else if (/sp|dt/.test(currentRef)) {//视频 和 大图 的接龙介绍
          this.temporaryUrlList = [e.url]
        }
        // console.log('状态修改时触发 currentRef ==>', currentRef)
      }
    },
    /**
     * 上传文件自定义方法
     *
     * 不用组件的自动上传文件，是因为上传多个文件时，所传的参数无法改变
     */
    async uploadCustomizeFunc(file) {
      console.log('file ==>', file)
      this.fileList.push(file)
      //使用 延时函数的原因是 如果是 多选 文件的时候，自定义上传函数会多次触发，每次只加载一个文件，
      //需要等所有文件都加载完成之后，再合并再一起，进行统一调用接口上传
      clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        let fileList = this.fileList //上传的文件列表
        let urlList = []  //成功上传文件后的完整路径列表
        let currentRef = this.clickCurrentRef
        for (let i = 0; i < fileList.length; i++) {//批量上传文件
          let url = await uploadFiles.call(this, fileList[i]).catch(() => {})
          if (!url) {
            console.log('上传文件失败')
            continue;
          }
          urlList.push(url)
        }
        console.log('urlList ==>', urlList)
        console.log('currentRef ==>', currentRef)
        let contentElementRelationlist = this.solitaireObj.contentElementRelationlist
        //上传图片的位置是 接龙介绍内容里面的 上传图片
        if (currentRef.includes('smlPic')) {
          //上传小图图片的位置，在介绍内容列表的索引
          let index = Number(currentRef.slice(6));
          //展示页面的图片临时路径列表（节省服务器的访问流量，发布接龙时再替换成 远程路径）
          contentElementRelationlist[index].describtion1.push(...this.temporaryUrlList)
          //
          contentElementRelationlist[index].describtion2.push(...urlList)
          contentElementRelationlist[index].loading = false;
        } else if (currentRef.includes('smlFj')) {
          console.log('上传附件文件')
          let index = Number(currentRef.slice(5));//上传附件文件的位置，在介绍内容列表的索引
          // eslint-disable-next-line no-case-declarations
          let fileObjList = []
          this.fileList.forEach((item, index) => {
            let file = item.file
            fileObjList.push({
              name: file.name,
              path: urlList[index],
              size: file.size,
              type: 'file'
            })
          })
          let describtion1 = contentElementRelationlist[index].describtion1 || []
          describtion1.length === 0 ? describtion1 = fileObjList : describtion1.push(...fileObjList)
          contentElementRelationlist[index].describtion1 = describtion1
        } else {//添加接龙内容介绍
          this.addSolitaireIntroduced(urlList);
        }

        this.solitaireObj.contentElementRelationlist = contentElementRelationlist

        // console.log('this.solitaireObj.contentElementRelationlist ==>', this.solitaireObj.contentElementRelationlist)
        let newImageReviewQueue = `${this.$store.state.imageReviewQueue}${urlList.join(',')},`//图片审核队列（用于校验图片是否有违规）
        this.$store.commit('cgImageReviewQueue', newImageReviewQueue)
        console.log(' this.$refs ==>', this.$refs)
        console.log('currentRef ==>', currentRef)
        console.log(' this.$refs[currentRef] ==>', this.$refs[currentRef])
        if (this.$refs[currentRef][0]) {
          this.$refs[currentRef][0].clearFiles() //历史上传文件置空，避免上传重复的文件
        } else {
          this.$refs[currentRef].clearFiles() //历史上传文件置空，避免上传重复的文件
        }
        this.fileList = []//历史上传文件置空，避免上传重复的文件
        this.temporaryUrlList = []//临时文件路径置空
        console.log('this.urlList ==>', urlList)
      })
    },

    /**
     * 删除小文件
     * @param index 接龙内容介绍 索引
     * @param i 接龙内容介绍的小文件 索引
     */
    deleteSmallFile(index, i) {
      this.solitaireObj.contentElementRelationlist[index].describtion1.splice(i, 1);
      this.solitaireObj.contentElementRelationlist[index].describtion2.splice(i, 1);
    },
    /**
     * 添加接龙介绍
     *
     * @param urlList 上传成功后的 文件链接列表
     */
    addSolitaireIntroduced(urlList) {
      let currentRef = this.clickCurrentRef
      let addContent = deepCopy(this.solitaireConfig.contentElementList.filter((item, index) => {
        return this.contentElementList[index].ref === currentRef
      })[0])
      let temporaryUrlList = this.temporaryUrlList//临时文件路径列表
      let leng = this.solitaireObj.contentElementRelationlist.length
      addContent.id = ''
      switch (currentRef) {
        case 'dt'://大图
        case 'sp'://视频
          addContent.describtion1 = temporaryUrlList[0]
          addContent.describtion2 = urlList[0];
          console.log('temporaryUrlList ==>', deepCopy(temporaryUrlList))
          this.solitaireObj.contentElementRelationlist.push(addContent)
          break;
        case 'xt'://小图
          this.solitaireObj.contentElementRelationlist[leng - 1].describtion1 = temporaryUrlList
          this.solitaireObj.contentElementRelationlist[leng - 1].describtion2 = urlList
          this.solitaireObj.contentElementRelationlist[leng - 1].loading = false
          break;
        case 'fj'://附件
          addContent.describtion1 = temporaryUrlList
          addContent.describtion2 = urlList;
          this.solitaireObj.contentElementRelationlist.push(addContent)
          break;
      }
      // addContent.describtion1 = isXt ? [this.temporaryURL] : this.temporaryURL


    },
    /**
     * 添加内容介绍
     */
    addIntroduction(currentRef, index) {
      // eslint-disable-next-line no-unreachable
      let addContent = deepCopy(this.solitaireConfig.contentElementList[index])
      console.log('addContent ==>', addContent)
      addContent.id = ''
      let elementName = addContent.elementName
      switch (elementName) {
        case '文字':
        case '附件':
          this.solitaireObj.contentElementRelationlist.push(addContent)
          break;
        case '小图':
          addContent.describtion1 = []
          addContent.describtion2 = []
          this.solitaireObj.contentElementRelationlist.push(addContent)
          this.clickCurrentRef = currentRef
          break;
        default :
          this.clickCurrentRef = currentRef
      }
    },
    /**
     * 修改标题
     */
    cgSolitaireObjFun(e) {
      console.log('修改标题 ==》', e)
      this.solitaireObj.title = e
    },
    /**
     * 删除接龙介绍文本输入框
     */
    deleteTextBox(index) {
      this.solitaireObj.contentElementRelationlist.splice(index, 1)
    },
  }
  ,
  watch: {
    ...
      listenSolitaireChangeObj
  }
}
</script>

<style lang="scss" scoped>
//@import "../../../assets/scss/public";
$smlPic-item-wh: 130px; //小图片盒子的宽高度
.solitaire_introduction {
  width: 100%;
  padding: 30px 20px;
  background-color: #ebedf0;
  box-sizing: border-box;

  .introduction-box {
    .introduction-item {
      margin-top: 20px;

      & > div {
        display: flex;

        .operateList {
          width: 35px;
          margin: 5px 0 0 auto;
          padding-left: 5px;
          display: flex;
          //flex-wrap: wrap;
          //align-items: stretch;
          flex-direction: column;
          justify-content: space-around;

          div {
            span {
              &:nth-of-type(1) {
                cursor: pointer;
              }
            }

            .move {
              cursor: move !important;
            }
          }


        }
      }

      .big_picture {
        width: 100%;

        .image-box {
          //flex: 1;

          .big_image {
            width: 100%;
          }
        }
      }

      .small_picture-box {
        display: flex;

        .small_picture-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .avatar-uploader {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .small_picture-item {
            width: $smlPic-item-wh;
            height: $smlPic-item-wh;
            margin: 10px 1%;
            padding: 5px;
            box-sizing: border-box;
            border: 1px solid silver;
            border-radius: 5px;


            .picture-box {
              width: 100%;
              height: 100%;
              position: relative;

              .small_image {
                width: 100%;
                height: 100%;
              }

              .no-mask-layer {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                background-color: rgba(0, 0, 0, 0);
                color: transparent;
                transition: all 0.5s;
                position: absolute;
                top: 0;
                left: 0;

                &:hover {
                  background-color: rgba(0, 0, 0, 0.5);
                  color: #d5d5d5;
                }

                .operation-box {
                  display: flex;

                  & > div {
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                    margin: 0 5px;
                    //transition: all 0.5s;
                    &:hover {
                      color: white;
                      font-size: 18px;
                    }

                  }
                }

              }
            }
          }
        }

        .add {
          background-color: #e7e7e7;

          cursor: pointer;

          .add-box {
            width: 120px;
            height: $smlPic-item-wh;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .icon {
            margin-bottom: 10px;
          }
        }
      }

      .video-box {
        width: 100%;
        display: flex;

        .video {
          flex: 1;
        }
      }

      .uploadFiles-box {
        flex: 1;
        margin-top: 40px;

        .uploadFiles-content {
          width: 100%;

          .uploadFiles {
            width: 100%;
            padding: 10px 0;
            border: 1px solid silver;
            border-radius: 4px;
            font-size: 14px;
            cursor: pointer;
            transition: all .5s;

            &:hover {
              border-color: #fb9900;
            }

            & > .icon {
              margin-right: 5px;
              font-size: 18px;
              color: #fb9900;
            }
          }

          .uploadFiles-item {
            width: 100%;
            margin-top: 10px;
            text-align: left;
            display: flex;

            a {
              margin-top: 10px;
            }

            .deleted-icon {
              margin-left: auto;
              font-size: 12px;
              cursor: pointer;
            }


          }
        }
      }
    }


  }


  //添加接龙介绍
  .add_introduction {
    width: 100%;
    margin: 20px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px;

    .img_box {
      cursor: pointer;

      .introduce-type {
        .avatar-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }

        .avatar-uploader .el-upload:hover {
          border-color: #409EFF;
        }
      }
    }
  }

}
</style>
<style>
.el-upload {
  width: 100%;
}
</style>
