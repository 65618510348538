export const tabList = [
  {
    name: 'mySolitaire',
    label: '我发布',
    // tag: 'mySolitaire'
    solitaireList:[],
    isContinueLoading:true,//是否继续加载数据
  },
  {
    name: 'myParticaipate',
    label: '我参与',
    // tag: 'myParticaipate'
    solitaireList:[],
    isContinueLoading:true,//是否继续加载数据
  },
  {
    name: 'myManager',
    label: '我管理',
    // tag: 'myManager'
    solitaireList:[],
    isContinueLoading:true,//是否继续加载数据
  },
]// 导航标签列表


export const solitaireTypeTextList = ['信息收集','天天打卡','问卷调查','投票评选','活动报名','','在线考试']

