//引入axios请求 并配置域名
import axios from 'axios'
import {Message} from 'element-ui'
import {requestHeaderHook} from '@/utils/tokenHandler'

export const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 6000, // 请求超时时间
})
// request interceptor(请求拦截器)
request.interceptors.request.use(async config => {
   // 请求头添加token
  config.headers = await requestHeaderHook(config.headers)
  // console.log('请求拦截器 config:', config)
  return config
})
//（接收拦截器）
request.interceptors.response.use((response) => {
  // console.log("response ==>", response)
  if (response.status === 200) {
    let {checkResult} = response.data
    // console.log("checkResult ==>", checkResult)
    if (response.data === '') {
      return response
    }
    if (checkResult?.allowed) {
      return response
    }
    if (!checkResult?.allowed) {
      // console.log('走这里')
      let message = `请求失败,${checkResult.resultMessage}`
      Message({
        message,
        type: 'error',
        duration: 5 * 1000
      })
      // console.log("checkResult ==>", checkResult)
      return Promise.resolve({checkResult})
    }
  }
})
