<template>
  <!--遮罩层-->
  <div class="no-mask-layer box-center">
    <div class="operation-box">
      <!--预览小图片-->
      <el-tooltip content="预览图片" placement="top" effect="light">
        <div class="el-icon-view" @click="previewPicture"></div>
      </el-tooltip>
      <!--删除图片-->
      <el-tooltip content="删除图片" placement="top" effect="light">
        <div class="el-icon-delete" @click="deleteEvent"></div>
      </el-tooltip>
    </div>
  </div>
</template>

<!--使用该组件时需要在该组件的外部父盒子添加 position：reactive|absolute 属性-->
<script>
export default {
  name: "pictureOperate",
  data() {
    return {

    }
  },
  methods:{
    /**
    * 点击预览图片
    */
    previewPicture(){
      this.$emit("previewPicture")
    },
    /**
    * 点击删除图片
    */
    deleteEvent(){
      this.$emit("deleteEvent")
    },
  }
}
</script>

<style lang="scss" scoped>
.no-mask-layer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
  color: transparent;
  transition: all 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    color: #d5d5d5;
  }

  .operation-box {
    display: flex;

    & > div {
      width: 18px;
      height: 18px;
      cursor: pointer;
      margin: 0 5px;
      //transition: all 0.5s;
      &:hover {
        color: white;
        font-size: 18px;
      }

    }
  }

}
</style>
