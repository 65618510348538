<template>
  <div class="typetemplate">
    <!--布局容器-->
    <el-container>
      <el-header>
        <Head>
          <template v-slot:typetemplate>
            <!--个人信息开始-->
            <div class="avatar">
              <el-menu class="el-menu-demo" active-text-color="#ebedf0" background-color="#ebedf0" mode="horizontal" @select="handleSelect">
                <el-submenu index="1">
                  <!--个人头像开始-->
                  <template slot="title">
                    <el-avatar :size="30" :src="userInfo.headimgurl"></el-avatar>
                    <span class="nameSpan">{{userInfo.nickname}}</span>
                  </template>
                  <!--个人头像结束-->
                  <!--移入头像时显示的内容-->
                  <!--<el-menu-item index="1-1">帮助文档</el-menu-item>-->
                  <!--<el-menu-item index="1-2">产品交流</el-menu-item>-->
                  <el-menu-item index="1-3" @click="signOutFun">退出</el-menu-item>
                </el-submenu>
              </el-menu>
            </div>
            <!--个人信息结束-->
          </template>
        </Head>
      </el-header>
      <!--容器头部结束-->
      <!--容器的内容开始-->
      <el-main>
        <el-row type="flex" justify="center">
          <el-col :xs="20" :sm="18" :md="16" :lg="16" :xl="14">
            <div class="main">
              <!--说明类型文本-->
              <div class="type-text">{{ typeText }}</div>
              <!--新建发布-->
              <div class="new-create" @click="jumpCreatePage">
                <div class="iconfont icon-add"></div>
                <div class="new-create-text">新建发布</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <!--模板内容-->
        <TemplateContent></TemplateContent>
      </el-main>
      <!--容器的内容结束-->
    </el-container>
  </div>
</template>

<script>
import {SOLITAIRE_TYPE_LIST} from '@/tempData/solitaireTempData';
import TemplateContent from '@/components/TemplateContent';
import Head from '@/components/Head';
import {getLocalStorage} from '@/assets/js/tools/common';

export default {
  name: 'TypeTemplate',
  components: {TemplateContent, Head},
  data() {
    return {
      typeText: '',
      solitaireType: 0,
      userInfo:{},//用户信息
    }
  },
  created() {
    this.createdInit();
  },
  methods: {
    /**
     * 初始化方法
     */
    createdInit() {
      console.log('this.$route ==>', this.$route.params.solitaireType)
      this.getTypeText()
      this.userInfo = getLocalStorage('userInfo')
    },
    /**
     * 退出登录
     */
    signOutFun(){
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.removeItem('userInfo')
        this.$router.push({name:'Template'})
      }).catch(() => {

      });
    },
    /**
     * 跳转到创建页面
     */
    jumpCreatePage() {
      this.$message('正在开发中')
      // this.$router.push({name: 'CreateSolitairePage', params: {solitaireType: this.solitaireType}})
    },
    /**
     * 获取类型描述
     */
    getTypeText() {
      this.solitaireType = this.$route.params.solitaireType
      this.typeText = SOLITAIRE_TYPE_LIST.filter(item => item.solitaireType == this.solitaireType)[0].title
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>

<style lang="scss" scoped>
$new-create_fontSize: 18px;
//头像
.avatar {;
  display: flex;
  align-items: center;
  cursor: pointer;

  .nameSpan {
    margin-left: 10px;
  }
}

//内容
.main {
  //width: 70vw;
  width: 100%;
  margin: auto;
  //background-color: #2e6eff;
  .type-text {
    font-size: 20px;
  }

  //新建发布盒子
  .new-create {
    width: 38%;
    height: 140px;
    margin: 40px auto;
    background-color: #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $new-create_fontSize;
    cursor: pointer;

    .icon-add {
      font-size: $new-create_fontSize;
    }

    //新建发布 文本
    .new-create-text {
      margin-left: 10px;
    }
  }
}
</style>
<style>


/*.el-submenu__title{*/
/*  !*background-color: #3c5267;*!*/
/*  !*background-color: rgb(47, 60, 77);*!*/
/*  background-color: #ebedf0;*/
/*}*/
</style>
