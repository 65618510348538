<template>
  <div class="topicContent-box" @mouseover="isShowBasicInfoEditIcon=true" @mouseleave="isShowBasicInfoEditIcon=false">
    <div class="legendTitle">
      <div class="legendTitle_text">{{ legendTitle === '考试问题' ? legendTitle + '（总分 ' + totalScore + '）' : solitaireObj.basicInfoObj.title }}</div>
      <div class="text_import" v-if="legendTitle === '考试问题'">
        <el-link type="primary" @click="textImportFun">文本导入</el-link>
      </div>
      <!--基本信息 操作 删除 编辑等-->
      <div class="text_import icons" v-else-if="legendTitle==='基本信息' && isShowBasicInfoEditIcon">
        <!--<span class="iconfont icon-delete1 icons-item" @click="deleteTopicFun(index)"></span>-->
        <span class="el-icon-edit-outline icons-item" @click="editBasicInfoFun"></span>
      </div>
    </div>
    <div class="topicContent">
      <!--拖拽组件 修改排序的功能-->
      <Drag
        v-slot:contentIntroduction="{item,index}"
        :selector="'.move'"
        :list='list'
        @cgSort='cgTopicSort'
      >
        <div class="topicContent-item"
             :class="{hover:legendTitle==='考试问题' && mousemoveIndex===index}"
             @mousemove="mousemoveFun(index)"
             @mouseleave="mousemoveIndex=-1">
          <div class="topicTitle">
            <div class="info">
              <!--是否必填-->
              <span>*</span>

              <!--题目序号-->
              <span>{{ index + 1 }}.</span>

              <!--题目标题-->
              <span>{{ legendTitle === '考试问题' ? item.fieldTips : item.typeText }}
                    <!--题目的类型（单选 多选等等）-->
                  <span v-if="legendTitle !== '基本信息'">【{{ item.text }}】</span>
                    <!--该题目的分数 （只有   考试问题  时才有）-->
                  <span class="score" v-if="legendTitle==='考试问题' ">{{ '(' + item.score + '分)' }}</span>
            </span>
            </div>

<!--            &lt;!&ndash;题目的一些 操作 删除 编辑等&ndash;&gt;
            <div class="icons" v-if="legendTitle==='考试问题' && mousemoveIndex===index">
              &lt;!&ndash;<div class="icons">&ndash;&gt;
              &lt;!&ndash;删除按钮&ndash;&gt;
              <span class="el-icon-delete icons-item" @click="deleteTopicFun(index)"></span>
              &lt;!&ndash;编辑按钮&ndash;&gt;
              <span class="el-icon-edit-outline icons-item" @click="editTopicFun(index) "></span>
              &lt;!&ndash;移动按钮&ndash;&gt;
              <span class="iconfont icon-move1 icons-item move"></span>
            </div>-->


          </div>
          <div v-if="legendTitle==='基本信息'" class="box">
            <span v-if="item.text==='签名'">手写签名（该功能仅支持在小程序端使用）</span>
            <!--<span v-else></span>-->
          </div>
          <div v-else-if="legendTitle==='考试问题'" class="options-box">
            <div class="options">
              <template v-if="item.text==='单选'">
                <el-radio-group>
                  <div v-for="(element,i) in item.defaultValue" :key="i">
                    <el-radio disabled class="option">
                      <span>{{ element }}</span>
                      <span v-if="i==item.answer" class="answer">(答案)</span>
                    </el-radio>
                  </div>
                </el-radio-group>
              </template>
              <template v-else-if="item.text==='多选'">
                <el-checkbox-group>
                  <div v-for="(element,i) in item.defaultValue" :key="i">
                    <el-checkbox disabled class="option">
                      <span>{{ element.content }}</span>
                      <span v-if="element.isAnswer" class="answer">(答案)</span>
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </template>
            </div>
          </div>
          <!--题目的一些 操作 删除 编辑等-->
          <div class="icons" v-if="legendTitle==='考试问题' && mousemoveIndex===index">
            <!--<div class="icons">-->
            <!--删除按钮-->
            <div class="el-icon-delete icons-item" @click="deleteTopicFun(index)"></div>
            <!--编辑按钮-->
            <div class="el-icon-edit-outline icons-item" @click="editTopicFun(index) "></div>
            <!--移动按钮-->
            <div class="iconfont icon-move1 icons-item move"></div>
          </div>
        </div>
      </Drag>
    </div>

    <!--录题编辑-->
    <template v-if="legendTitle==='考试问题'">
      <el-dialog
        title="文本导入"
        :visible.sync="showImportText"
        width="50%"
        :before-close="handleClose">
        <div class="textImport_edit_box">
          <div class="notes_box">
            <div class="notes-item">1、第一行为题目。在此行开头需标注 "【题目】"，结尾说明题目类型"【单选】"或者"【多选】"，中间不能换行。</div>
            <div class="notes-item">2、第二行为分值。只能设置数字，中间不能换行。</div>
            <div class="notes-item">3、最后一行为答案。在此行开头需标注 "【答案】"，多个答案时，以"|"此符号分割，中间不能换行。</div>
            <div class="notes-item">4、第三行到倒数第二行为选项，选项不能少于2个，中间不能换行。</div>
            <div class="notes-item">5、答案跟选项需要匹配得上
              <span @click="exampleFun" class="example">示例</span>
              <span @click="emptyFun" class="example" :class="{doNotEmpty:textarea ===''}">清空</span>
            </div>
          </div>
          <div class="textarea">
            <el-input
              type="textarea"
              :rows="16"
              placeholder="请输入要添加的题目"
              v-model="textarea">
            </el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showImportText = false">取 消</el-button>
          <el-button type="primary" @click="confirmEntry">确 定</el-button>
        </span>
      </el-dialog>
    </template>

    <div class="tips" v-if="isShowAlert">
      <el-result icon="error" title="错误提示" :subTitle="errorMessage">
        <template slot="extra">
          <el-button @click="isShowAlert = false" type="primary" size="medium">确定</el-button>
        </template>
      </el-result>
    </div>

    <!--编辑题目-->
    <template v-if="legendTitle==='考试问题'">
      <el-dialog
        :title="'编辑题目【'+ editTopicObj.text +'】'"
        :visible.sync="isShowEditTitlePage"
        width="40%"
        :before-close="closeEditTopicPage">
        <div class="edit_title_page">
          <!--编辑标题-->
          <div class="edit_title">
            <div class="title_text">题目</div>
            <el-input
              class="title_input"
              placeholder="请输入题目"
              v-model="editTopicObj.fieldTips"
              clearable>
            </el-input>
          </div>
          <!--编辑标题结束-->
          <!-- 选项以及答案-->
          <div class="options_answer_box">
            <div class="legend_text">
              <span class="text">答案</span>
              <span class="option_text">选项</span>
            </div>

            <!--单选的选项 开始-->
            <div class="options_answer_list" v-if="editTopicType==='单选'">
              <el-radio-group v-model="radioAnswer" class="radio-group">
                <div class="radio_item" v-for="(item,index) in editTopicObj.defaultValue" :key="index">
                  <el-radio :label="index"><span></span></el-radio>
                  <el-input
                    class="option_input"
                    placeholder="请输入选项内容"
                    v-model="editTopicObj.defaultValue[index]"
                    clearable
                  >
                  </el-input>
                  <span class="iconfont icon-minus-circle remove_option" @click="removeOptionFun(index)"></span>
                </div>
              </el-radio-group>
            </div>
            <!--单选的选项 结束-->
            <!--多选的选项 开始-->
            <div class="options_answer_list" v-else-if="editTopicType==='多选'">
              <el-checkbox-group v-model="checkAnswerList" class="radio-group">
                <div class="radio_item" v-for="(item,index) in editTopicObj.defaultValue" :key="index">
                  <el-checkbox :label="item.content" @change="clickCheckbox(index)"><span></span></el-checkbox>
                  <el-input
                    class="option_input"
                    placeholder="请输入内容"
                    v-model="editTopicObj.defaultValue[index].content"
                    type="text"
                    clearable
                  >
                  </el-input>
                  <span class="iconfont icon-minus-circle remove_option" @click="removeOptionFun(index)"></span>
                </div>
              </el-checkbox-group>
            </div>
            <!--多选的选项 结束-->


          </div>
          <!-- 选项以及答案结束 -->
          <!--  添加选项开始-->
          <div class="add_option" @click="addOptionFun">
            <div class="text">
              添加选项
            </div>
          </div>
          <!--  添加选项开始-->
          <!--  题目分值-->
          <div class="edit_title">
            <div class="title_text">分值</div>
            <el-input
              class="title_input"
              type="number"
              placeholder="请输入该题的分值"
              v-model="editTopicObj.score"
              clearable>
            </el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeEditTopicPage">取 消</el-button>
          <el-button type="primary" @click="saveQuestionInfoFun">确 定</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 编辑基本信息-->
    <template v-else-if="legendTitle==='基本信息'">
      <el-dialog
        :title="'编辑基本信息'"
        :visible.sync="isShowEditBasicPage"
        width="40%"
        :before-close="closeEditBasicPage">
        <div class="edit_title_page">
          <!--编辑基本信息标题-->
          <div class="edit_title">
            <!--<div class="title_text">题目</div>-->
            <el-input
              class="title_input"
              placeholder="请输入题目"
              v-model="editBasicInfoObj.title"
              clearable>
            </el-input>
          </div>
          <!--编辑标题结束-->
          <!-- 选项以及答案-->
          <div class="options_answer_box">
            <div class="options_answer_list">
              <div class="radio_item" v-for="(item,index) in editBasicInfoObj.basicInfoList" :key="index">
                <el-input
                  class="option_input"
                  placeholder="请输入内容"
                  v-model="editBasicInfoObj.basicInfoList[index].typeText"
                  :clearable="item.text!=='签名'"

                >
                  <!--签名时输入框才会有图标-->
                  <div slot="suffix" v-if="item.text==='签名'" class="center">
                    <div><i class="iconfont  icon-dianziqianmingx"></i></div>
                  </div>
                </el-input>
                <span class="iconfont icon-minus-circle " :class="{remove_option:!item.default,disabled_remove_option:item.default}" @click="item.default?'':removeInformationItemFun(index)"></span>
              </div>
            </div>

          </div>
          <!--  添加信息项开始-->
          <div class="add_option">
            <div class="text" @click="addInformationItemFun">
              添加信息项
            </div>
            <div class="text" @click="addSignatureFun">
              添加手写签名
            </div>
          </div>
          <!--  添加信息项结束-->
          <!--   <div class="edit_title">
               <div class="title_text">分值</div>
               <el-input
                 class="title_input"
                 type="number"
                 placeholder="请输入该题的分值"
                 v-model="editTopicObj.score"
                 clearable>
               </el-input>
             </div>-->
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeEditBasicPage">取 消</el-button>
          <el-button type="primary" @click="saveBasicInfoFun">确 定</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import {exampleValue} from '@/assets/js/staticData/createSolitairePage';
import {deepCopy, filterDuplicates} from '@/assets/js/tools/common';
import Drag from '../../public/Drag'
import {listenSolitaireChangeObj} from '../../../utils/public/publicFunc';
// import {mapState} from 'vuex';

export default {
  name: 'TopicContent',
  props: ['legendTitle'],
  components: {Drag},
  data() {
    return {
      solitaireConfig: {},
      solitaireObj: {basicInfoObj: {}},
      //题目列表
      list: [],
      //是否显示导入文本编辑页
      showImportText: false,
      //是否显示编辑题目编辑页
      isShowEditTitlePage: false,
      //是否显示基本信息的编辑页
      isShowEditBasicPage: false,
      //是否显示错误提示
      isShowAlert: false,
      //错误提示文本
      errorMessage: '',
      exampleValue,
      //录题的文本
      textarea: '',
      //鼠标移入题目时的索引
      mousemoveIndex: -1,
      //编辑题目时的索引
      editTopicIndex: -1,
      //编辑题目时的内容对象
      editTopicObj: {},
      //编辑的题目的类型单选
      editTopicType: '单选',
      //编辑基本信息的对象内容
      editBasicInfoObj: {},
      //单选的答案
      radioAnswer: -1,
      //多选框的答案列表 (用于页面的展示 实际修改数据为clickCheckbox方法)
      checkAnswerList: [],
      value: 1,
      //是否显示 基本信息的编辑按钮
      isShowBasicInfoEditIcon: false,
    }
  },
  mounted() {
    this.solitaireConfig = this.$store.state.solitaireConfig
    this.solitaireObj = this.$store.state.solitaireObj
    if (this.legendTitle === '基本信息') {
      this.list = this.solitaireObj.basicInfoObj.basicInfoList
    } else if (this.legendTitle === '考试问题') {
      this.list = deepCopy(this.solitaireObj.elementRelationlist)
    }

  },
  methods: {
    /**
     * 修改题目的顺序
     */
    cgTopicSort(data) {
      let {removedIndex, addedIndex} = data
      // this.list = arrayLocationExchange(this.list, removedIndex, addedIndex)
      let element =deepCopy(this.list[removedIndex])
      this.list.splice(removedIndex,1)
      this.list.splice(addedIndex,0,element)
      this.solitaireObj.elementRelationlist = this.list
      this.mousemoveIndex = addedIndex
      // ;[this.list[removedIndex], [this.list[addedIndex]]] = [this.list[addedIndex], [this.list[removedIndex]]]
    },
    /**
     * 点击编辑  基本信息
     */
    editBasicInfoFun() {
      this.isShowEditBasicPage = true;
      this.editBasicInfoObj = deepCopy(this.solitaireObj.basicInfoObj)
    },
    /**
     * 点击文本导入
     */
    textImportFun() {
      this.showImportText = true
    },
    /**
     * 确定导入文本
     */
    // eslint-disable-next-line no-unused-vars
    confirmEntry() {
      let value = this.textarea
      if (value === '') {
        this.isShowAlert = true;
        this.errorMessage = `请输入题目`
        return;
      }
      // console.log('value ==>', value)
      let topicStrArr = value.split(/\n{2,}/)
      // console.log('topicStrArr ==>', topicStrArr)
      for (let i = 0; i < topicStrArr.length; i++) {
        if (topicStrArr[i] === '') continue;//最后有可能有多个换行，多出一个空白项

        //设置题目的内容数组
        let topicContentArr = topicStrArr[i].split('\n')

        //是否通过格式校验 通过返回 标题和分值
        let isPassVerification = this.checkFormat(topicContentArr)
        if (!isPassVerification) return;

        //题目的类型
        let type = topicContentArr[0].slice(-3, -1);

        //校验选项 和 答案的匹配
        let isByMatching = this.isMatchingOptionAnswerIsTheSame(topicContentArr, type)
        // console.log('isByMatching ==>', isByMatching)
        if (!isByMatching) return;

        this.enterTheTopic(type, isByMatching, isPassVerification)//

        console.log(`第${i + 1}道题通过校验`)


      }
      this.showImportText = false
    },

    /**
     * 校验导入文本的格式是否正确
     * @param topicContentArr 设置题目的内容数组
     * @return {boolean}
     * 1. 返回false：验证失败 ;
     * 2. 返回对象：完成验证，title:题目标题  score：得分;
     */
    checkFormat(topicContentArr) {
      // console.log('topicContentArr ==>', topicContentArr)
      let isTitleFormat = /^【题目】/.test(topicContentArr[0])
      if (!(isTitleFormat)) {
        this.isShowAlert = true;
        this.errorMessage = `${topicContentArr[0]}，格式有误，此行开头应为"【题目】"`
        return false
      }
      let isTypeFormat = /【单选】|【多选】$/.test(topicContentArr[0])
      if (!isTypeFormat) {
        this.isShowAlert = true;
        this.errorMessage = `${topicContentArr[0]}，格式有误，此行结尾应为题目类型，【单选】或【多选】`
        return false
      }

      let isScoreFormat = /^【分值】/.test(topicContentArr[1])
      if (!isScoreFormat) {
        this.isShowAlert = true;
        this.errorMessage = `${topicContentArr[1]}，格式有误，此行开头应为"【分值】"`
        return false
      }

      let isScore = /^[0-9]+$/.test(topicContentArr[1].slice(4))
      if (!isScore) {
        this.isShowAlert = true;
        this.errorMessage = `【分值】只能设置数字`
        return false
      }

      let isAnswerFormat = /^[【答案】]/.test(topicContentArr[topicContentArr.length - 1])
      if (!isAnswerFormat) {
        this.isShowAlert = true;
        this.errorMessage = `${topicContentArr[topicContentArr.length - 1]}，格式有误，此行开头应为"【答案】"`
        return false;
      }
      if (topicContentArr.length < 5) {
        this.isShowAlert = true;
        this.errorMessage = `${topicContentArr[0]}，该题选项少于2项`
        return false;
      }
      return {title: topicContentArr[0].slice(4, -4), score: topicContentArr[1].slice(4)};
    },
    /**
     * 校验导入文本 设置的答案和选项是否匹配
     * @param topicContentArr 设置题目的内容数组
     * @param type 题目的类型 单选 或者 多选
     * @return returnValue
     *1. 不通过 返回false
     *2. 通过  单选：返回一个对象 （选项数组 和 答案的索引）
     *3. 多选：返回一个数组对象（选项和答案在其对象内）
     */
    isMatchingOptionAnswerIsTheSame(topicContentArr, type) {
      let answerArr = topicContentArr[topicContentArr.length - 1].slice(4).split('|')

      // eslint-disable-next-line no-unused-vars
      let answerIsMatch = false;//答案和选项是否匹配

      let returnValue = type === '单选' ? {options: []} : [];//答案的索引数组

      let matchTheCorrectNumber = 0;//多选设置的答案跟选项能够匹配得上的个数

      for (let j = 0; j < topicContentArr.length; j++) {
        //索引为 0，1 ，最后的索引 不进行答案跟选项匹配校验（不是选项不进行校验）
        let isMatch = ![0, 1, topicContentArr.length - 1].includes(j)

        //单选 查找答案跟选项是否匹配
        if (isMatch && type === '单选') {
          if (answerArr[0].trim() === topicContentArr[j].trim()) {
            returnValue.answer = j - 2;
            answerIsMatch = true;//
          }
          returnValue.options.push(topicContentArr[j].trim())
        }
        //多选 查找答案跟选项是否匹配
        if (isMatch && type === '多选') {
          // eslint-disable-next-line no-unused-vars
          let isMatchUp = false;//选项和答案 是否匹配得上
          for (let k = 0; k < answerArr.length; k++) {
            if (topicContentArr[j].trim() === answerArr[k].trim()) {
              matchTheCorrectNumber++
              isMatchUp = true;
              returnValue.push({content: topicContentArr[j].trim(), isAnswer: true})
              break;
            }
          }
          if (!isMatchUp) {
            returnValue.push({content: topicContentArr[j].trim(), isAnswer: false})
          }
        }
      }
      // 设置多选的答案  在选项中都能匹配得到时
      if (type === '多选' && answerArr.length === matchTheCorrectNumber) answerIsMatch = true
      if (!answerIsMatch) {
        this.isShowAlert = true;//显示错误提示框
        this.errorMessage = `${topicContentArr[0]}，格式有误，该题目的答案和选项不匹配`
        returnValue = false
        return returnValue;
      }
      return returnValue;
    },
    /**
     *将处理好的数据 赋值到接龙对象上
     */
    enterTheTopic(type, optionsInfo, titleAndScore) {
      let elementConfiglist = this.solitaireConfig.elementConfiglist
      let solitaireObj = this.solitaireObj
      let elementRelationlist = solitaireObj.elementRelationlist
      let topicInitialData = type === '单选' ? elementConfiglist[0] : elementConfiglist[1]
      topicInitialData.score = titleAndScore.score
      topicInitialData.fieldTips = titleAndScore.title
      topicInitialData.id = ''
      topicInitialData.fieldValueTips = ''
      // topicInitialData.canItBeDeleted = true;
      if (type === '单选') {
        topicInitialData.answer = optionsInfo.answer
        topicInitialData.defaultValue = optionsInfo.options
        elementRelationlist.push(topicInitialData)
      } else {
        topicInitialData.defaultValue = optionsInfo
        elementRelationlist.push(topicInitialData)
      }
      this.list = deepCopy(this.solitaireObj.elementRelationlist)
      this.$store.commit('cgSolitaireObj', solitaireObj)
    },
    /**
     * 点击示例时触发
     */
    exampleFun() {
      this.textarea += this.exampleValue
    },
    /**
     * 点击清空时触发
     */
    emptyFun() {
      if (this.textarea === '') return;
      this.$confirm('是否清空输入框的值', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.textarea = ''
      }).catch(() => {

      });
    },
    /**
     * 关闭 导入文本编辑页
     */
    handleClose() {
      this.showImportText = false
    },
    /**
     * 删除题目
     */
    deleteTopicFun(index) {
      console.log(index)

      this.$confirm('是否确定删除该题目', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.solitaireObj.elementRelationlist.splice(index, 1)
        this.$store.commit('cgSolitaireObj', this.solitaireObj)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        console.log('取消删除')
      });
    },
    /**
     *  编辑题目
     */
    editTopicFun(index) {
      this.editTopicObj = deepCopy(this.solitaireObj.elementRelationlist[index])
      let type = this.editTopicObj.text
      console.log('this.editTopicObj ==>', this.editTopicObj)
      this.editTopicIndex = index;
      this.isShowEditTitlePage = true;//显示编辑题目框
      this.editTopicType = type
      if (type === '单选') {
        this.radioAnswer = Number(this.editTopicObj.answer)
      } else if (type === '多选') {
        let defaultValue = deepCopy(this.editTopicObj.defaultValue)
        this.checkAnswerList = defaultValue.filter(item => item.isAnswer).map(element => element.content)
        console.log(' this.checkAnswerList ==>', this.checkAnswerList)
      }
    },
    /**
     * 添加题目
     */
    addTopicFun(data) {
      console.log('data ==>', data)
      if (this.legendTitle === '考试问题' && data?.bigType === '问题类型') {
        let _elementConfiglist = this.solitaireConfig.elementConfiglist
        let _smallType = data.smallType
        this.editTopicObj = _smallType === '单选' ? deepCopy(_elementConfiglist[0]) : deepCopy(_elementConfiglist[1])
        _smallType === '单选' ?
          this.editTopicObj.defaultValue = ['', ''] :
          this.editTopicObj.defaultValue = [{content: '', isAnswer: false}, {content: '', isAnswer: false}]

        this.editTopicObj.fieldTips = ''
        this.isShowEditTitlePage = true;//显示编辑题目框
        this.editTopicType = this.editTopicObj.text;
        console.log('this.editTopicObj ==>', this.editTopicObj)
        console.log('editTopicObj.defaultValue[0].content ==>', this.editTopicObj.defaultValue)
      }
    },
    /**
     * 添加 信息项
     */
    addInformationItem(data) {
      if (data.smallType === '文本') {
        let obj = {typeText: '文本', value: ''}
        // this.list.push(obj)
        this.solitaireObj.basicInfoObj.basicInfoList.push(obj)
      } else if (data.smallType === '手写签名') {
        let signatureObj = deepCopy(this.solitaireConfig.elementConfiglist.find(item => item.text === '签名'))
        signatureObj.typeText = '手写签名'
        signatureObj.value = ''
        // this.list.push(signatureObj)
        this.solitaireObj.basicInfoObj.basicInfoList.push(signatureObj)
      }
      this.$message({
        message: '添加成功',
        type: 'success'
      })
    },
    /**
     * 点击 多选框
     */
    clickCheckbox(index) {
      let isAnswer = !this.editTopicObj.defaultValue[index].isAnswer
      console.log('this.editTopicObj.defaultValue ==>', isAnswer)
      this.$set(this.editTopicObj.defaultValue[index], 'isAnswer', isAnswer)
    },
    /**
     *删除题目选项
     */
    removeOptionFun(index) {
      if (this.editTopicObj.defaultValue[index] === '') {//没有内容的直接删除
        index < this.radioAnswer ? this.radioAnswer-- : index === this.radioAnswer ? this.radioAnswer = -1 : ''
        this.editTopicObj.defaultValue.splice(index, 1)
        return;
      }
      this.$confirm('此操作为删除该选项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        index < this.radioAnswer ? this.radioAnswer-- : index === this.radioAnswer ? this.radioAnswer = -1 : ''
        this.editTopicObj.defaultValue.splice(index, 1)
        this.$message({
          type: 'success',
          message: '删除选项成功!'
        });
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },
    /**
     * 添加选项
     */
    addOptionFun() {
      let editTopicObj = this.editTopicObj
      let defaultValue = editTopicObj.defaultValue
      if (editTopicObj.text === '单选') {
        defaultValue.push('')
      } else if (editTopicObj.text === '多选') {
        defaultValue.push({content: '', isAnswer: false})
      }
      this.editTopicObj.defaultValue = defaultValue;
    },
    /**
     * 添加信息项
     */
    addInformationItemFun() {
      this.editBasicInfoObj.basicInfoList.push({typeText: '', value: ''})
    },
    /**
     * 删除基本信息项
     */
    removeInformationItemFun(index) {
      this.$confirm('此操作为删除该信息项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.editBasicInfoObj.basicInfoList.splice(index, 1)
        this.$message({
          type: 'success',
          message: '删除信息项成功!'
        });
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },
    /**
     * 添加签名
     */
    addSignatureFun() {
      let signatureObj = deepCopy(this.solitaireConfig.elementConfiglist.find(item => item.text === '签名'))
      signatureObj.typeText = ''
      signatureObj.value = ''
      console.log('signatureObj ==>', signatureObj)
      this.editBasicInfoObj.basicInfoList.push(signatureObj)

    },
    /**
     * 关闭 编辑题目 页面
     */
    closeEditTopicPage() {
      this.isShowEditTitlePage = false
    },
    /**
     * 关闭 编辑题目 页面
     */
    closeEditBasicPage() {
      this.isShowEditBasicPage = false
    },
    /**
     *保存编辑的题目信息
     */
    saveQuestionInfoFun() {
      let editTopicObj = this.editTopicObj
      if (editTopicObj.fieldTips.trim() === '') {
        this.isShowAlert = true;
        this.errorMessage = '标题不能为空'
        return
      }
      let inputOption = editTopicObj.defaultValue
      if (inputOption.length < 2) {
        this.isShowAlert = true;
        this.errorMessage = '不能少于2个选项'
        return
      }

      if (editTopicObj.text === '单选') {
        if (inputOption.findIndex(target => target === '') !== -1) {
          this.isShowAlert = true;
          this.errorMessage = '选项内容不能为空'
          return
        }
        if (this.radioAnswer === -1) {//没有填写答案
          this.isShowAlert = true;
          this.errorMessage = '请勾选该题的答案'
          return
        } else {
          editTopicObj.answer = this.radioAnswer
        }
      } else if (editTopicObj.text === '多选') {
        if (editTopicObj.defaultValue.some(item => item.content === '')) {//有空内容的选项
          this.isShowAlert = true;
          this.errorMessage = '选项内容不能为空'
          return
        }
        if (editTopicObj.defaultValue.every(item => item.isAnswer === false)) {//没有填写答案
          this.isShowAlert = true;
          this.errorMessage = '请勾选该题的答案'
          return
        }
      }
      if (!editTopicObj.score && editTopicObj.score !== 0) {//题目分数没填
        this.isShowAlert = true;
        this.errorMessage = '请设置该题目的分数'
        return
      }//选项是否重复(重复返回重复的字符串，不重复返回 true)
      let isItRedundant = filterDuplicates(inputOption)
      if (isItRedundant) {//如果选项有重复选项则不再进行
        this.isShowAlert = true;
        this.errorMessage = isItRedundant
        return
      }

      if (this.editTopicIndex === -1) {//添加题目
        this.solitaireObj.elementRelationlist.push(this.editTopicObj)
      } else {//修改题目
        this.solitaireObj.elementRelationlist[this.editTopicIndex] = this.editTopicObj
        this.editTopicIndex = -1 //初始化  点击编辑题目 的索引
      }
      this.list = this.solitaireObj.elementRelationlist
      this.$store.commit('cgSolitaireObj', this.solitaireObj)
      this.isShowEditTitlePage = false //隐藏 编辑题目的组件
      this.radioAnswer = -1 //初始化 单选的答案
      this.$message({
        type: 'success',
        message: '保存成功!'
      });
    },
    /**
     * 保存编辑 的基本信息
     */
    saveBasicInfoFun() {
      let editBasicInfoObj = this.editBasicInfoObj
      if (editBasicInfoObj.title === '') {
        this.isShowAlert = true;
        this.errorMessage = '标题不能为空'
        return
      }
      //判断信息项列表值是否有为空的
      let isEmpty = editBasicInfoObj.basicInfoList.some(item => item.typeText.trim() === '')
      if (isEmpty) {
        this.isShowAlert = true;
        this.errorMessage = '信息项不能为空'
        return
      }
      this.solitaireObj.basicInfoObj = editBasicInfoObj
      this.list = editBasicInfoObj.basicInfoList
      this.$set(this.solitaireObj, 'basicInfoObj', editBasicInfoObj)
      this.$store.commit('cgSolitaireObj', this.solitaireObj)
      this.isShowEditBasicPage = false;
      this.$message({
        type: 'success',
        message: '保存成功!'
      });
    },
    /**
     * 鼠标移入题目时触发
     */
    mousemoveFun(index) {
      if (this.mousemoveIndex === index) return;
      if (this.legendTitle !== '考试问题') return;
      this.mousemoveIndex = index;
    },
  },

  computed: {
    // ...mapState(['addTopicCommand']),
    /**
     * 计算总分
     */
    totalScore() {
      return this.list.reduce((pre, next) => pre + Number(next.score), 0)
    }
  },
  watch: {
    ...
      listenSolitaireChangeObj
    // solitaireObj: {
    //   handler(solitaireObj) {
    //     console.log('123')
    //     this.$store.commit('cgSolitaireObj', solitaireObj)
    //   },
    //   deep: true
    // }
  }
}
</script>

<style lang="scss" scoped>

.topicContent-box {
  background-color: #ebedf0;
  padding: 20px;
  text-align: left;

  .legendTitle {
    margin: 20px 0;
    display: flex;

    .legendTitle_text {
      font-size: 18px;
      font-weight: bold;
    }

    .text_import {
      //color: #0202ea;
      cursor: pointer;
      margin-left: auto;
    }
  }

  .topicContent {

    .hover {
      //cursor: pointer;
      box-shadow: 0 0 15px #707070;
    }

    .topicContent-item {
      padding: 15px 20px;
      position: relative;


      .topicTitle {
        display: flex;

        .info {
          flex: 1;
          display: flex;

          .score {
            color: #18c631;
          }

        }


      }

      .box {
        width: 100%;
        box-sizing: border-box;
        min-height: 30px;
        line-height: 16px;
        padding: 10px;
        margin-top: 10px;
        font-size: 14px;
        border: 1px solid silver;
        //margin-top: 14px;
        border-radius: 4px;
        color: #949393;
        text-align: center;
      }

      .options-box {
        .options {
          .option {
            margin-top: 10px;

            .answer {
              color: #18c631;
              margin-left: 10px;
            }
          }
        }
      }

      .icons {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        //margin-left: auto;

        .icons-item {
          //margin-left: 10px;
          cursor: pointer;
          width: 16px;
        }

        .move {
          cursor: move;
        }
      }
      .editTitle {
        //width: 300px;
        //height: 300px;
        //background-color: lightblue !important;
        //z-index: 20;
        //position: absolute;
        //left: 200px;
        //top: 20px;
      }
    }
  }

  .textImport_edit_box {
    .notes_box {
      .notes-item {
        margin-bottom: 10px;

        .example {
          cursor: pointer;
          margin-left: 40px;
          color: #2e6eff;
        }

        .doNotEmpty {
          color: rgba(46, 108, 248, .5);
        }
      }
    }
  }
}

.edit_title_page {
  .edit_title {
    display: flex;
    align-items: center;

    .title_text {
      margin-right: 20px;
    }

    .title_input {
      flex: 1;
    }
  }

  .options_answer_box {
    width: 100%;
    margin-top: 20px;

    .legend_text {
      .option_text {
        margin-left: 10px;
      }
    }

    .radio_item {
      width: 100%;
      margin-top: 15px;
      padding-left: 6px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
  }

  .options_answer_list {
    width: 100%;
    max-height: 300px;
    overflow: auto;

    &::-webkit-scrollbar { //设置滚动条的宽度
      width: 2px;
    }

    .radio-group {
      width: 100%;


    }

    .remove_option {
      cursor: pointer;
      color: #0050a5;
      margin-left: 10px;
    }

    .disabled_remove_option {
      cursor: no-drop;
      margin-left: 10px;
    }
  }

  .add_option {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      padding: 10px;
      margin: 0 10px;
      border-radius: 6px;
      background-color: #0051a6;
      color: white;
      cursor: pointer;
      text-align: center;

    }

  }
}

.center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-right: 20px;
}

.tips {
  min-width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10009;
  background-color: #d3d3d3;
}
</style>
<style>
.el-radio__label, .el-checkbox__label {
  color: #212121 !important;
}

.el-dialog {
  background-color: #f4f5f7;
}

.el-radio {
  /*width: 100%;*/
  margin-right: 0 !important;
}
</style>

<!--修改选项-->
<style lang="scss">


.el-radio, .el-checkbox {
  display: flex !important;
  white-space: pre-wrap !important;
}

.el-radio__input {
  display: block;
}
</style>
