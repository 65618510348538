<template>
  <div class="header">
    <el-row justify="center" type="flex">
      <el-col :lg="18" :md="24" :sm="24" :xl="16" :xs="24" class="navHead">
        <div class="left">
          <el-image
            class="logo"
            @click="showHome"
            style="width: 150px"
            :src="url"
            fit="contain"></el-image>

          <!--<el-image alt="首页图标" fit="contain" src="" ></el-image>-->
        </div>
        <div class="right">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1" @click="showHome">首页</el-menu-item>
            <el-menu-item index="2" @click="showTemplate">模板中心</el-menu-item>
            <!-- <el-submenu index="3">
               <template slot="title">小程序</template>
               <div><img alt="首页图标" src="@/assets/img/appletCode.jpg"></div>
             </el-submenu>-->
            <el-menu-item index="" v-if="!areYouLoggedIn" @click="scanCodeToLogIn1">
              <template slot="title">扫码登录</template>
            </el-menu-item>
            <!-- <el-menu-item @click="codeScanningLogin" class="noneFocus">扫码登录</el-menu-item> -->
            <el-menu-item v-else class="noneFocus" @click.self="manageMySolitaire">管理我的接龙</el-menu-item>
            <!-- <el-menu-item class="noneFocus"><a href="http://localhost:8080/publish" target="_black">管理我的接龙</a></el-menu-item> -->
          </el-menu>
        </div>
      </el-col>
    </el-row>
    <div class="carrier" v-if="isShowSunCode" @click.self="cancelLogin">
      <div class="sunCode-box">
        <div class="appletCode-box">
          <img class="appletCode" alt="首页图标" v-if="appletCode" :src="appletCode">
          <!--<el-image
            style="width: 100px; height: 100px"
            :src="appletCode"
            fit="fit"></el-image>-->
        </div>
        <div class="loginStatusText">{{ loginStatusText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {PARTENCODE} from '@/utils/constant';
import {JSONparse, getLocalStorage, setLocalStorage} from '@/assets/js/tools/common';
import {jlLogoNoPinYinG} from '@/assets/js/staticData/imageRequest';
import {checkLogin} from '@/utils/logIn'

export default {
  name: 'Header',
  data() {
    return {
      activeIndex: '1',
      isShowSunCode: false,//是否显示登录太阳码
      loginStatusText: '加载中...',//登录状态说明
      areYouLoggedIn: false,//是否已登录
      ws: '',//webSocket协议
      // appletCode: require('@/assets/img/appletCode.jpg'),
      appletCode: '',
      url:jlLogoNoPinYinG,
      // appletCode:''
    }
  },
  created() {
    this.init()
    // 更正显示路由跟选中标签不匹配
    this.cgActiveIndex()
  },
  methods: {
    /**
     * 初始化函数
     */
    init() {
      let userInfo = getLocalStorage('userInfo')
      console.log('userInfo ==>', userInfo)
      if (userInfo) {
        this.areYouLoggedIn = true
        this.userInfo = userInfo
      }
    },
    /**
     * 点击扫码登录
     */
    scanCodeToLogIn1() {
      checkLogin.call(this) // 检查登录
    },
    /**
     * 取消登录
     */
    cancelLogin() {
      // this.appletCode = ''
      this.isShowSunCode = false
      this.ws.close()//关闭websocket协议连接
    },
    /**
     * 点击 管理我的接龙
     */
    manageMySolitaire() {
      this.$router.push({name: 'Solitaire'})
    },
    /**
     * 更正显示路由跟选中标签不匹配
     */
    cgActiveIndex() {
      let name = this.$route.name
      // console.log('跳转的路由',name);
      if (name !== 'Home') {
        this.activeIndex = '2'
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
      if (this.activeIndex == key) return;
      this.activeIndex = key
    },
    showHome() {
      this.$router.push({
        name: 'FirstHome'
      })
      this.activeIndex = '1'
    },
    showTemplate() {
      this.$router.push({name: 'Template'})
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/components/header';
</style>
