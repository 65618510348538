import {MessageBox} from 'element-ui'

export const whySelectJlxsqData = {
  bigTitle: '为什么选择接龙小神器',
  list: [
    {
      title: '各行各业都在用',
      text1: '无论是职场白领、HR、还是高校的老师、',
      text2: '学生，他们都在使用接龙小神器',
    },
    {
      title: '简单三步，即可获得数据',
      text1: '不限答卷收集数量、数据图表化展示、权',
      text2: '威级数据安全保障、永久保存并支持免费',
      text3: '下载',
    },
    {
      title: '多端支持，所有功能均免费',
      text1: 'PC电脑、手机APP、小程序无缝发布，让',
      text2: '你可以随时随地创建、编辑、浏览项目',
    },
  ],
}


/*let obj1 = {
  "bigTitle": "为什么选择接龙小神器",
  "list": "[
    {
      "title": "各行各业都在用",
      "text1": "无论是职场白领、HR、还是高校的老师、",
      "text2": "学生，他们都在使用接龙小神器",
    },
    {
      "title": "简单三步，即可获得数据",
      "text1": "不限答卷收集数量、数据图表化展示、权",
      "text2": "威级数据安全保障、永久保存并支持免费",
      "text3": "下载",
    },
    {
      "title": "多端支持，所有功能均免费",
      "text1": "PC电脑、手机APP、小程序无缝发布，让",
      "text2": "你可以随时随地创建、编辑、浏览项目",
    },
  ]"
}*/

// export const qrCodeList = [
//   {title: '微信小程序',text:'微信扫码', icon:require('@/assets/img/Home/suspended/sy_icon_wxxcx.png'), url: require('@/assets/img/Home/foot/xcx222.jpg')},
//   {title: 'QQ小程序',text:'QQ扫码', icon:require('@/assets/img/Home/suspended/sy_icon_qqxcx.png'),url: require('@/assets/img/Home/foot/qqxcx222.jpg')},
//   {title: '微信客服',text:'微信扫码，加我微信',icon:require('@/assets/img/Home/suspended/sy_icon_wxkf.png'), url: require('@/assets/img/Home/foot/kf222.jpg')},
//   {title: '官方公众号', url: require('@/assets/img/Home/foot/jielongxiaoshenqi-fuwuhao.jpg')},
// ]

export const qunjielongList = [
  {title: '北京微信群接龙'},
  {title: '上海微信群接龙'},
  {title: '广州微信群接龙'},
  {title: '深圳微信群接龙'},
  {title: '南京微信群接龙'},
  {title: '杭州微信群接龙'},
  {title: '天津微信群接龙'},
  {title: '青岛微信群接龙'},
  {title: '成都微信群接龙'},
  // {title:'重庆微信群接龙'},
  // {title:'武汉微信群接龙'},
  // {title:'厦门微信群接龙'},
  // {title:'郑州微信群接龙'},
  {title: '更多城市微信群接龙'},
]

export const qunjielongListAPP = [
  {title: '北京微信群接龙'},
  {title: '上海微信群接龙'},
  {title: '广州微信群接龙'},
  {title: '深圳微信群接龙'},
  {title: '南京微信群接龙'},
  // {title:'杭州微信群接龙'},
  // {title:'天津微信群接龙'},
  // {title:'青岛微信群接龙'},
  // {title:'成都微信群接龙'},
  // {title:'重庆微信群接龙'},
  // {title:'武汉微信群接龙'},
  // {title:'厦门微信群接龙'},
  // {title:'郑州微信群接龙'},
  {title: '更多城市微信群接龙'},
]
/**
 * 域名信息列表
 */
const filingNoInformationList = [
  {
    domainName: 'dancimao.com',//访问的域名
    text: 'Copyright © 网威信息 (2019-2022). All Rights Reserved.',//备案说明文本
    filingNo: '粤ICP备10232178号'//备案号
  },
  {
    domainName: 'xiaoshenqi.cn',//访问的域名
    text: 'Copyright 2020-2022 广州接龙神器科技有限公司 保留公司所有权利',//备案说明文本
    filingNo: '粤ICP备20001809号'//备案号
  },
  {
    domainName: 'jielongshenqi.com',//访问的域名
    text: 'Copyright © 接龙神器 (2019-2022). All Rights Reserved.',//备案说明文本
    filingNo: '粤ICP备20001809号'//备案号
  },
]
/**
 * 获取当前域名信息
 */
export let getCurrentDomainNameInfo = function (parameter) {
  let href = window.location.href
  if (process.env.NODE_ENV === 'development') {//开发环境
    href = 'http://www.dancimao.com/'
  }
  let reg = /www.(.*?)\//
  let currentDomainName = href.match(reg)[1]//当前访问的域名
  let currentDomainNameInfoObj = filingNoInformationList.filter(item => item.domainName === currentDomainName)[0]
  if (currentDomainNameInfoObj) {//有对应的域名
    switch (parameter) {
      case 'domainName':
        return currentDomainNameInfoObj.domainName
      default:
        return currentDomainNameInfoObj
    }
  }
  let message = `当前访问的地址域名配置有误或者没配置，请联系前端开发人员进行配置`;
  MessageBox.alert(message, '错误提示', {
    confirmButtonText: '确定'
  });
  return {}
}

/**
 * 获取当前域名
 */
export function getDomainNameFun() {
  let href = process.env.NODE_ENV === 'development' ? 'http://www.dancimao.com/' : window.location.href
  let reg = /www.(.*?)\//
  return href.match(reg)[1]//当前访问的域名
}
