import {request} from '@/utils/request';
import {api} from '@/utils/constant';

/**
 * 获取我的接龙列表数据
 */
export function getMySolitaireListAPI(params){
  let type = params.type
  return new Promise((resolve) => {
    request({
      // url: '/solitaireMgr/solitaireAPI!mySolitaire.do',
      url: `/solitaireMgr/solitaireAPI!mySolitaire.do`,
      params,
    }).then((res) => {
      // console.log('获取我的接龙数据 res ==>', res.data.checkResult.resultObject[type])
      if (res.data?.checkResult?.allowed) {
        let solitaireList = res.data.checkResult.resultObject[type]
        return resolve( solitaireList )
      } else {
        console.log('获取我的接龙列表数据失败')
        resolve(false)
      }
    })
  })
}
