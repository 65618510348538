/**
 * 字符串合法性检测(支持[,，;；\n]分割姓名)
 * @param str 要检测的字符串
 * @returns result 通过则返回一个数组对象: [{id: number, name: ''}], 否则返回 false
 */

// var str = 'df&&&000000,王伟&00000000，赵燕&2001,王小明&0000002'
// var str = '123,大概,fagg,ERT更改'
// legalityTest(str)
export function legalityTest(str) {
  // console.log('@@@@@@@', str)
  if (str === '') {
    this.$message.error('请设置名单')
    return false
  }
  // step0: 清除冗余的干扰字符
  str = str.replace(/[^\S\r\n]+|[\f\r\t\v]/ig, '') // 正则匹配所有空格不包括换行
  str = str.replace(/，/ig, ',') // 把中文逗号改成英文逗号
  str = str.replace(/；/ig, ';') // 把中文分号改成分号逗号
  str = str.replace(/[＆＃#]/ig, '&') // 把全角＆替换为半角&符
  str = str.replace(/(&{2,})/ig, '&') // 把连续出现2次及以上的 && 变成 &
  str = str.replace(/(,{2,})/ig, ',')
  str = str.replace(/(;{2,})/ig, ';')
  str = str.replace(/(\n{2,})/ig, '\n') // 把多个换行符替换为一个
  str = str.replace(/,\n|;\n/ig, '\n') // 把逗号(分号)后面紧跟的换行符替换为换行符
  str = str.replace(/[\n,;&]+$/ig, '') // 去除末尾的多余字符
  // console.log('用户输入的姓名格式为:', str)

  // 开始处理字符串
  if (/&/.test(str)) {
    // let regexp = /([\u4E00-\u9FA5`~!@#$%^&*()_\-+=<>?:"{}|.\/;'\\[]·]+)&\d+([\u4e00-\u9fa5A-Za-z`~!@#$%^&*()_\-+=<>?:"{}|.\/;'\\[]·]+)/ig
    // console.log(str.match(regexp)) // 匹配 [汉字+字母+数字 & 数字] 格式, 也就是 姓名可以由汉字+字母+数字组成, 编号只能由阿拉伯数字组成, 且最长不能超过6位数
    // str = str.replace(/\b(0+)(?=\d)/ig, "") // 把编号前面的 0 去掉

    // step1: 使用(,)分割字符串
    let arr = str.split(/[,;\n]/)

    // let arr = ["李阳&1", "李斌&2", "李无&3", "礼包&1", "东方&2", "细分&3","李阳&1", '李斌&2'];
    // console.log(isRepeat(arr));
    // 验证重复元素，有重复返回true；否则返回false
    let res = isRepeat(arr)
    // console.log('验证重复元素:', arr, res)
    if (!res) {return res}


    // step2: 检测字符串
    let result = [] // 结果接收容器
    let resultObj = [] // 结果接收对象
    for (let i = 0; i < arr.length; i++) {
      // step3: 找出以 & 开头 或 非数字 结尾的字符串
      if (/^&+/ig.test(arr[i]) || !/\d+$/ig.test(arr[i])) {
        console.log('格式有误字符串:', arr[i])
        this.$message.error(`${arr[i]} 格式有误`)
        return result = false // 循环意外中断则返回 false
      }
      // console.log(arr[i], '已通过 [姓名&数字] 检测')

      // step4: 使用(&)分割字符串
      result.push(arr[i].split(/&/))
      // console.log('已生成 姓名&编号 格式数组:', result)
      // 判断 result[i] < 2 为格式有误
      if (result[i].length < 2) {
        console.log('格式有误的字符串为:', arr[i])
        this.$message.error(`${arr[i]} 格式有误`)
        return result = false
      }
      result[i][1] = result[i][1].replace(/^0+(?=\d)/ig, '') // 把编号前面的 0 去掉, 全 零 则保留一个 零
      // step5: 找出使用(&)分割后数组长度大于 2 的数组
      if (result[i].length > 2) {
        console.log('格式有误的字符串为:', arr[i])
        this.$message.error(`${arr[i]} 格式有误`)
        return result = false
        // step6: 找出编号大于 5 位的数组
      } else if (result[i][1].length > 5) {
        console.log('编号大于 5 位数的字符串为:', arr[i], '编号不能大于 5 位数')
        this.$message.error(`${arr[i]} 编号不能大于 5 位数`)
        return result = false
      }
      resultObj.push({id: result[i][1], name: result[i][0], done: false})
      // console.log(resultObj)
    }
    // console.log('经检验, 用户输入的 [姓名&编号] 格式合法, 开始序列化编号')
    // console.log('已初始化接龙完成情况对象',resultObj)
    return resultObj

    // 判断姓名, 其长度不能大于20个字符
  } else {
    let arr = str.split(/[,;\n]/)
    // 验证重复元素，有重复返回true；否则返回false
    let res = isRepeat(arr)
    // console.log('验证重复元素:', arr, res)
    if (!res) {return res}
    let resultObj = []
    for (let i = 0; i < arr.length; i++) {
      // console.log(arr)
      if (arr[i].length > 20) {
        console.log(arr[i], '姓名长度已大于20个字符')
        this.$message.error(`${arr[i]} 姓名长度不能大于20个字符`)
        return resultObj = false
      }
      resultObj.push({name: arr[i], done: false})
      // console.log(resultObj)
    }
    // console.log('经检验, 用户输入的 [姓名] 格式合法, 开始序列化编号')
    // console.log('已初始化接龙完成情况对象',resultObj)
    return resultObj
  }
}

/**
 * 验证重复元素，有重复返回true；否则返回false
 * @param arr
 * @returns {boolean}
 */
function isRepeat(arr) {
  // console.log('@@@@@@@@@@',arr)
  let hash = {};
  for(let i in arr) {
    if(hash[arr[i]]) {
      this.$message.error(`${arr[i]} 重复`)
      return false;
    }
    // 不存在该元素，则赋值为true，可以赋任意值，相应的修改if判断条件即可
    hash[arr[i]] = true;
  }
  return true;
}

/**
 * 编号合法性检测
 * @param strName 编号名称
 * @param str 编号范围
 * @returns result 通过则返回一个数组对象: [{id: number, name: ''}], 否则返回 false
 */
// var str = '1-400,-300-500'
// numberDetection('编号', str)
export function numberDetection(strName, str) {
  if (strName === '') {
    this.$message.error('编号名称不能为空')
    return false
  } else if (str === '') {
    this.$message.error('编号范围不能为空')
    return false
  }
  // step0: 清除冗余的干扰字符
  str = str.replace(/\s/g, '') // 清除全部空格
  str = str.replace(/，/g, ',') // 把中文逗号改成英文逗号
  str = str.replace(/－/g, '-')
  str = str.replace(/(,{2,})/g, ',') // 把连续出现2次及以上的 ,, 变成 ,
  str = str.replace(/(-{2,})/g, '-')
  // console.log('用户输入的编号格式为:', str)
  // str = '1-26,-8-10,31,3^2,38%'
  // console.log(/[^0-9-,，]/g.test(str)) // true --> 有格式有误字符
  // console.log(str.match(/[^0-9-,，]/g)) // 打印格式有误字符 --> ["^" "%"]
  // 开始处理字符串
  if (/[,-]/.test(str)) {
    // step1: 使用(,)分割字符串
    let arr = str.split(',')
    // console.log('使用","切割成数组:', arr)
    // step2: 检测字符串
    let result = [] // 结果接收容器
    let resultObj = [] // 结果接收对象
    let addTo = [] // 添加元素容器
    let exclude = [] // 排除元素容器
    for (let i = 0; i < arr.length; i++) {
      // step3: 找出以 数字和"-" 开头 或 非数字 结尾的字符串
      if (!/^\d+-\d+$|^-\d+-\d+$|^-\d+$|^\d+$/.test(arr[i])) {
        console.log('格式有误编号:', arr[i])
        this.$message.error(`${arr[i]} 格式有误`)
        return result = false // 循环意外中断则返回空数组
      }
      // console.log(arr[i], '编号已通过检测')
      // 编号例子: -7-14(表示排除 7 至 14 的编号)
      // 处理负数开头的编号(把开头的负号"-", 改成下划线"_", 然后根据连接符"-"分割字符串)
      if (/^-/.test(arr[i])) {
        arr[i] = arr[i].replace(/^-/, '_')
        // console.log('已处理负数开头的编号:', arr[i])
      }
      // step4: 使用(-)分割字符串
      result.push(arr[i].split(/-/))
      // console.log('使用"-"把编号区间切割成数组:', result)
      // step5: 找出使用(&)分割后数组长度大于 2 的数组
      if (result[i].length > 2) {
        console.log('编号格式有误:', arr[i])
        this.$message.error(`${arr[i]} 格式有误`)
        return result = false
        // step6: 找出编号大于 5 位的数组
      } else if (result[i][0].length > 5 || (result[i][1] !== undefined && result[i][1].length > 5)) {
        console.log('编号大于 5 位数的字符串为:', arr[i])
        this.$message.error(`${arr[i]} 编号不能大于 5 位数`)
        return result = false
      }
      // 把编号前面的 0 去掉, 全 零 则保留一个 零
      result[i][0] = result[i][0].replace(/^0+(?=\d)/ig, '')
      if (result[i][1] !== undefined) result[i][1] = result[i][1].replace(/^0+(?=\d)/ig, '')

      // 判断是排除还是添加编号(元素[0]是"_"开头代表是排除编号)
      if (/^_/.test(result[i][0])) {
        result[i][0] = result[i][0].replace(/^_/, '') // 已经使用两个数组 addTo 和 exclude 把添加或排除编号区分开了, 则删除"_"负号, 方便后续判断排除范围是否合法
        if ( result[i][0] - result[i][1] > 0) {
          this.$message.error(`${result[i][0]}-${result[i][1]} 编号范围错误`)
          return exclude = false
        }
        exclude.push(result[i])
      } else {
        if ( result[i][0] - result[i][1] > 0) {
          this.$message.error(`${result[i][0]}-${result[i][1]} 编号范围错误`)
          return exclude = false
        }
        addTo.push(result[i])
      }
    }
    // console.log('已把用户输入的字符串, 转变成可使用的数组:', result)
    // console.log('需要添加的编号:', addTo)
    // console.log('需要排除的编号:', exclude)

    // 经检验, 用户输入的编号格式合法, 开始序列化编号
    // console.log('经检验, 用户输入的 [编号] 格式合法, 开始序列化编号')

    // 已添加编号的字符串, resultObj.push({id}) 的同时也添加一个在这里, 用于匹配新添加的编号是否已存在
    let numberString = []

    // 生成编号对象
    for (let i = 0; i < addTo.length; i++) {
      // 编号是一个范围
      if (addTo[i].length > 1) {
        let length = addTo[i][1] - addTo[i][0]
        let id = Number(addTo[i][0]) // 开始的编号
        for (let j = 0; j <= length; j++) {
          // 判断 id 是否已存在 numberString 中
          // let reg = new RegExp(`${id}`)
          // if (reg.test(numberString)) {
          if (numberString.some(item => item === id)) {
            this.$message.error(`编号区间 ${addTo[i][0]}-${addTo[i][1]} 有重复编号`)
            return resultObj = false
          }
          // 经检验, 编号可用, 追加到数组对象
          resultObj.push({id: id, name: '', done: false})
          // 记录已追加到数组对象的 id
          numberString.push(id)
          id++
        }

        // 编号一个明确的数字
      } else {
        // 判断 id 是否已存在 numberString 中
        // let reg = new RegExp(`${addTo[i][0]}`)
        // if (reg.test(numberString)) {
        if (numberString.some(item => item === addTo[i][0])) {
          this.$message.error(`编号 ${addTo[i][0]} 重复`)
          return resultObj = false
        }
        resultObj.push({id: Number(addTo[i][0]), name: '', done: false})
        numberString.push(addTo[i][0])
      }
    }

    // 减去编号
    for (let i = 0; i < exclude.length; i++) {
      // 编号是一个范围
      if (exclude[i].length > 1) {
        let length = exclude[i][1] - exclude[i][0]
        let id = Number(exclude[i][0])
        for (let j = 0; j <= length; j++) {
          // 找到相应的 id, 并将其移除
          if (resultObj.findIndex(item => item.id === id) === -1) {
            // 找不到相应的 id, 说明编号区间有误
            this.$message.error(`编号区间 ${exclude[i][0]}-${exclude[i][1]} 有误`)
            return resultObj = false
          } else if (resultObj.findIndex(item => item.id === id)) {
            // 找到对应 id 将其所属对象移除
            resultObj.splice(resultObj.findIndex(item => item.id === id), 1)
          }
          id++
        }

        // 编号一个明确的数字
      } else {
        // console.log(Number(exclude[i][0]), exclude[i][0])
        // console.log(resultObj.findIndex(item => item.id === Number(exclude[i][0])))
        if (resultObj.findIndex(item => item.id === Number(exclude[i][0])) === -1) {
          this.$message.error(`编号 -${exclude[i][0]} 有误`)
          return resultObj = false
        } else if (resultObj.findIndex(item => item.id === Number(exclude[i][0]))) {
          resultObj.splice(resultObj.findIndex(item => item.id === Number(exclude[i][0])), 1)
        }
      }
    }

    // console.log('已初始化接龙完成情况对象',resultObj)
    return resultObj

    // 判断编号, 其长度不能大于5个字符
  } else {
    // console.log('编号有格式有误字符', /[^0-9-,，]/g.test(str)) // true --> 有格式有误字符
    if (/[^0-9-,，]/g.test(str)) {
      console.log(str.match(/[^0-9-,，]/g), '字符格式有误') // 打印格式有误字符 --> ["^" "%"]
      this.$message.error(`${str} 编号格式有误`)
      return false
    } else if (str.length > 5) {
      this.$message.error(`${str} 编号不能大于 5 位数`)
      return false
    }
    // console.log('经检验, 用户输入的 [编号] 格式合法, 开始序列化编号')
    // console.log('已初始化接龙完成情况对象', [{id: str, name: '', done: false}])
    return [{id: str, name: '', done: false}]
  }
}
