import {OSSHOST} from '@/utils/constant';

let path = `${OSSHOST}/data/jl/img1/`;//自定义上传的路径

//背景色为绿色，字体白色的接龙图标
export const jielongSmallLogo = `${path}jielong-small-logo-bgc-g.png`

//白色没拼音的接龙小神器logo
export const jlLogoNoPinYin = `${path}jl-logo-noPinYin.png`

//白色没拼音的接龙小神器logo
export const jlLogoNoPinYinG = `${path}jl-logo-noPinYin-g.png`

//微信客服二维码
export const kefuUrl = `${path}weixin-kefu-qrCode.jpg`

//版本对比 的四张图片
export const versionComparisonImageList = [
  `${path}2022-9-5-bbdb-mfb.png`,
  `${path}2022-9-5-bbdb-bzb.png`,
  `${path}2022-9-5-bbdb-gjb.png`,
  `${path}2022-9-5-bbdb-dzb.png`,
]


export const test = `https://oss.jielongshenqi.com/data/solitaire/template/1_50285406.png`


let list = [
  {
    type: '开学接龙',
    templateList: []//模板列表
  },
  {
    type: '防疫收集',
    templateList: []//模板列表
  },
  {
    type: '家校沟通',
    templateList: []//模板列表
  },
]
