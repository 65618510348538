<template>
  <div class="template">
    <div class="navigationBar-box box-center" :class="{bgc:!isReachTheTop}">
      <div class="navigationBar-content ">
        <div class="navigationBar">
          <div class="logo" >
            <a href="">
              <el-image
                style="width: 160px;"
                :src="url"
                fit="contain"></el-image>
            </a>
          </div>

          <div class="content">
            <div class="content-item"
                 v-for="(item,index) in navigationFunctionList"
                 :key="index"
                 :class="{cgColor:!isReachTheTop}"
                 @click="jumpType(index)"
                 @mouseover="navigationListEvent(index,'over')"
                 @mouseleave="navigationListEvent(index,'leave')"
            >
              <span>{{ item.title }}</span>
              <span v-if="item.eventType===3" class="el-icon-arrow-down "></span>
            </div>
          </div>
          <div class="right-login">
            <div class="login-icon-box " v-if="configuration.isShowQQIcon===1">
              <div class="iconfont icon-weixin login-icon wx" :class="{'add-border':!isReachTheTop}"></div>
            </div>
            <div class="login-icon-box " v-if="configuration.isShowWXIcon===1">
              <div class="iconfont icon-QQ login-icon QQ" :class="{'add-border':!isReachTheTop}"></div>
            </div>
            <div class="login" @click="manageMySolitaire" v-if="areYouLoggedIn">管理我的接龙</div>
            <template v-else>
              <div class="login" v-if="configuration.isShowLogin===1" @click="scanCodeToLogIn1">登录</div>
              <div class="registered" v-if="configuration.isShowRegister===1" @click="scanCodeToLogIn1">免费注册</div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="second-level-navigation-bigBox box-center"
         :class="{lucency:isReachTheTop}"
         v-if="activeIndex>=0">
      <div class="second-level-navigation-box"
           v-show="navigationFunctionList[activeIndex].isShow"
           @mouseover="navigationListEvent(activeIndex,'over')"
           @mouseleave="navigationListEvent(activeIndex,'leave')"
      >
        <div class="second-level-navigation-content">
          <div class="second-navigation-list">
            <div class="second-navigation-item" @click="secondNavigationJump(index)" v-for="(item,index) in navigationFunctionList[activeIndex].menuList" :key="index">
              <div class="content-box">
                <div class="image-box">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="item.imageUrl"
                    fit="cover"></el-image>
                </div>
                <div class="text-list">
                  <div class="title">{{ item.title }}</div>
                  <div class="memo">{{ item.memo }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carrier" v-if="isShowSunCode" @click.self="cancelLogin">
      <div class="sunCode-box">
        <div class="appletCode-box">
          <img class="appletCode" alt="首页图标" v-if="appletCode" :src="appletCode">
          <!--<el-image :src="appletCode" fit="fit"></el-image>-->
        </div>
        <div class="loginStatusText">{{ loginStatusText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import {OSSHOST} from '@/utils/constant';
import {jlLogoNoPinYin, jlLogoNoPinYinG} from '@/assets/js/staticData/imageRequest'
import {getNavigationFunctionListAPI} from '@/api/firstHome/firstHome'
import {JSONparse, valueTypeFun} from '@/assets/js/tools/common'
import {checkLogin} from '@/utils/logIn'

export default {
  name: 'NavigationBar',
  data() {
    return {
      configuration:{},//项目配置信息
      // url: 'https://oss.jielongshenqi.com/data/jl/img1/logo1.png',
      url: jlLogoNoPinYin,
      isReachTheTop: true,//是否到达顶部
      contentList: [
        {
          title: '首页',
          label: 'home',
          isSubset: false,//是否有子集
        },
        {
          title: '产品功能',
          label: 'cpgn',
          isSubset: false,//是否有子集
        },
        {
          title: '应用展示',
          label: 'yyzs',
          isSubset: false,//是否有子集
        },
        {
          title: '样本服务',
          label: 'ybfw',
          isSubset: false,//是否有子集
        },
        {
          title: '产品价格',
          label: 'cpjg',
          isSubset: false,//是否有子集
        },

        {
          title: '模板中心',
          label: 'mbzx',
          isSubset: false,//是否有子集
        },

      ],
      //导航栏列表功能
      navigationFunctionList: [
        {menuList: [], isShow: false},
        {menuList: [], isShow: false},
        {menuList: [], isShow: false},
        {menuList: [], isShow: false},
        {menuList: [], isShow: false},
        {menuList: [], isShow: false},
      ],
      activeIndex: -1,
      userInfo: {},
      token: {},
      // 登录配置
      isShowSunCode: false,//是否显示登录太阳码
      loginStatusText: '加载中...',//登录状态说明
      areYouLoggedIn: false,//是否已登录
      appletCode: '',
      ws: '',//webSocket协议
    }
  },
  async created() {
    checkLogin.call(this) // 检查登录
    window.addEventListener('scroll', this.handleScroll, true);
    //获取 首页 导航栏 功能列表
    this.navigationFunctionList = await getNavigationFunctionListAPI()
    this.configuration = this.$store.state.configuration
    console.log('项目配置信息 ==>', this.configuration)
    // this.activeIndex = 4
    // this.navigationFunctionList[4].isShow = true
    // console.log('navigationFunctionList ==>', navigationFunctionList)
    // console.log("configuration ==>",this.configuration)
  },
  methods: {
    /**
     * 点击登录或免费注册
     */
    scanCodeToLogIn1() {
      checkLogin.call(this) // 检查登录
    },

    /**
     * 取消登录
     */
    cancelLogin() {
      // this.appletCode = ''
      this.isShowSunCode = false
      this.ws.close()//关闭websocket协议连接
    },
    /**
     * 点击 管理我的接龙
     */
    manageMySolitaire() {
      this.$router.push({name: 'Solitaire'})
    },
    /**
     * 二级导航栏跳转
     * @param i 二级导航栏的索引
     */
    secondNavigationJump(i) {
      console.log('二级导航栏 索引 ==>', this.activeIndex, i)
      let index = this.activeIndex;//一级导航栏的索引
      let obj = this.navigationFunctionList[index].menuList[i]
      let jumpInfo = obj.jumpInfo
      jumpInfo = JSONparse(jumpInfo)//解析字符串为对象 不是JSON字符串的返回原数据
      if (obj.eventType === 1) {//本页跳转
        if (typeof jumpInfo !== 'string') {
          throw new Error(`后台配置的的数据跟实际跳转本页的数据不符`)
        }
        this.$emit('jump', jumpInfo)
      } else if (obj.eventType === 2) {//跳转到其他页面
        if (valueTypeFun(jumpInfo) !== 'Object') {
          throw new Error(`后台配置的的数据【${jumpInfo}】不是JSON字符串`)
        }
        this.$router.push(jumpInfo);
      }
      this.navigationListEvent(index, 'leave')

    },
    /**
     * 导航栏功能列表移入移出事件
     * @param index 导航栏功能列表的索引
     * @param type 导航栏功能列表的鼠标事件 over:移入事件 | leave：移出事件
     */
    navigationListEvent(index, type) {
      let navigationFunction = this.navigationFunctionList[index]
      if (navigationFunction.eventType !== 3) return //只有 有二级导航栏事件的才会执行该方法

      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        let isOver = type === 'over'
        this.isReachTheTop = !isOver
        this.url = this.isReachTheTop ? jlLogoNoPinYin : jlLogoNoPinYinG
        navigationFunction.isShow = isOver
        this.navigationFunctionList[index] = navigationFunction
        this.activeIndex = isOver ? index : -1
        if (!isOver) this.handleScroll()
      },)

    },
    /**
     * 浏览器滚动触发函数
     */
    handleScroll() {
      // console.log('activeIndex ==>', this.activeIndex)
      if (this.activeIndex >= 0 && scrollTop !== 0) return;
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.isReachTheTop = scrollTop === 0
      // this.url = this.isReachTheTop ? jlLogoNoPinYin : jlLogoNoPinYinG
      this.url = this.isReachTheTop ? this.configuration.logoURL : this.configuration.logoURL1
    },
    /**
     * 跳转模板
     */
    jumpType(index) {
      let operationInfo = this.navigationFunctionList[index]
      let eventType = operationInfo.eventType // 事件类型 1：本页跳转 2：跳转到其他页面 3：显示二级导航栏事件
      let label = operationInfo.jumpUrl //跳转的标签 或者 路由名+参数
      switch (eventType) {
        case 1://本页跳转
          this.$emit('jump', label)
          break;
        case 2://跳转到其他页面
          label = JSONparse(label)//解析字符串为对象
          console.log('label ==>', label)
          if (valueTypeFun(label) !== 'Object') {
            throw new Error(`后台配置的的数据【${label}】不是JSON字符串`)
          }
          this.$router.push(label);
          break;
        case 3://显示二级导航栏事件
          this.navigationFunctionList[index].isShow = !this.navigationFunctionList[index].isShow
          break;
      }
    },
  },

}
</script>

<style lang="scss" scoped>
$QQWXbgc: rgba(255, 255, 255, .15);

.template {
  width: 100vw;
  min-width: 1300px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  .navigationBar-box {
    //transition: background-color .8s;

    .navigationBar-content {
      width: 80%;
      min-width: 1200px;
      max-width: 1400px;

      .navigationBar {
        width: 100%;
        height: 68px;
        display: flex;

        .logo {
          display: flex;
          align-items: center;
        }

        .content {
          margin-left: 50px;
          display: flex;
          font-size: 10px;
          color: white;

          .content-item {
            //transition: all .8s;
            margin: 0 15px;
            font-size: 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
        }

        .right-login {
          margin-left: auto;
          display: flex;

          .login-icon-box {
            display: flex;
            align-items: center;
            justify-content: center;

            .login-icon {
              font-size: 26px;
              width: 25px;
              height: 25px;
              padding: 5px;
              margin-right: 10px;
              display: flex;
              align-items: center;
            }

            .wx {
              color: #25ad65;
              border-radius: 50%;
              transition: all .5s;
              cursor: pointer;
              background-color: $QQWXbgc;
              border: 1px solid $QQWXbgc;

              &:hover {
                background-color: #25ad65;
                color: white;
              }
            }

            .QQ {
              color: #279af3;
              border-radius: 50%;
              transition: all .5s;
              cursor: pointer;
              background-color: $QQWXbgc;
              border: 1px solid $QQWXbgc;

              &:hover {
                background-color: #279af3;
                color: white;
              }
            }
          }


          .login {
            color: #18c631;
            background-color: white;
            font-size: 16px;
            padding: 10px 20px;
            margin: 15px 10px;
            border-radius: 34px;
            transition: all .5s;
            cursor: pointer;

            &:hover {
              background-color: #18c631;
              color: white;
            }
          }

          .registered {
            color: white;
            background-color: #07dc60;
            font-size: 16px;
            padding: 10px 20px;
            margin: 15px 10px;
            border-radius: 34px;
            transition: all .5s;
            cursor: pointer;

            &:hover {
              background-color: #0aea33;
            }
          }
        }
      }

    }

  }

  //二级导航
  .second-level-navigation-bigBox {
    width: 100%;
    background-color: rgba(255, 255, 255, .8);
    backdrop-filter: blur(10px);
    transition: all .8s;

    .second-level-navigation-box {
      width: 80%;
      min-width: 1200px !important;
      max-width: 1400px !important;

      .second-level-navigation-content {
        //padding: 0 200px;
        box-sizing: border-box;

        .second-navigation-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .second-navigation-item {
            width: 50%;
            cursor: pointer;

            .content-box {
              height: 50px;
              margin: 15px 0;
              display: flex;

              .image-box {

              }

              .text-list {
                margin-left: 10px;

                .title {
                  margin-top: 5px;
                }

                //.title-color{
                //  color: white;
                //}
                .memo {
                  margin-top: 5px;
                  font-size: 14px;
                  color: #606060;
                }

                //.memo-color{
                //  color: #e5e5e5;
                //}
              }
            }
          }
        }
      }
    }
  }
}

.noShowSecond {
  transform: rotate(30deg);
  opacity: 0;
}

.showSecond {
  transform: rotate(0deg);
  opacity: 1;
}

.lucency {
  //background-color: rgba(80, 189, 129, 0.2) !important;
}

.bgc {
  background-color: white;
}

.cgColor {
  color: #1a1a1a;
}

.aCgColor {
  color: white;
}

.add-border {
  border: 1px solid #e2e2e2 !important;
}

/*登录太阳码*/
.carrier {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;

  .sunCode-box {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;

    .appletCode-box {
      width: 250px;
      height: 250px;
      background-color: white !important;

      .appletCode {
        width: 100%;
        height: 100%;
        background-color: white !important;
      }
    }

    .loginStatus {
      font-size: 20px;
      color: #333333;
      line-height: normal;
      margin-top: 20px;
    }

    .loginStatusText {
      margin-top: 20px;
      text-align: center;
    }
  }
}
</style>
