<template>
  <div class="XiaoShenQi">
    <div v-if="isPC">
      <el-container>
        <el-header>
          <Header />
        </el-header>
        <el-main class="el-main">
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </el-main>
        <Foot label="template"></Foot>
      </el-container>
    </div>
    <!--移动端的模板中心-->
    <div v-else>
      <TemplateCenterAPP></TemplateCenterAPP>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
// import Footer from '@/components/Footer'
import Foot from '@/components/FirstHome/Foot'
import TemplateCenterAPP from '../../components/APP/TemplateCenterAPP/TemplateCenterAPP'

export default {
  name: 'XiaoShenQi',
  components: {Header, Foot,TemplateCenterAPP},
  data() {
    return {
      isPC: true,//是否为PC端
    }
  },
  created() {
    this.isPC = this.$store.state.isPC
  },
  computed: {},
  methods: {},
}
</script>
