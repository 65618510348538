<template>
  <div class="create">
    <!--布局容器-->
    <el-container>
      <el-header>
        <Head>
          <template v-slot:createSolitairePage>
            <!--个人信息开始-->
            <div class="head_right_info">
              <el-menu default-active="1" class="el-menu-demo" mode="horizontal">
                <el-submenu index="1">
                  <template slot="title">联系客服</template>
                  <el-image
                    :src="src"
                    fit="contain"></el-image>
                </el-submenu>
              </el-menu>
              <div class="btn-box">
                <el-button @click="preview" class="el-btn" plain>预览</el-button>
              </div>
              <div class="btn-box" @click="releaseSolitaire">
                <el-button class="fabu" plain>立即发布</el-button>
              </div>
            </div>

            <!--个人信息结束-->
          </template>
        </Head>
      </el-header>
      <!--容器头部结束-->
      <!--容器的内容开始-->
      <el-main>
        <div class="main-content" v-if="isLoadingCompleted">
          <el-row class="el-rows">
            <el-col class="el_col" :xs="4" :sm="5" :md="5" :lg="7" :xl="7">
              <div class="page-left">
                <div class="left">
                  <QuestionAndInfo @addTopicCommandEvent="addTopicCommandFun"></QuestionAndInfo>
                </div>
              </div>
            </el-col>
            <el-col class="el_col" :xs="15" :sm="14" :md="12" :lg="10" :xl="9">
              <div class="page-content">
                <CenterContent ref="CenterContent"></CenterContent>
              </div>
            </el-col>
            <el-col class="el_col" :xs="5" :sm="5" :md="7" :lg="7" :xl="8">
              <div class="page-right">
                <div class="right">
                  <TypeSolitaireSettings></TypeSolitaireSettings>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <!--容器的内容结束-->
    </el-container>
  </div>
</template>

<script>
import Head from '@/components/Head';
import QuestionAndInfo from '@/components/createSolitaire/QuestionAndInfo';
import CenterContent from '@/components/createSolitaire/centerContent/index';
import TypeSolitaireSettings from '@/components/createSolitaire/settings/TypeSolitaireSettings';
import {getSolitaireObj} from '@/utils/createSoiltaire/processSolitaireObj';
import {getSessionStorage, setSessionStorage} from '@/assets/js/tools/common';
import {kefuUrl} from '@/assets/js/staticData/imageRequest';
import {getSolitaireConfigAPI, postReleaseSolitaireAPI} from '@/api/createSolitaire/solitaireDetail';

export default {
  name: 'Create',
  components: {Head, QuestionAndInfo, CenterContent, TypeSolitaireSettings},
  data() {
    return {
      src: kefuUrl,
      solitaireType: 0,//接龙类型
      solitaireConfig: {},//接龙最初始化的数据对象
      solitaireObj: {},//接龙对象
      isLoadingCompleted: false,//数据是否加载完成

    }
  },
  created() {
    this.processSolitaireConfig()
  },
  beforeDestroy() {
    // 删除缓存的参数
    if(getSessionStorage('paramsObj'))sessionStorage.removeItem('paramsObj')
  },
  methods: {
    /**
     * 预览
     */
    preview() {
      console.log('点击预览')
    },
    /**
     * 获取接龙详情
     */
    async processSolitaireConfig() {
      this.solitaireType = this.$route.params.solitaireType
      let type = Number(this.solitaireType) === 7 ? 'zxzy' : ''
      let params = this.$store.state.query;
      // console.log('params ==>', params)
      let paramsObj = getSessionStorage('paramsObj') || {}
      if (params?.solitaireId) {
        paramsObj[type] = params
        // console.log('paramsObj ==>', paramsObj)
        setSessionStorage('paramsObj', paramsObj)
      } else {
        params = paramsObj[type]
      }
      // let params = {userId: 1506511, solitaireId: 501837};
      // console.log('params ==>', {...params})
      this.solitaireConfig = await getSolitaireConfigAPI(this.solitaireType)
      this.solitaireObj = await getSolitaireObj(this.solitaireConfig, {...params})
      this.$store.commit('cgSolitaireConfig', this.solitaireConfig)
      this.$store.commit('cgSolitaireObj', this.solitaireObj)
      this.isLoadingCompleted = true //数据加载完成后再渲染页面
      // console.log('solitaireObj ==>', this.solitaireObj)
    },
    /**
     * 点击发布接龙
     */
    async releaseSolitaire() {
      let solitaireObj = this.$store.state.solitaireObj;
      console.log('发布接龙的对象 solitaireObj ==>', solitaireObj.elementRelationlist[0])
      console.log('发布接龙的对象 solitaireObj.title ==>', solitaireObj.title)
      // console.log('solitaireObj.title ==>', solitaireObj.title)
      // if (this.data.solitaireObj.solitaireRole == 1 || this.data.solitaireObj.userId == wx.getStorageSync('userId')) {
      //   // 添加发布人头像
      //   solitaireObj.headimgurl = wx.getStorageSync('userMsg').headimgurl
      // }

      // 给反馈元素添加顺序
      solitaireObj.contentElementRelationlist.forEach((item, index) => item.sort = index + 1)
      // 给题目 或者 问题 列表进行排序
      solitaireObj.elementRelationlist.forEach((item, index) => item.sort = index + 1)

      let solitaireInfo = JSON.stringify(solitaireObj)
      //所有题目的信息
      let elementRelationInfo = JSON.stringify(solitaireObj.elementRelationlist)
      //介绍接龙的信息
      let contentElementRelationInfo = JSON.stringify(solitaireObj.contentElementRelationlist)
      let imgs = this.$store.state.imageReviewQueue.slice(0, -1)
      //考生的基本信息
      let basicInfoObj = JSON.stringify(solitaireObj.basicInfoObj)
      let data = {
        solitaireInfo,
        elementRelationInfo,
        contentElementRelationInfo,
        basicInfoObj,
        imgs
      }
      // console.log('data ==>', data)
      // console.log('接龙对象:\n', solitaireObj)
      // console.log('接龙表 json 数据:\n', solitaireInfo)
      // console.log('接龙发起反馈关联元素表 json 数据:\n', elementRelationInfo)
      // console.log('接龙内容元素关联表 json 数据:\n', contentElementRelationInfo)
      console.log('接龙审核图片队列:\n', imgs)
      //发出发布请求
      let isSuccess = await postReleaseSolitaireAPI(data)
      if (isSuccess) {//是否发布成功
        this.$message({
          message: '发布成功',
          type: 'success'
        });
        let timer = setTimeout(() => {
          clearTimeout(timer)
          this.$router.go(-1)
        }, 200)
      }
    },

    /**
     * 添加题目类型
     */
    addTopicCommandFun(data) {
      if (data?.bigType === '问题类型') {
        this.$refs.CenterContent.$refs.examQuestions.addTopicFun(data)
      } else if (data?.bigType === '基本信息') {
        this.$refs.CenterContent.$refs.basicInformation.addInformationItem(data)
      }

    },
  }
}
</script>

<style lang="scss" scoped>
$themeColor: #18c631;
.head_right_info {
  display: flex;

  .btn-box {
    margin-left: 20px;

    .el-btn {
      background-color: #ebedf0;

      &:hover {
        border-color: $themeColor;
        color: $themeColor;
      }
    }

    .fabu {
      background-color: $themeColor;
      color: white;

      &:hover {
        border-color: $themeColor;
      }
    }
  }
}

//el-main里面的内容
.main-content {
  //width: 70vw;
  //margin: 0 auto;
  .transparent {
    height: 10px;
  }

  .page-left {
    display: flex;

    .left {
      margin-left: auto;
    }
  }

  .page-content {
    margin: 0 20px;
  }

  .page-right {
    max-height: calc(100vh - 100px);
    overflow: auto;
    display: flex;

    .right {
      margin-right: auto;
    }

    &::-webkit-scrollbar {
      //display: none; /* Chrome Safari */
      width: 2px;
    }

  }
}
</style>
<style>
.el-header, .el-menu-item, .el-submenu {
  background-color: #ebedf0;
}
</style>
