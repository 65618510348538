import {getLocalStorage, JSONparse, setLocalStorage} from '@/assets/js/tools/common'
import {PARTENCODE} from '@/utils/constant'

/**
 * 判断登录状态
 */
export const loginStatus = () => {
  const {userId} = getLocalStorage('userInfo')
  return !!userId
}

/**
 * 检查是否需要登录
 */
export function checkLogin() {
  const login = loginStatus()
  // console.log('登录状态:', login)
  // 未登录
  if (!login) {
    // 弹起小程序码(扫码登录)
    this.isShowSunCode = true
    let websocketId = Math.random().toString().slice(2)
    const ws = new WebSocket(`wss://www.dancimao.com/DCM/socketServer/${websocketId}`)
    this.ws = ws
    ws.onopen = function () {
      //process.env.NODE_ENV 输出此时的环境 开发环境还是生产环境
      //envVersion（小程序码的环境）的值 正式版为 release，体验版为 trial，开发版为 develop
      let obj = {websocketId, partenCode: PARTENCODE, cmd: 'getQrcode', envVersion: 'release'}
      let objStr = JSON.stringify(obj)
      ws.send(objStr)
      console.log('WebSocket已连接');
    }
    ws.onmessage = (data) => {
      // console.log('这是服务端返回的数据  ==》', data.data)
      // ws.send("前端收到后端返回的数据")
      let objData = JSONparse(data.data)
      console.log('服务端返回数据 objData ==>', objData)
      if (objData.base64Str) {//返回来的小程序码
        this.appletCode = `data:image/png;base64,${objData.base64Str}`
        this.loginStatusText = '用微信扫码登录'
        return;
      }
      if (objData.cmd === 'qrcode') {
        this.appletCode = require('@/assets/img/scanned.png')
        this.loginStatusText = '已扫码，请在手机操作!'
        return;
      }
      if (objData.cmd === 'cancel') {
        this.$message('已取消登录');
        this.cancelLogin()
        return;
      }
      if (objData.userInfo) {//确定登录
        // this.$message({
        //   message: '登录成功',
        //   type: 'success'
        // });
        this.$toast.success('登录成功');
        // this.$store.commit('cgLoginInfo', objData.userInfo)
        this.areYouLoggedIn = true
        this.activeIndex = '2'
        console.log('objData.userInfo ==>', objData.userInfo)
        let {headimgurl, nickname, userId} = objData.userInfo
        setLocalStorage('userInfo', {headimgurl, nickname, userId})
        this.cancelLogin()
      }
    }
    ws.onclose = () => {
      this.isShowSunCode = false;
      console.log('断开连接')
    }
    // 通信发生错误时触发
    ws.onerror = function () {
      console.log('通信发生错误时触发');
    };
    // this.$router.push({name: 'ScanCodeToLogIn'})
  } else {
    // 已登录
    // this.$toast.success('已登录');
    this.areYouLoggedIn = true
    this.activeIndex = '2'
  }
}
