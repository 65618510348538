<template>
  <div class="SolitaireIntroduction">
    <div v-for="(item, index) in list" :key="index">
      <!--文字-->
      <template v-if="item.elementName==='文字'">
        <div class="text">{{ item.describtion1 }}</div>
      </template>
      <!--大图-->
      <template v-else-if="item.elementName==='大图'">
        <div class="big-img">
          <el-image class="big-img-inner" :src="item.describtion1" fit="contain"></el-image>
        </div>
      </template>
      <!--小图-->
      <template v-else-if="item.elementName==='小图'">
        <SmallImg :list="item.describtion1" />
      </template>
      <!--视频-->
      <template v-else-if="item.elementName==='视频'">
        <div class="video-wrap">
          <video
            controls
            width="auto"
            height="400"
            class="video"
            :src="item.describtion1.tempFilePath"
          >您的浏览器不支持 video 标签。
          </video>
        </div>
      </template>
      <!--附件-->
      <template v-else-if="item.elementName==='附件'">
        <div class="annex" v-for="(a, i) in item.describtion1" :key="i">
          <el-link
            type="primary"
            :href="a.path"
            target="_blank">
            <i class="el-icon-paperclip">{{ a.name }}</i>
          </el-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {parseJSON} from '@/assets/js/tools/common'
import SmallImg from '@/components/SolitaireDetails/components/SmallImg'

export default {
  name: 'SolitaireIntroduction',
  components: {SmallImg},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    // console.log('接龙介绍组件创建阶段:', this.list)
    this.list.forEach(item => {
      item.describtion1 = parseJSON(item.describtion1)
    })
  },
}
</script>

<style lang="scss">
.SolitaireIntroduction {
  padding: 0 20px 20px;
  // 介绍元素
  & > div {
    margin-bottom: 20px;
  }
  & > div:last-child {
    margin-bottom: 0;
  }

  // 去除元素底部空白
  .big-img,
  .small-img,
  .video-wrap {
    font-size: 0;
  }

  // 文字
  .text {
    line-height: 1.5;
  }

  // 大图
  .big-img {
    .big-img-inner {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  // 小图
  .small-img {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    .small-img-inner {
      aspect-ratio: auto 1 / 1
    }
  }

  // 视频
  .video-wrap {
    .video {
      width: 100%;
      height: 400px;
      border-radius: 5px;
    }
  }

  // 附件
  .annex {
    margin-bottom: 5px;
  }
}
</style>
