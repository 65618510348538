<template>
  <div class="foot-box">
    <div class="qunjielong-list" v-if="!label">
      <div class="qunjielong-item" @click="$router.push({name:'Template'})" v-for="(item,index) in qunjielongList" :key="index">
        {{ item.title }}
      </div>
    </div>
    <div class="content">
      <div class="qrCodeList-box">
        <div class="qrCode-item">
          <div class="qrCode">
            <el-image
              class="logo"
              style="width: 70px"
              :src="url "
              fit="contain"></el-image>
          </div>
          <div class=" attention-text">关注接龙小神器公众号，了解更多</div>
        </div>
      </div>

      <div class="companyInfo">
        <div>地 址：广州市天河区天河北路890号广州国际科贸中心9楼</div>
        <div>（地铁3号线华师站B出口）</div>
        <div>电 话：020-31420405</div>
        <div>传 真：020-31420506</div>
        <div>邮 编：510630</div>
        <div>邮 箱：kefu@xiaoshenqi.cn</div>
      </div>

    </div>

    <div class="footer">
      <div class="text">{{currentDomainNameInfo.text}}</div>
      <div class="bah">备案号：
        <a href="https://beian.miit.gov.cn">{{currentDomainNameInfo.filingNo}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import {qrCodeList, qunjielongListAPP} from '@/assets/js/staticData/firstHome';
import {getAppletCodeListAPI} from '@/api/firstHome/firstHome';
import {getCurrentDomainNameInfo} from '../../assets/js/staticData/firstHome';

export default {
  name: 'Foot',
  props: ['label'],
  data() {
    return {
      activeIndex: -1,
      qrCodeList,
      qunjielongList: qunjielongListAPP,
      appletCodeList: [],
      url:'',
      domainName:'',//访问完整路径的域名
      currentDomainNameInfo:'',//当前域名的备案号信息
    }
  },
  created() {
    this.processAppletCodeListFun()//获取小程序码、微信客服二维码等图片列表以及数据的处理
    this.currentDomainNameInfo = getCurrentDomainNameInfo()//获取当前域名的备案号信息
  },
  methods: {
    /**
     * 获取小程序码、微信客服二维码等图片列表以及数据的处理
     */
    async processAppletCodeListFun() {
      this.appletCodeList = await getAppletCodeListAPI()
      this.url = this.appletCodeList[3].childList[0].bigimg
      this.appletCodeList.forEach((item) => {
        if (item.type === 'wxkf') {
          console.log("item ==>",item)
          this.$emit('getKefuQrCode', {kefuQrCode: item.childList[0].bigimg})
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.foot-box {
  .footer {
    width: 100%;
    //background-color: #353535;
    text-align: center;
    color: #bbbbbb;
    font-size: 12px;
    transform: scale(0.8);
    .text {
      padding-top: 10px;
    }

    .bah {
      padding: 10px 0;
    }

    a {
      color: #bbbbbb;
    }

    a:hover {
      color: #5cb429;
    }
  }
}

.qunjielong-list {
  width: 100%;
  font-size: 10px;
  color: #909090;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;

  .qunjielong-item {
    width: 29%;
    margin: 5px 2%;
    cursor: pointer;


  }

}

.content {
  margin-top: 20px;

  .qrCodeList-box {
      display: flex;
    .qrCode-item{
      margin: auto;
      .qrCode{
        display: flex;
        justify-content: center;
      }
      .attention-text{
        font-size: 12px;
        margin: 10px 0;
      }
    }
  }
  .companyInfo{
    font-size: 12px;
    text-align: center;
    margin: 20px 0;
    color: #858585;
    div{
      margin-top: 10px;
      &:nth-of-type(2){
        margin-top: 4px;
      }
    }

  }
}

.feet-box {
  background-color: #353535;
  color: #c3c3c3;
  border-bottom: 1px solid #464646;

  .screen6 {
    width: 1200px;
    margin: 0 auto;
    padding: 30px 0;


    .content {
      overflow: hidden;
      display: flex;

      .left {
        width: 700px;

        li {
          display: flex;
          font-size: 16px;
          padding-bottom: 10px;

          //p {
          //  font-size: 24px;
          //}
        }
      }

      .right {
        margin-left: auto;
        //margin-right: 200px;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .box {
          margin-left: 20px;

          p {
            font-size: 14px;
            text-align: center;
            margin-top: 15px;
            //color: #343434;
          }
        }

        .mgr {
          margin-right: 45px;
        }

        img {
          width: 180px;
        }
      }
    }
  }

  .right_top {
    //background: #1a59b7;
    color: #ffffff;
    overflow: hidden;
    z-index: 1;
    position: fixed;
    text-align: center;
    border-radius: 4px;
    right: 0;
    top: 180px;
    padding: 5px;

    .box {
      width: 120px;

      p {
        margin: 10px 0;
      }
    }


  }

  .showQrCode-box {
    .package-box {
      //width: 200px;
      //height: 300px;
      padding: 10px 20px;
      background-color: white;
      position: fixed;
      right: 135px;
      box-sizing: border-box;
      display: flex;
      border-radius: 6px;
      box-shadow: 0 0 15px #b2b2b2;

      &:after {
        content: "";
        width: 0;
        height: 0;
        display: block;
        border: 8px transparent solid;
        border-radius: 3px;
        border-top-color: white;
        border-right-color: white;
        position: absolute;
        top: 160px;
        left: calc(100% - 10px);
        transform: rotate(45deg);
      }

      .showQrCode {
        padding: 10px;
        background-color: white;

        //transform: translate(-25%,-25%);

        .description {
          color: #1a1a1a;
          text-align: center;
          margin-top: 8px;
        }
      }
    }
  }

}


</style>
