<template>
  <div class="typeSolitaireSettings-box">
    <div class="legendTitle">更多设置</div>
    <div class="typeSolitaireSettings">


      <div v-for="(item,index) in solitaireSetUpList" :key="index">
        <div :class="item.itemBoxClass" v-if="item.isShow">
          <div class="text">{{ item.text }}</div>
          <div class="suggest" v-if="item.suggest">{{ item.suggest }}</div>
          <div :class="item.contentBoxClass">

            <template v-if="item.type==='startTime'">
              <el-date-picker
                  v-model="startTime"
                  type="datetime"
                  placeholder="选择开始时间">
              </el-date-picker>
            </template>
            <template v-if="item.type==='endTime'">
              <el-date-picker
                  v-model="solitaireObj.endTime"
                  type="datetime"
                  placeholder="选择结束时间">
              </el-date-picker>
            </template>

            <!--发布人-->
            <template v-else-if="item.type==='nickname'">
              <el-input v-model="solitaireObj.nickname" placeholder="请输入内容"></el-input>
            </template>
            <template v-else-if="item.type==='managerMember'">
              <el-input type="number" :value="solitaireObj.managerMemberCount" @input="cgManagerMemberCountFun"
                        placeholder="请设置管理员人数"></el-input>
            </template>
            <!-- 转发图-->
            <template v-else-if="item.type==='forwardImg'">
              <!--图片遮罩层（操作） -->
              <pictureOperate
                  v-if="isShowForwardImg"
                  @previewPicture="previewPicture"
                  @deleteEvent="deleteForwardImg"
              ></pictureOperate>
              <el-image
                  v-if="isShowForwardImg"
                  style="width: 150px;height: 120px;"
                  :src="solitaireObj.forwardImg"
                  fit="contain">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <el-upload
                  v-else
                  class="avatar-uploader"
                  ref="uploadForward"
                  accept="image/*"
                  :action="action"
                  :show-file-list="false"
                  :on-change="cgFileStatus"
                  :limit="1"
                  :http-request="uploadCustomizeFunc"
                  list-type="picture"
                  :auto-upload="true">
                <div class="add-box">
                  <div>
                    <div class="iconfont icon-add-bold icon"></div>
                    <div class=" ">上传图片</div>
                  </div>
                </div>
              </el-upload>
            </template>
            <template v-else-if="item.type==='groupName'">
              <el-select :value="solitaireObj.groupName" @change="cgGroupName" clearable placeholder="请选择">
                <el-option
                    v-for="element in groupList"
                    :key="element.id"
                    :label="element.groupName"
                    :value="element.id">
                </el-option>
              </el-select>
            </template>
            <template v-else-if="item.type==='solitaireCount'">
              <el-input type="number" :value="solitaireObj.solitaireCount" @input="cgSolitaireCountFun"
                        placeholder="请输入每人最多可填次数"></el-input>
            </template>
            <template v-else-if="item.type==='isForbidCopy'">
              <el-switch
                  v-model="solitaireObj.isForbidCopy"
                  :active-value="1"
                  :inactive-value="2"
                  active-color="#18c631"
                  inactive-color="#ff4949">
              </el-switch>
            </template>
            <template v-else-if="item.type==='isForbidForward'">
              <el-switch
                  v-model="solitaireObj.isForbidForward"
                  :active-value="1"
                  :inactive-value="2"
                  active-color="#18c631"
                  inactive-color="#ff4949">
              </el-switch>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!--    剪切图片的组件-->
    <Cropper
        :shearImg='shearImg'
        :cropWidth="500"
        :cropHeight="400"
        @cutThePicture="getShearBlobUrl"
        @cancel=' cancelCutThePicture'
    ></Cropper>
  </div>
</template>

<script>
import Cropper from '../../public/Cropper';
import pictureOperate from '../../public/pictureOperate.vue';
import {getGroupDataFnAPI} from '@/api/createSolitaire/groupData';
import {deepCopy} from '@/assets/js/tools/common';
import {ossHostUpload} from "@/utils/constant";
import {solitaireSetUpList} from '@/assets/js/staticData/createSolitairePage'
import {uploadFiles} from '@/utils/public/customizeUploadFile'

export default {
  name: 'typeSolitaireSettings',
  components: {Cropper, pictureOperate},
  data() {
    return {
      solitaireConfig: {},
      solitaireObj: {},
      solitaireSetUpList: [],
      OSSSignatureObj: {},//oss的上传签名数据
      forwardImg: '',//转发图片
      action: ossHostUpload,//上传OSS图片的域名
      // startAndEndTimeArr:['','']
      startAndEndTimeArr: '',
      groupList: [],//群组列表
      startTime: '',//开始时间
      endTime: '',//结束时间
      shearImg: '',//需要剪切图片的路径
      file: '',//上传图片的对象
    }
  },
  async created() {
    this.solitaireConfig = this.$store.state.solitaireConfig
    this.solitaireObj = deepCopy(this.$store.state.solitaireObj)
    let {userId, startTime, endTime} = this.solitaireObj
    this.groupList = await getGroupDataFnAPI(userId)
    this.solitaireObj.groupName = this.getGroupName(this.groupList)
    this.startTime = startTime
    this.endTime = endTime

    // eslint-disable-next-line no-unused-vars
    let {solitaireType} = this.solitaireObj
    solitaireSetUpList.forEach((item) => {
      item.isShow = item.isShowList.includes(solitaireType)
    })
    this.solitaireSetUpList = solitaireSetUpList
  },
  methods: {
    getGroupName(groupList) {
      let groupName = ''
      if (groupList) {
        groupList.forEach((item) => {
          if (item.id === this.solitaireObj.groupId) {
            groupName = item.groupName
          }
        })
      }
      return groupName
    },
    /**
     * 预览转发图图片
     */
    previewPicture() {
      this.$viewerApi({images: [this.solitaireObj.forwardImg]})
    },
    /**
     * 删除转发图图片
     */
    deleteForwardImg() {
      this.solitaireObj.forwardImg = this.solitaireConfig.forwardImg
    },
    /**
     * 取消截图
     */
    cancelCutThePicture() {
      this.shearImg = ""
    },
    /**
     * 获取剪切后的图片
     */
    async getShearBlobUrl(data) {
      console.log('data ==>', data)
      this.shearImg = '';//主要作用是隐藏 切图组件
      let file = this.file;//上传图片所需要的文件对象
      let {shearBlobData} = data
      file.file = new File([shearBlobData], file.file.name, {type: file.file.type})
      let url = await uploadFiles.call(this, file).catch(() => {
        console.log("上传图片失败 ")
        this.$refs.uploadForward[0].clearFiles();//清除上传的历史记录
      })
      if (url) {
        this.solitaireObj.forwardImg = url
        this.$refs.uploadForward[0].clearFiles()
      }
    },
    /**
     * 上传文件自定义方法
     *
     * 不用组件的自动上传文件，是因为上传多个文件时，所传的参数无法改变
     */
    async uploadCustomizeFunc(file) {
      console.log('file ==>', file)
      this.file = file;
      this.$refs.uploadForward[0].clearFiles();//清除上传的历史记录
    },
    /**
     * 文件状态改变时的钩子
     */
    async cgFileStatus(e) {
      console.log('选择封面图上传 cgFileStatus==>', e)
      if (e.status === 'ready') {
        let imgSize = this.$store.state.solitaireObj.imgSize
        if ((e.raw.size / 1024 / 1024) > imgSize) {
          this.$message({
            message: `上传的图片超过${imgSize}M大小限制`,
            type: 'error'
          })
          return;
        }
        this.shearImg = e.url //临时图片
      }
    },
    /**
     * 群组名状态改变时触发
     */
    cgGroupName(id) {
      console.log('id ==>', id)
      this.solitaireObj.groupId = id
      console.log('this.groupList.find(item =>item.id === id ) ==>', this.groupList.find(item => item.id === id))
      let groupNameObj = this.groupList.find(item => item.id === id)
      this.solitaireObj.groupName = groupNameObj ? groupNameObj.groupName : ''
    },
    /**
     * 修改最多可填次数
     */
    cgSolitaireCountFun(count) {
      this.solitaireObj.solitaireCount = count <= 1 ? 1 : count
    },
    /**
     * 修改管理员的人数
     */
    cgManagerMemberCountFun(count) {
      this.solitaireObj.managerMemberCount = count <= 1 ? 1 : count
    },
  },
  watch: {
    solitaireObj: {
      handler() {
        this.$store.commit('cgSolitaireObj', this.solitaireObj)
      },
      deep: true
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    isShowForwardImg() {//是否显示转发图
      return this.solitaireObj.forwardImg.includes("https://")//是否显示转发图
    }
  }
}
</script>

<style lang="scss" scoped>
.typeSolitaireSettings-box {
  background-color: #ebedf0;
  padding: 20px;
  text-align: left;
  width: 300px;

  .legendTitle {
    font-size: 18px;
    font-weight: bold;
  }

  .typeSolitaireSettings {
    .startAndEndTime-box {
      .startAndEndTime {
        width: 100%;
        box-sizing: border-box;
        min-height: 16px;
        line-height: 16px;
        padding: 10px;
        margin-top: 10px;
        font-size: 14px;
        border: 1px solid silver;
        //margin-top: 14px;
        border-radius: 4px;
        color: #949393;
        text-align: center;
      }

      .suggest {
        margin-top: 10px;
        color: #7d7d7d;
        font-size: 14px;
      }

      .forward {
        width: 50%;
        padding: 3px;
        border: 1px solid silver;
        border-radius: 2px;
        position: relative;

        .add-box {
          width: 150px;
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .item-box {
      display: flex;

      .item_right {
        margin-left: auto;
      }
    }
  }
}

.mgt30 {
  margin-top: 30px;
}

.mgt10 {
  margin-top: 10px;
}

.center {
  display: flex;
  justify-content: center;
}
</style>
<style>
.el-select {
  width: 100%;
}

.el-date-editor.el-input {
  width: 100% !important;
}

/*解决转发图下面空隙的问题*/
/*.el-image.el-image__inner {
  overflow: inherit !important;
}*/
</style>
