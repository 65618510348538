<template>
  <div class="navigationBar-box">
    <!--顶部 开始-->
    <div class="navigationBar">
      <div class="logo">
        <div class="iconfont icon-arrow-left-bold" @click="$router.go(-1)"></div>
      </div>
      <!--<div class="iconfont icon-liebiao more"></div>-->
      <div class="more" @click="manageMySolitaire" v-if="areYouLoggedIn">管理我的接龙</div>
      <div class="more" @click="scanCodeToLogIn1" v-else>登录</div>
    </div>
    <!--顶部 结束-->
    <div class="search-box">
      <!--<van-field class="search-input" :border="true" placeholder="请输入关键字" v-model="searchValue"  />-->
      <el-input v-model="searchValue" placeholder="请输入关键字"></el-input>
    </div>
    <div class="templateContent">
      <div class="template-bigType" v-for="(item,index) in tempalteContentList" :key="index">
        <div class="bigType-title">
          <div class="title-text">{{ item.typeName }}</div>
          <div class="more-box">
            <div class="more-text">更多 <span class="iconfont icon-arrow-right-bold icon"></span></div>
          </div>

        </div>
        <div class="template-list">
          <div class="template-item" v-for="(element,i) in item.templateList" :key="i">
            <el-image
              style="width:100%;height: 100px;"
              :src="element.showPicture"></el-image>
            <div class="template-title ellipsisOne">{{ element.title }}</div>
            <span class="template-type-text">{{ element.typeText }}</span>
          </div>
          <!--<div class="template-item more-box">-->
          <!--  <div class="more-text">-->
          <!--    更多模板-->
          <!--    <span class="iconfont icon-arrow-right-bold"></span>-->
          <!--  </div>-->
          <!--</div>-->
        </div>
      </div>
    </div>
    <div class="foot-box">
      <Foot></Foot>
    </div>
    <div class="carrier" v-if="isShowSunCode" @click.self="cancelLogin">
      <div class="sunCode-box">
        <div class="appletCode-box">
          <img class="appletCode" alt="首页图标" v-if="appletCode" :src="appletCode">
        </div>
        <div class="loginStatusText">{{ loginStatusText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {jlLogoNoPinYinG} from '@/assets/js/staticData/imageRequest';
import {test} from '@/assets/js/staticData/imageRequest';
import Foot from '@/components/FirstHomeAPP/FootAPP';
import {templateTitlelistFun} from '@/tempData/templateData';
import {getAPPTemplateCenterAPI} from '@/api/APP/template';
import {JSONparse, getLocalStorage, setLocalStorage} from '@/assets/js/tools/common';
import {PARTENCODE} from '@/utils/constant';
import {checkLogin} from '@/utils/logIn'

export default {
  name: 'TemplateCenterAPP',
  components: {Foot},
  data() {
    return {
      url: jlLogoNoPinYinG,
      isShowBgc: false,//是否显示导航栏的背景色
      searchValue: '',
      src: test,
      isShowSunCode: false,//是否显示登录太阳码
      loginStatusText: '加载中...',//登录状态说明
      areYouLoggedIn: false,//是否已登录
      appletCode: '',
      ws: '',//webSocket协议
      tempalteContentList: []
    }
  },
  created() {
    // window.addEventListener('scroll', this.handleScroll, true);
    // this.getTemplateTitle()
    let userInfo = getLocalStorage('userInfo')
    console.log('userInfo ==>', userInfo)
    if (userInfo) {
      this.areYouLoggedIn = true
      this.userInfo = userInfo
    }
    this.getTemplateContent()
  },
  methods: {
    /**
     * 跳转模板
     */
    jumpTemplate(label) {
      if (label === 'mbzx') {
        this.$router.push({name: 'Template'});
      }
    },
    /**
     * 点击扫码登录
     */
    scanCodeToLogIn1() {
      checkLogin.call(this) // 检查登录
    },
    /**
     * 取消登录
     */
    cancelLogin() {
      // this.appletCode = ''
      this.isShowSunCode = false
      this.ws.close()//关闭websocket协议连接
    },
    /**
     * 点击 管理我的接龙
     */
    manageMySolitaire() {
      this.$router.push({name: 'Solitaire'})
    },
    /**
     *  获取模板标题列表
     */
    async getTemplateTitle() {
      let templateTitleList = await templateTitlelistFun('APP')
      console.log('templateTitleList ==>', templateTitleList)
    },
    /**
     * 获取模板内容
     */
    async getTemplateContent() {
      this.tempalteContentList = await getAPPTemplateCenterAPI()
      console.log('tempalteContentList ==>', this.tempalteContentList)
    }
  }
}
</script>

<style lang="scss" scoped>
$QQWXbgc: rgba(255, 255, 255, .15);
.bgc {
  background-color: white;
}

.cgColor {
  color: #1a1a1a;
}

.add-border {
  border: 1px solid #e2e2e2 !important;
}

.navigationBar-box {
  width: 100vw;
  padding: 10px 16px;
  box-sizing: border-box;
  background-color: #e3e6e8;

  .navigationBar {
    width: 100%;
    display: flex;

    .logo {
      display: flex;
      align-items: center;
    }

    .more {
      margin-left: auto;
      //color: #8cf889;
      font-size: 14px;
    }
  }

  .search-box {
    margin: 20px 0;
  }

  .templateContent {
    .template-bigType {
      .bigType-title {
        margin: 20px 0;
        display: flex;

        .title-text {
          font-weight: bold;
          font-size: 18px;
        }

        .more-box {
          margin-left: auto;
          background-color: transparent;
          display: flex;

          .more-text {
            font-size: 14px;
            margin: auto;

            .icon {
              font-size: 14px;
            }
          }
        }
      }

      .template-list {
        display: flex;
        flex-wrap: wrap;

        .template-item {
          &:nth-of-type(2n-1) {
            margin-right: 10px;
          }

          width: calc(50% - 5px);
          margin: 5px 0;
          border-radius: 6px;
          overflow: hidden;
          background-color: white;
          font-size: 14px;
          padding-bottom: 10px;

          .template-title {
            padding-left: 4px;
            margin: 10px 0;
          }

          .template-type-text {
            padding: 4px 8px;
            //background-color: #18c631;
            border: 1px solid silver;
            color: #575757;
            margin-left: 4px;
            font-size: 12px;
            border-radius: 10px;
          }
        }


      }


    }
  }

  .foot-box {
    transform: translate(-10px);
    width: 100vw;
  }
}

.carrier {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;

  .sunCode-box {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;

    .appletCode-box {
      width: 250px;
      height: 250px;
      background-color: white !important;

      .appletCode {
        width: 100%;
        height: 100%;
        background-color: white !important;
      }
    }

    .loginStatus {
      font-size: 20px;
      color: #333333;
      line-height: normal;
      margin-top: 20px;
    }

    .loginStatusText {
      margin-top: 20px;
      text-align: center;
    }
  }
}
</style>

