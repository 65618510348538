/**
 * 获取移动端模板中心内容
 */
import {api, OSSHOST, PARTENCODE} from '../../utils/constant';
import {request} from '../../utils/request';
import {deepCopy} from '../../assets/js/tools/common';

export function getAPPTemplateCenterAPI() {
  return new Promise((resolve) => {
    request({
      url: `/solitaireMgr/templateAPI!loadTemplateTypeAndDetail.do`,
      params: {partenCode: PARTENCODE,sizeCount:4},
    }).then((res) => {
      // console.log('获取移动端模板中心内容 res ==>', res)
      if (res.data?.checkResult?.allowed) {
        let templateTypeList = deepCopy(res.data.checkResult.resultObject.templateTypeList)

        templateTypeList.forEach((item) => {
          item.templateList.forEach(element => {
            let typeList = ['反馈', '打卡', '填表', '投票', '活动', '', '作业']
            element.showPicture = OSSHOST + element.showPicture
            element.typeText = typeList[element.solitaireType-1]
          })
        })

        // console.log('templateList ==>', templateList)
        return resolve(templateTypeList)
      } else {
        console.log('获取移动端模板中心内容失败')
        resolve(false)
      }
    })
  })
}
