<template>
  <div v-loading.fullscreen.lock="loading">
    <div class="FKCY-Child">
      <el-col class="main-content" :xs="15" :sm="14" :md="12" :lg="10" :xl="9" v-if="obj">
        <div class="blockWrap">
          <CoverPicture v-if="obj.coverImg" :coverImg="obj.coverImg"></CoverPicture>
          <h2 class="title">{{ obj.title }}</h2>
          <el-alert
            type="warning"
            :center="true"
            :closable="false"
            :title="`结束时间：${obj.endTime}`">
          </el-alert>
          <SolitaireIntroduction v-if="obj.contentElementRelationlist" :list="obj.contentElementRelationlist"/>
        </div>
        <div class="blockWrap">
          <SolitaireCompletionSituation v-if="showSCS" :scs="scs"/>
        </div>
        <div class="blockWrap blockWrapScroll">
          <SolitairePartInRecord v-if="obj.id" :spr="obj"/>
        </div>
      </el-col>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import CoverPicture from '@/components/SolitaireDetails/components/CoverPicture'
import SolitaireIntroduction from '@/components/SolitaireDetails/components/SolitaireIntroduction'
import SolitaireCompletionSituation from '@/components/SolitaireDetails/components/SolitaireCompletionSituation'
import {legalityTest, numberDetection} from '@/components/SolitaireDetails/utils/stringValidityCheck'
import SolitairePartInRecord from '@/components/SolitaireDetails/components/SolitairePartInRecord'

export default {
  name: 'FKCY',
  components: {CoverPicture, SolitaireIntroduction, SolitaireCompletionSituation, SolitairePartInRecord},
  props: {
    id: Number,
    type: Number,
  },
  data() {
    return {
      loading: false,
      obj: '',
    }
  },
  computed: {
    showSCS() {
      const {isnameclock, namelistArr} = this.obj
      return (isnameclock === 1 || isnameclock === 2) && namelistArr.length > 0
    },
    scs() {
      const {
        id,
        isnameclock,
        namelistArr,
        rowcolumn,
      } = this.obj
      return {
        id,
        isnameclock,
        namelistArr,
        rowcolumn,
      }
    },
  },
  created() {
    // 获取路由参数
    this.loading = true
    const {userId, solitaireId} = this.$store.state.query
    // 获取接龙详情
    api.loadSolitaire({userId, solitaireId}).then(res => {
      // console.log('接龙详情', res.data)
      const {checkResult: {allowed, resultMessage, resultObject}} = res.data
      if (!allowed) {
        this.$message.error(resultMessage)
      } else {
        this.obj = resultObject.content
        const obj = this.obj

        // 1设定姓名、2设定编号、3不启用--默认不启用
        if (obj.isnameclock === 1) {
          obj.namelistArr = legalityTest(obj.namelist)
        } else if (obj.isnameclock === 2) {
          obj.namelistArr = numberDetection(obj.number, obj.numberLimits)
        }
        // 判断是不是发布者 或 管理员
        this.obj.isPublisher = this.obj.solitaireRole === 1 || this.obj.solitaireRole === 2
        this.obj = obj
      }
      this.loading = false
    }).catch(err => {
      console.log('获取接龙详情失败', err)
      this.loading = false
    })
  },
}
</script>

<style lang="scss" scoped>
.FKCY-Child {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;

  .main-content {
    padding-top: 20px;
    .blockWrap {
      margin-bottom: 10px;
      background-color: #fff;
      border-radius: 5px;

      .title {
        padding: 0 20px;
      }
    }
    .blockWrapScroll {
      height: calc(100vh - 60px);
      overflow: hidden;
    }
  }
}
</style>
