import {loadPriticipageNames} from '@/api'
import {updateSolitaireNamelistStatus} from '@/components/SolitaireDetails/utils/updateSolitaireNamelistStatus'

/**
 * 获取反馈接龙参与记录精简列表, 用于计算接龙完成情况
 */
export function getPriticipageNames() {
  return new Promise((resolve, reject) => {
    loadPriticipageNames({solitaireId: this.obj.id}).then(res => {
      const {checkResult: {allowed, resultMessage, resultObject}} = res.data
      if (!allowed) {
        this.$message.error(resultMessage)
        return
      }
      // console.log('获取反馈接龙参与记录精简列表, 用于计算接龙完成情况', resultObject)
      let {participateNamesList} = resultObject
      this.obj.part10000 = participateNamesList
      // 更新接龙完成情况
      let isnameclock = this.obj.isnameclock
      let namelistArr = this.obj.namelistArr
      const {namelist, count} = updateSolitaireNamelistStatus(isnameclock, namelistArr, participateNamesList)
      this.obj.namelistArr = namelist
      this.obj.count = count
      // console.log('更新接龙完成情况', namelist, count)
      resolve()
    }).catch(err => {
      console.log('获取反馈接龙参与记录精简列表, 用于计算接龙完成情况失败:', err)
      reject()
    })
  })
}
