/**
 * 设置接龙完成情况一行几个框
 */
import {updateContentRowcolumn} from '@/api'
import {getLocalStorage} from '@/assets/js/tools/common'

export function changeRowCol(index) {
  // 发送请求更改一行几列
  updateContentRowcolumn({
    solitaireId: this.obj.id,
    rowcolumn: index
  }).then(res => {
    let {checkResult: {allowed}} = res.data
    if (allowed) {
      this.obj.rowcolumn = index
      setRowColParams.call(this, index)
      this.$message.success('修改成功')
    } else {
      this.$message.error('修改失败')
    }
  }).catch(err => {
    console.log('一行几列修改失败:', err)
  })
}

/**
 * 设置一行几个参数
 */
export function setRowColParams(rowCol) {
  this.obj.aFew = rowCol
  this.obj.showNumber = rowCol * 7
  this.obj.defaultNumber = rowCol * 7

  // 设置一行几个样式
  this.contentStyle.gridTemplateColumns = `repeat(${rowCol}, 1fr)`
}

/**
 * 判断是否可以修改参与记录
 */
export function whetherCanModifyRecord(item) {
  const userId = getLocalStorage('userInfo').userId
  item.canModify = false // 默认不可修改
  // 反馈终止前、反馈后48小时内、反馈后24小时内、不可修改
  if (this.obj.isGhostupdate === 1) {
    if (new Date().getTime() < Date.parse(this.obj.endTime.replace(/-/g, '/')) && item.userId === userId) item.canModify = true
  } else if (this.obj.isGhostupdate === 2) {
    if (new Date().getTime() < (Date.parse(item.createTime.replace(/-/g, '/')) + 86400000) && item.userId === userId) item.canModify = true
  } else if (this.obj.isGhostupdate === 3) {
    if (new Date().getTime() < (Date.parse(item.createTime.replace(/-/g, '/')) + 172800000) && item.userId === userId) item.canModify = true
  }
}

const regExp = /^https:\/\/www\.dancimao\.com\/DCM|^https:\/\/jielongxiaoshenqi\.oss-cn-shenzhen\.aliyuncs\.com/g
const ossHost = 'https://oss.jielongshenqi.com'

/**
 * 替换参与记录域名
 * @param elementType 元素类型
 * @param content 元素内容
 */
export function replaceDomainName(elementType, content) {
  // console.log('替换参与记录域名', elementType, content)
  if (/图片/.test(elementType) && content instanceof Array) {
    for (let i = 0; i < content.length; i++) {
      content[i] = content[i].replace(regExp, ossHost)
    }
  } else if (/视频|录音/.test(elementType) && content) {
    content.tempFilePath = content.tempFilePath.replace(regExp, ossHost)
  } else if (/文件/.test(elementType) && content instanceof Array) {
    for (let i = 0; i < content.length; i++) {
      content[i].path = content[i].path.replace(regExp, ossHost)
    }
  } else if (/图文/.test(elementType) && content instanceof Array) {
    for (let i = 0; i < content.length; i++) {
      content[i] = content[i].replace(regExp, ossHost)
    }
  }
}

/**
 * 替换评论域名
 */
export function replaceCommentDomainName(str) {
  return str.replace(regExp, ossHost)
}
