import {defaultCoverImage} from '../../assets/js/staticData/createSolitairePage';
import {OSSHOST} from '../constant';
import {JSONparse} from '../../assets/js/tools/common';
import {getSolitaireObjAPI} from '../../api/createSolitaire/solitaireDetail';

/**
 * 获取SolitaireObj对象 数据
 */
export async function getSolitaireObj(solitaireConfig, params) {
  // console.log('solitaireConfig ==>', solitaireConfig)
  let solitaireObj = await getSolitaireObjAPI(params)
  let solitaireType = solitaireObj.solitaireType
  if (solitaireType === 7) {//在线作业模块
    solitaireObj.addInfoAndQuestionConfig = [
      {
        title: solitaireConfig.basicInfoObj.title,
        list: [
          {
            itemTitle: '文本',
            mark: 'info',
            icon: '',
          },
        ]
      },
      {
        title: '问题类型',
        list: []
      }
    ]

    solitaireConfig.elementConfiglist.forEach((item) => {
      if (item.text === '签名') {
        // let signatureData = solitaireObj.elementConfiglist.splice(j, 1)[0]
        let signatureObj = {
          itemTitle: '手写签名',
          mark: 'info',
          icon: '',
        }
        solitaireObj.addInfoAndQuestionConfig[0].list.push(signatureObj)
      } else {
        solitaireObj.addInfoAndQuestionConfig[1].list.push({
          itemTitle: item.text,
          mark: 'question',
          icon: '',
        })
      }
    })
  }

  //解析 solitaireObj 对象里面 JSON字符串
  solitaireObj = analyzeSolitaireObjJSONstr(solitaireObj)


  if (solitaireObj.coverImg.indexOf('https') === -1) {
    // 如果图片路径没有https，则是默认的封面图，需要加域名
    solitaireObj.coverImg = OSSHOST + (solitaireObj.coverImg || defaultCoverImage)
  }
  return solitaireObj
}

/**
 * 解析 solitaireObj 对象里面 JSON字符串
 */
function analyzeSolitaireObjJSONstr(solitaireObj) {
  solitaireObj.basicInfoObj = JSONparse(solitaireObj.basicInfoObj)//将字符串解析成对象

  //接龙介绍内容列表
  solitaireObj.contentElementRelationlist.forEach((item) => {
    let elementName = item.elementName;
    if (elementName === '小图' || elementName === '附件' || elementName === '视频') {
      if (item.describtion1.length > 0) {
        item.describtion1 = JSONparse(item.describtion1)
      }
      if (item.describtion2.length > 0) {
        item.describtion2 = JSONparse(item.describtion2)
      }
    }
  })

  //题目列表
  solitaireObj.elementRelationlist.forEach((item) => {
    item.defaultValue = JSONparse(item.defaultValue)
  })

  return solitaireObj;
}
