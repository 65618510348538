import {request} from '@/utils/request';
// import {templateTitleData} from '@/tempData/templateData';
import { OSSHOST, api} from '@/utils/constant';//获取接龙小神器简码  域名

/**
 * 获取模板标题
 */
// export function getTemplateTitle() {
//   return new Promise((resolve, resject) => {
//     request({
//       url: `/solitaireMgr/templateAPI!loadTemplateType.do`,
//       params: {
//         partenCode: PARTENCODE,
//       },
//     })
//       .then((res) => {
//         // console.log("获取模板标题 ==>", res);
//         if (res.data.checkResult.allowed) {
//           let templateTitleList =
//             res.data.checkResult.resultObject.templateTypeList;
//           templateTitleList.unshift({text: '全部', id: 3});
//           templateTitleList.forEach((item, index) => {
//             if (index == 0) {
//               item.name = 'All';
//             } else {
//               item.name = templateTitleData[index].name;
//               item.component = '';
//             }
//           });
//           resolve(templateTitleList);
//         } else {
//           console.log('获取模板标题数据失败！');
//           resject(false);
//         }
//       });
//   });
// }

/**
 * 获取模板标题对应的模板内容
 */
export function getTemplateContent(id) {
  return new Promise((resolve) => {
    let params = {sizeCount: 1000};
    if (id != 3) {
      params = {
        templateTypeId: id,
        sizeCount: 10000
      };
    }
    request({
      url: `/solitaireMgr/templateAPI!loadSolitaireTemplate.do`,
      params: params,
    }).then((res) => {
      // console.log("获取模板内容的数据 ==>", res);
      if (res.data.checkResult.allowed) {
        let templateList =
          res.data.checkResult.resultObject.solitaireTemplateList;
        templateList.forEach((item) => {
          item.showPicture = OSSHOST + item.showPicture;
        });
        resolve(templateList)
        // console.log("获取模板内容的数据 ==>", templateList);
      } else {
        resolve(false)
        console.log('获取模板内容失败，请检查 template.js文件 res ==>', res);
      }
    });
  })
}

