/**
 * 更新接龙完成状态
 * @param isnameclock 按名单反馈开关
 * @param namelist 反馈名单
 * @param participateList 反馈参与记录
 * @returns {{Array, Number}} 返回 新的反馈名单, 完成的百分比
 */
export function updateSolitaireNamelistStatus(isnameclock, namelist, participateList) {
  // console.log('更新接龙完成状态函数参数:', arguments)
  let count = 0
  // 如果参与记录为零, 则 namelist 中 done 全部复位为 false
  if (participateList.length === 0) {
    namelist.forEach(item => {item.done = false})
  } else {
    namelist.forEach(item => {
      if (isnameclock === 1) {
        // console.log('单名单反馈 --> 设定姓名:', item.id, item.name)
        for (let i = 0; i < participateList.length; i++) {
          if (participateList[i].key2 !== '') {
            if (participateList[i].key1 === item.name && participateList[i].key2 === item.id) {
              item.done = true
              item.userId = participateList[i].user_id
              count++
              // console.log(item.id, item.name, '已完成接龙')
              break
            } else {
              item.done = false
            }
          } else {
            if (participateList[i].key1 === item.name) {
              item.done = true
              item.userId = participateList[i].user_id
              count++
              // console.log(item.name, '已完成接龙')
              break
            } else {
              item.done = false
            }
          }
        }
      } else if (isnameclock === 2) {
        // console.log('单名单反馈 --> 设定编号:', item.id)
        for (let i = 0; i < participateList.length; i++) {
          if (participateList[i].key2 === item.id) {
            item.done = true
            item.userId = participateList[i].user_id
            count++
            item.name = participateList[i].key1 // 设定编号需要赋值姓名
            // console.log(item.id, item.name, '已完成接龙')
            break
          } else {
            item.done = false
            item.name = ''
          }
        }
      }
      // console.log(item.name, item.done)
    })
  }
  return {namelist, count}
}
