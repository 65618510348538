<template>
  <!--容器头部开始-->
  <div class="topBar">
    <!-- 返回按钮-->
    <div class="goback" @click="goBack">
      <span class="iconfont icon-arrow-left-bold"></span>
      <span class="goback-text">返回</span>
    </div>
    <!--返回按钮结束-->
    <div class="right">
      <slot name="typetemplate"></slot>
      <slot name="createSolitairePage"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Head',
  methods: {
    /**
     * 返回上一级
     */
    goBack() {
      this.$router.go(-1)
    },
  }
}
</script>

<style lang="scss" scoped>
//顶部
.topBar {
  display: flex;

  .goback {
    width: auto;
    margin-left: 20px;
    cursor: pointer;
    //左上角返回
    .goback-text {
      margin-left: 10px;
    }
  }

  .right {
    margin-left: auto;
    margin-right: 20px
  }
}
</style>
