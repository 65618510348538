import {request} from '@/utils/request';

/**
 * 获取用户的群组信息列表
 */
export function getGroupDataFnAPI(userId) {
  return new Promise((resolve) => {
    request({
      // url: '/solitaireMgr/groupAPI!loadMyGroup.do',
      url: `/solitaireMgr/groupAPI!loadMyGroup.do`,
      params: {userId},
    }).then((res) => {
      // console.log('获取用户的群组信息列表 res ==>', res)
      if (res.data?.checkResult?.allowed) {
        return resolve( res.data.checkResult.resultObject.groupList)
      } else {
        console.log('获取用户的群组信息列表数据有误 ==》',res)
        resolve(false)
      }
    })
  })
}