<template>
  <div class="first_home" ref="home">
    <div class="head" id="home">
      <NavigationBar @jump="jump"></NavigationBar>
      <div class="introduction">
        <div :class="{first:item.isBig,explanation:!item.isBig}" v-for="(item,index) in configuration.introductionTextlist" :key="index">{{ item.title }}</div>
        <!--<div class="data-box">问卷网已帮助 <span class="data">2,072万</span>用户收集超过 <span class="data">17.8亿</span>份数据</div>-->
        <div class="use">
          <div class="text" v-if="configuration.homeBtnText1.length>0" @click="freeToUse">{{configuration.homeBtnText1}}</div>
          <div class="text" v-if="configuration.homeBtnText2.length>0" @click="jumpTmplate">{{configuration.homeBtnText2}}</div>

        </div>
      </div>
      <div class="tips">
        <div class="iconfont icon-icon_jiantou-xia tipsFirstIcon"></div>
        <div class="iconfont icon-icon_jiantou-xia tipsSecondIcon"></div>
      </div>
    </div>
    <!--接龙介绍-->
    <div class=" type_introduce">
      <div class="h1" id="cpgn" ref="cpgn">接龙小神器，可满足您各类<span style="color: #18c631">数据收集</span>需求</div>
      <div class="type-content">
        <div class="type-item" :class="{noBorderBonttom1:typeList.length%3===1,noBorderBonttom2:typeList.length%3===2,noBorderBonttom3:typeList.length%3===0}" ref="typeItem" @mouseover="cgtypeListFun(index)  " @mouseleave="revealImgIndex=-1" v-for="(item,index) in typeList" :key="index">
          <div class="img-box">
            <el-image
              :src="item.smallimg"
              fit="contain"></el-image>
          </div>
          <div class="type-title">{{ item.title }}</div>
          <div class="text">{{ item.text1 }}</div>
          <div class="text">{{ item.text2 }}</div>
          <div class="reveal-img" ref="revealImgBox" v-show="index===revealImgIndex">
            <el-image
              :src="item.bigimg"
              ref="revealImgInfo"
              fit="contain"></el-image>
          </div>
        </div>
      </div>
      <div class="more-box" v-if="allTypeList.length>6">
        <div class="more" @click="cgTypeList">{{ typeList.length > 6 ? '收起' : '显示更多' }}</div>
      </div>
    </div>


    <!-- 简单三步 -->
    <div class="step-box">
      <div class="h1" id="yyzs" ref="yyzs">简单三步，可快速完成您的<span style="color: #18c631">数据收集</span></div>
      <div class="step-content">
        <!--第一步 第三步-->
        <div v-for="(contentItem,contentIndex) in circuitList" :key="contentIndex">
          <div class="first" v-if="contentIndex%2 === 0">
            <div class="texts-box-left">
              <div class="yardage"><span class="yardage-text">{{ contentIndex + 1 }}</span>/{{ circuitList.length }}</div>
              <div class="type-title">{{ contentItem.type }}</div>
              <div class="options">
                <div class="option" @click="cgCircuitObj(contentIndex,index)" :class="{active:contentItem.activeIndex===index}" v-for="(item , index) in contentItem.childList" :key="index">
                  <span class="iconfont icon-shuqian icon"></span>
                  {{ item.title }}
                </div>
              </div>
            </div>
            <!--<transition-group  tag="div" name="el-fade-in-linear">-->
            <div class="img-right">
              <div v-for="(item , index) in contentItem.childList" :key="item.id">
                <el-image
                  v-show="contentItem.activeIndex===index"
                  :src="item.bigimg"
                  :preview-src-list="previewSrcList"
                  fit="contain"></el-image>
              </div>
            </div>
            <!--</transition-group>-->
          </div>
          <!--第二步-->
          <div class="second" v-else>
            <!--<transition-group name="el-fade-in-linear">-->
            <div class="img-right" v-for="(item , index) in contentItem.childList" :key="item.id" v-show="contentItem.activeIndex===index">
              <el-image
                :src="item.bigimg"
                :preview-src-list="previewSrcList"
                fit="contain"></el-image>
            </div>
            <!--</transition-group>-->
            <div class="texts-box-right">
              <div class="yardage"><span class="yardage-text">{{ contentIndex + 1 }}</span>/{{ circuitList.length }}</div>
              <div class="type-title">{{ contentItem.type }}</div>
              <div class="options">
                <div class="option" @click="cgCircuitObj(contentIndex,index)" :class="{active:contentItem.activeIndex===index}" v-for="(item , index) in contentItem.childList" :key="index">
                  <span class="iconfont icon-shuqian icon"></span>
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--为什么选择接龙小神器-->
    <div class="why-select-jlxsq" v-if="configuration.isShowWhySelectInfo===1">
      <div class="bigTitle">{{ configuration.whySelectInfo.bigTitle }}</div>
      <div class="content-list">
        <div class="content-item" v-for="(item,index) in configuration.whySelectInfo.list" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="introduction-text">
            <div class="text">{{ item.text1 }}</div>
            <div class="text">{{ item.text2 }}</div>
            <div class="text" v-if="item.text3">{{ item.text3 }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 调研模型-->
    <!-- <div class="researchModel-box">
       <div class="h1">调研模型</div>

       <div class="researchModel-list">
         <div class="researchModel-item" v-for="(item,index) in 8" :key="index">
           <a href="">
             <el-image
               style="width: 100%"
               :src="researchModelUrl"
               fit="contain"></el-image>
           </a>
         </div>
       </div>
     </div>-->


    <!-- 接龙模板-->
    <!-- <div class="researchModel-box">
       <div class="h1">接龙模板</div>
       <div class="bbdb">更多的接龙模版，满足更多的业务场景</div>
       <div class="researchModel-list">
         <div class="template-box">
           <div class=" direction-box ">
             <div class="direction"><span class="iconfont icon-arrow-left-bold"></span></div>
           </div>
           <div class="template-list">
             <div class="template-item" v-for="(item,index) in 4" :key="index">
               <div class="title ellipsisOne">员工满意度调查表</div>
               <div class="template-item-content" v-for="(element,i) in 5" :key="i">
                 <div class="template-item-link-text ellipsisOne">
                   <a href="">安全制度考试(安全生产工作例会、安全生产教育培训、安全生产检查)</a>
                 </div>
               </div>
               <div class="more">更多</div>
             </div>
           </div>
           <div class="direction-box">
             <div class="direction"><span class="iconfont icon-arrow-right-bold"></span></div>
           </div>
         </div>
       </div>
     </div>-->
    <!--强大的模板库-->
    <div class="step-box" ref="ybfw">
      <TemplateLibrar></TemplateLibrar>
    </div>
    <!-- 版本对比-->
    <div class="researchModel-box">
      <div class="h1" style="padding-top: 150px;" ref="cpjg" id="banbenduibi">版本对比</div>
      <!--<div class="bbdb">接龙小神器为不同需求提供版本服务</div>-->
      <div class="researchModel-list">
        <div class="researchModel-item" v-for="(item,index) in versionComparisonImageList" :key="index">

          <el-image
            @click="banbenduibiFun(index)"
            style="width: 100%"
            :src="item"
            fit="contain"></el-image>

        </div>
      </div>
    </div>
    <div class="postion-bigbox" @click.capture="showCustomerServiceQRCode=!showCustomerServiceQRCode" v-if="showCustomerServiceQRCode">
      <div class="content-box">
        <div class="image-box">
          <el-image
            style="width: 100%"
            :src="getKefuQrCode"
            fit="contain"></el-image>
        </div>
        <div class="text">微信扫码添加微信客服开通</div>
      </div>
    </div>
    <Foot @getKefuQrCode="getKefuQrCodeFun"></Foot>

  </div>
</template>

<script>
import NavigationBar from '@/components/FirstHome/NavigationBar';
import TemplateLibrar from '@/components/FirstHome/TemplateLibrar';
import Foot from '@/components/FirstHome/Foot';
import {setLocalStorage} from '@/assets/js/tools/common';
import {getLoadWebContent1API, getLoadWebContent2API} from '@/api/firstHome/firstHome';
import {whySelectJlxsqData} from '../../assets/js/staticData/firstHome';
import {versionComparisonImageList} from '../../assets/js/staticData/imageRequest';

export default {
  name: 'FirstHomePC',
  components: {NavigationBar, TemplateLibrar, Foot},
  data() {
    return {
      configuration: {},//项目配置信息
      url: require('@/assets/img/Home/logo-wenjuan-active1.png'),
      revealImgIndex: -1,//类型模块展示长截图 的索引
      activeIndex: 0,
      allTypeList: [],//所有的模块的类型列表
      typeList: [],//显示的模块的类型列表
      circuitList: [], //流程列表
      timer: null,//定时器
      previewSrcList: [],//流程步骤的图片列表
      getKefuQrCode: '',//微信客服二维码
      whySelectObj: whySelectJlxsqData,
      versionComparisonImageList,//版本对比列表
      showCustomerServiceQRCode: false,//显示客服
      inputValue: '',
    }
  },
  created() {
    this.processCircuitObjData()//获取  简单三步 的数据以及数据的处理
    this.getTypeItemInfo() //获取类型介绍的数据以及数据的处理
    this.configuration = this.$store.state.configuration//项目配置信息
  },
  mounted() {
    // this.getTypeItemInfo()

  },
  methods: {
    /**
     * 点击导航执行的方法
     */
    jump(label) {
      let scrollIntoViewOptions = {
        behavior: 'smooth',
        block: 'start',
        inline: 'center'
      }
      this.$refs[label].scrollIntoView(scrollIntoViewOptions)
    },
    /**
     *点击版本对比
     */
    banbenduibiFun(index) {
      // console.log('index ==>', index)
      if (index === 0) {
        this.jumpTmplate()
      } else {
        this.showCustomerServiceQRCode = !this.showCustomerServiceQRCode
      }
    },
    /**
     * 修改类型列表
     */
    cgTypeList() {
      if (this.typeList.length <= 6) {
        this.typeList = this.allTypeList
      } else {
        this.typeList = this.allTypeList.slice(0, 6)
      }
    },
    /**
     * 获取客服二维码
     */
    getKefuQrCodeFun(res) {
      this.getKefuQrCode = res.kefuQrCode
    },
    /**
     * 点击免费使用
     */
    freeToUse() {
      this.$router.push({name: 'Template'})
      // this.$message('正在开发中')
    },
    /**
     * 获取 circuitList 数组的数据
     */
    async processCircuitObjData() {

      this.circuitList = await getLoadWebContent2API()
      let previewSrcList = []
      this.circuitList.forEach((item) => {
        item.childList.forEach((element) => {
          previewSrcList.push(element.bigimg);
        })
      })
      this.previewSrcList = previewSrcList;
      this.timedExecution()
    },

    /**
     * 跳转到模板中心页面
     */
    jumpTmplate() {
      this.$router.push({name: 'Template'})
    },
    /**
     * 定时执行
     */
    timedExecution() {
      this.timer = setInterval(() => {
        this.circuitList.forEach((item) => {
          let len = item.childList.length - 1
          if (item.activeIndex >= len) {
            item.activeIndex = 0
          } else {
            ++item.activeIndex
          }
          // console.log('activeIndex ==>', item.activeIndex)
        })
      }, 3000)
      setLocalStorage('timer', this.timer)

    },
    /**
     * 异步获取原生dom，处理数据
     */
    async getTypeItemInfo() {
      let allTypeList = await getLoadWebContent1API()
      this.allTypeList = allTypeList;
      this.typeList = allTypeList.slice(0, 6);
      let timer = setTimeout(() => {
        clearTimeout(timer)
        this.typeList.forEach((item, index) => {
          let fatherBoxWidth = parseInt(getComputedStyle(this.$refs.typeItem[index])['width']) //父盒子的宽度
          let fatherBoxHeight = parseInt(getComputedStyle(this.$refs.typeItem[index])['height'])//父盒子的高度
          let imageWidth = this.$refs.revealImgInfo[index].imageWidth
          let imageHeight = this.$refs.revealImgInfo[index].imageHeight
          allTypeList[index].minTop = -(fatherBoxWidth / imageWidth * imageHeight - fatherBoxHeight)//需要移动的长度
          allTypeList[index].duration = (-allTypeList[index].minTop / 100)  //每秒移动100px 需要移动的秒数
        })
        // console.log('typeList ==>', deepCopy(allTypeList))
        this.allTypeList = allTypeList
        this.typeList = allTypeList.slice(0, 6);
      }, 1000)
    },
    /**
     * 移入 类型模板时 触发
     */
    cgtypeListFun(index) {
      this.revealImgIndex = index
      let operatingObj = this.typeList[index]
      this.$refs.revealImgBox[index].style.animation = `mymove ${operatingObj.duration}s infinite linear`
      this.$refs.revealImgBox[index].style['-webkit-animation'] = `mymove ${operatingObj.duration}s infinite linear`
      let style = document.styleSheets[0];
      style.insertRule(`@keyframes mymove{from{ top: 0; }to{  top: ${operatingObj.minTop}px;}} `, 0);//写入样式
      style.insertRule(`@-webkit-keyframes mymove{from{ top: 0; }to{  top: ${operatingObj.minTop}px;}} `, 0);//写入样式
    },
    /**
     * 修改流程选中的索引
     */
    cgCircuitObj(contentIndex, index) {
      this.$set(this.circuitList[contentIndex], 'activeIndex', index)
      clearInterval(this.timer)
      let timer = setTimeout(() => {
        clearTimeout(timer)
        this.timedExecution()
      }, 1000)
    },
  },

  watch: {}
}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/FirstHome/animation";

$borderStyle: 1px solid #e5e5e5;
.first_home {
  //min-width: 1300px;
  .head {
    width: 100%;
    height: 100vh;
    //background: url("https://oss.jielongshenqi.com/data/jl/img1/home-bg-pc.jpg") no-repeat ;
    background: url("https://oss.jielongshenqi.com/data/jl/img1/jl-home-bg-pc.png") no-repeat;
    background-size: 100% 100%;
    position: relative;

    .tips {
      position: absolute;
      bottom: 20px;

      //left: -50%;
      left: 50vw;
      transform: translate(-75%);

      div {
        font-size: 50px;
        height: 40px;
      }

    }

    .introduction {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: white;

      div {
        margin: 15px 0;
      }

      .first {

        font-size: 40px;
      }

      .explanation {
        font-size: 30px;
        margin: 30px 0;
      }

      .data-box {
        font-size: 20px;

        .data {
          color: #ffbb00;
        }
      }

      .use {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        .text {
          font-size: 25px;
          //background-color: #07dc60;
          border-radius: 6px;
          padding: 15px 40px;
          cursor: pointer;
          transition: background-color, border .5s;
          border: 1px solid white;
          margin: 10px;

          &:hover {
            //background-color: #0aea33;
            background-color: #07dc60;
            border: 1px solid #07dc60;
          }
        }
      }
    }
  }


  .type_introduce {
    min-width: 1050px;
    margin: 0 auto 100px;

    .h1 {
      padding-top: 100px;
    }

    .type-content {
      width: 1050px;
      display: flex;
      flex-wrap: wrap;
      margin: 50px auto;

      .noBorderBonttom1 {
        &:nth-last-of-type(1) {
          border-bottom: none !important;
        }
      }

      .noBorderBonttom2 {
        &:nth-last-of-type(1) {
          border-bottom: none !important;
        }

        &:nth-last-of-type(2) {
          border-bottom: none !important;
        }
      }

      .noBorderBonttom3 {
        &:nth-last-of-type(1) {
          border-bottom: none !important;
        }

        &:nth-last-of-type(2) {
          border-bottom: none !important;
        }

        &:nth-last-of-type(3) {
          border-bottom: none !important;
        }
      }

      .type-item {
        width: 350px;
        box-sizing: border-box;
        text-align: center;
        padding: 40px 0;
        position: relative;
        overflow: hidden;
        border-bottom: $borderStyle;


        &:nth-of-type(3n+1),
        &:nth-of-type(3n+2) {
          border-right: $borderStyle;
        }

        .img-box {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .type-title {
          margin: 15px 0;
          font-size: 20px;
          font-weight: bold;
        }

        .text {
          font-size: 15px;
          margin: 6px;
          color: #999999;
        }

        .reveal-img {
          position: absolute;
          left: 0;
          top: 0;
          animation: mymove 15s infinite linear;
          /*Safari 和 Chrome:*/
          -webkit-animation: mymove 15s infinite linear;
        }
      }

    }

    .more-box {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: center;

      .more {
        padding: 10px 30px;
        border: 1px solid #939393;
        //color: #808080;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }

  .step-box {
    width: 1200px;
    margin: 0 auto 50px;

    .h1 {
      padding-top: 100px;
    }

    .step-content {
      margin-top: 100px;

      .first {
        display: flex;
        margin-top: 50px;

        .texts-box-left {
          width: 400px;
          font-size: 20px;

          .yardage {
            font-size: 25px;

            .yardage-text {
              font-size: 36px;
              font-weight: bold;
            }
          }

          .type-title {
            margin: 20px 0;
            font-size: 36px;
            //font-weight: bold;
          }

          .options {
            margin-top: 30px;

            .option {
              width: 375px;
              padding: 15px;
              margin: 10px 0;
              box-sizing: border-box;
              cursor: pointer;
              transition: all .5s;

              .icon {
                font-size: 20px;
              }
            }

            .active {
              font-weight: bold;
              //border: 1px solid silver;
              color: white;
              background-color: #18c631;
            }
          }
        }

        .img-right {
          margin-left: auto;
          flex: 1;
          transition: all 2s;
        }
      }

      .second {
        display: flex;
        margin-top: 50px;

        .img-left {
          flex: 1;
        }

        .texts-box-right {
          width: 400px;
          font-size: 20px;
          margin-left: auto;

          .yardage {
            font-size: 25px;
            text-align: right;

            .yardage-text {
              font-size: 35px;
              font-weight: bold;
            }
          }

          .type-title {
            margin: 20px 0;
            font-size: 35px;
            text-align: right;
          }

          .options {
            margin-top: 30px;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;

            .option {
              width: 375px;
              padding: 15px;
              margin: 10px 0;
              box-sizing: border-box;
              cursor: pointer;
              transition: all .5s;

              .icon {
                font-size: 20px;
              }
            }

            .active {
              font-weight: bold;
              //border: 1px solid silver;
              color: white;
              background-color: #18c631;
            }
          }
        }


      }
    }


  }

  .researchModel-box {
    width: 1200px;
    margin: 0 auto 150px;

    .researchModel-list {
      width: 100%;
      margin-top: 50px;
      display: flex;
      flex-wrap: wrap;

      .researchModel-item {
        width: 23%;
        margin: 1%;
        border-radius: 6px;
        overflow: hidden;
        cursor: pointer;
      }

      .template-box {
        display: flex;

        .direction-box {
          flex: 1;
          display: flex;
          justify-content: center;
          align-content: center;
        }

        .template-list {
          width: 1100px;
          display: flex;

          .template-item {
            width: 25%;
            padding: 20px 32px;
            box-sizing: border-box;

            .more {
              color: #0095ff;
            }
          }
        }
      }
    }
  }

  .postion-bigbox {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .6);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 1000;

    .content-box {
      padding: 40px;
      background-color: white;
      margin: auto;

      .text {
        text-align: center;
        margin-top: 10px;
      }
    }
  }

  .why-select-jlxsq {
    margin: 140px auto;
    width: 1200px;

    .bigTitle {
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      margin: 60px 0;

    }

    .content-list {
      display: flex;
      justify-content: space-evenly;

      .content-item {
        width: 33.3%;
        text-align: center;

        .title {
          font-size: 24px;
          font-weight: bold;
        }

        .introduction-text {
          margin-top: 20px;

          .text {
            font-size: 16px;
            color: #b0b0b0;
            margin-top: 6px;
          }
        }
      }
    }
  }

  /*---- screen6 ----*/

  .screen6 {
    width: 1200px;
    margin: 0 auto;
    padding: 60px 0;
  }

  .screen6 .title {
    padding-bottom: 60px;
    text-align: center;
    font-size: 36px;
  }

  .screen6 .content {
    overflow: hidden;
    display: flex;
  }

  .screen6 .content .left {
    width: 700px;
  }

  .screen6 .content .right {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .box {
      margin-left: 20px;

      p {
        color: #343434;
      }
    }
  }

  .screen6 .content .right img {
    width: 180px;
  }

  .screen6 .content .left li {
    display: flex;
    font-size: 24px;
    padding-bottom: 20px;
  }

  .screen6 .content .left li p {
    font-size: 24px;
  }

  .screen6 .content .right .box.mgr {
    margin-right: 45px;
  }

  .screen6 .content .right .box p {
    font-size: 24px;
    text-align: center;
    margin-top: 15px;
  }


  .tipsFirstIcon {
    color: #e8e6e3;
    animation: firstIcon 2s linear infinite reverse;
  }

  .tipsSecondIcon {
    color: #e8e6e3;
    animation: secondIcon 2s linear infinite reverse;
  }

  .h1 {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
  }

  .bbdb {
    text-align: center;
    font-size: 20px;
    color: silver;
    margin: 20px;
  }
}
</style>
<style>
.el-dialog-zdy {
  margin-top: 30vh !important;
}
</style>
