export const exampleValue = '【题目】这是个单选题目【单选】\n' +
  '【分值】5\n' +
  'A、单选一\n' +
  'B、单选二\n' +
  'C、单选三\n' +
  '【答案】A、单选一\n' +
  '\n' +
  '【题目】这是个多选题目【多选】\n' +
  '【分值】10\n' +
  'A、多选一\n' +
  'B、多选二\n' +
  'C、多选三\n' +
  'D、多选四\n' +
  '【答案】B、多选二 | C、多选三\n' +
  '\n'


//默认的封面图
export const defaultCoverImage = '/data/solitaire/config/16403904187667330.png'

/**
* 发布接龙的设置列表
*/
export let solitaireSetUpList = [
  {
    itemBoxClass:'mgt30 startAndEndTime-box',//每一项大盒子的类名
    type:'startTime',//类型
    text:'开始时间',//类型的文本
    contentBoxClass:'mgt10 center',//类型具体内容盒子的类名
    isShowList:[7]//要显示的接龙类型列表 7 表示 在线考试 类型的接龙发布显示
  },
  {
    itemBoxClass:'mgt30 startAndEndTime-box',
    type:'endTime',
    text:'结束时间',
    contentBoxClass:'mgt10 center',
    isShowList:[7]//要显示的接龙类型列表
  },
  {
    itemBoxClass:'mgt30 startAndEndTime-box',
    type:'nickname',
    text:'发布人',
    contentBoxClass:'mgt10',
    isShowList:[7]//要显示的接龙类型列表
  },
  {
    itemBoxClass:'mgt30 startAndEndTime-box',
    type:'managerMember',
    text:'管理员人数',
    contentBoxClass:'mgt10 ',
    isShowList:[7]//要显示的接龙类型列表
  },
  {
    itemBoxClass:'mgt30 startAndEndTime-box',
    type:'forwardImg',
    text:'设置转发图',
    suggest:'建议尺寸 500 × 400',//说明
    contentBoxClass:'mgt10 forward',
    isShowList:[7]//要显示的接龙类型列表
  },
  {
    itemBoxClass:'mgt30',
    type:'groupName',
    text:'关联群组',
    contentBoxClass:'mgt10 startAndEndTime',
    isShowList:[7]//要显示的接龙类型列表
  },
  {
    itemBoxClass:'mgt30',
    type:'solitaireCount',
    text:'每人最多可填次数',
    contentBoxClass:'mgt10 startAndEndTime',
    isShowList:[7]//要显示的接龙类型列表
  },
  {
    itemBoxClass:'mgt30 manyATime_fillIn item-box',
    type:'isForbidCopy',
    text:'禁止复制',
    contentBoxClass:'switch item_right',
    isShowList:[7]//要显示的接龙类型列表
  },
  {
    itemBoxClass:'mgt30 manyATime_fillIn item-box',
    type:'isForbidForward',
    text:'禁止转发',
    contentBoxClass:'switch item_right',
    isShowList:[7]//要显示的接龙类型列表
  },
]
