<template>
  <div class="cover">
    <el-image
      lazy
      fit="cover"
      :src="coverImg"
      :alt="altText">
    </el-image>
  </div>
</template>

<script>
export default {
  name: 'CoverPicture',
  props: {
    coverImg: String,
    altText: {
      type: String,
      default: '封面图片',
    },
  },
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}
</style>
