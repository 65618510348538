import {request} from '@/utils/request'

const jielongApi = process.env.VUE_APP_JIELONG_API
const appJson = {'Content-Type': 'application/json'}

const api = {
  editLogisticsInfo: '/solitaireMgr/logisticsAPI!editLogisticsInfo.do',
  loadLogisticsList: '/solitaireMgr/logisticsAPI!loadLogisticsList.do',
}

/**
 * 编辑物流信息
 */

export const editLogisticsInfo = (data) => request({
  baseURL: jielongApi,
  url: api.editLogisticsInfo,
  method: 'post',
  headers: appJson,
  data,
})

/**
 * 获取物流信息列表
 */
export const loadLogisticsList = (data) => request({
  baseURL: jielongApi,
  url: api.loadLogisticsList,
  params: data,
})
