export const SOLITAIRE_TYPE_LIST = [
  // {
  //   title: "信息收集",
  //   tips: "一次性快速收集信息",
  //   solitaireType: 1,
  //   image: require("@/assets/img/fk.png"),
  //   color: "#5a89f1",
  // },
  // {
  //   title: "打卡接龙",
  //   tips: "周期性多次收集信息",
  //   solitaireType: 2,
  //   image: require("@/assets/img/dk.png"),
  //   color: "#34bcff",
  // },
  // {
  //   title: "问卷调查",
  //   tips: "表单 问卷 登记 填表",
  //   solitaireType: 3,
  //   image: require("@/assets/img/tb.png"),
  //   color: "#9690ff",
  // },
  // {
  //   title: "投票评选",
  //   tips: "单选 多选 图文 匿名",
  //   solitaireType: 4,
  //   image: require("@/assets/img/tp.png"),
  //   color: "#42d4b0",
  // },
  // {
  //   title: "活动报名",
  //   tips: "报名 聚会 订餐 招募",
  //   solitaireType: 5,
  //   image: require("@/assets/img/hd.png"),
  //   color: "#6398f6",
  // },
  {
    title: "在线考试",
    tips: "考试 测验 评分 统计",
    solitaireType: 7,
    image: require("@/assets/img/zxks.png"),
    color: "#00928c",
  },
];
