// 接龙小神器简码
export const PARTENCODE = 'wxwebJlxsq';

//上传文件
export const OSSHOST = 'https://oss.jielongshenqi.com';

//上传 oss文件的域名
export const ossHostUpload = 'https://jielongxiaoshenqi.oss-cn-shenzhen.aliyuncs.com/'

// eslint-disable-next-line no-constant-condition

/*
let obj =
  {
    logo: {
      logoURL: '',//这是图片路径
      logoURL1: '',//这是图片路径
      validOrNot: 1,//是否有效 1表示有效  2表示无效
    },
    functionList: [//功能列表
      {
        text: '首页',
        eventType: 1,//事件类型 1本页跳转 2跳转到其他页面 3 二级菜单（下拉事件）
        jumpLabelOrPageName: 'home',//跳转到本页的标签位置 或者 跳转到其他页面
        validOrNot: 1,//是否有效 1表示有效  2表示无效
        secondaryMenuList: [
          {
            title: '客户体验管理',//标题
            imageUrl: '',//图片路径
            describe: '多渠道全触点聆听用户心声',//描述
            jumpInfo: '{jumpRouteName:Template,parmas:{solitaireId:30014}}',//跳转的信息
            validOrNot: 1,//是否有效 1表示有效  2表示无效
          },
          {
            title: '客户体验管理',//标题
            imageUrl: '',//图片路径
            describe: '多渠道全触点聆听用户心声',//描述
            jumpInfo: '{jumpRouteName:Template,parmas:{solitaireId:30014}}',//跳转的信息
            validOrNot: 1,//是否有效 1表示有效  2表示无效
          },
        ],//二级菜单列表
      },
      {
        text: '产品功能',
        eventType: 1,//事件类型 1本页跳转 2跳转到其他页面 3 二级菜单（下拉事件）
        jumpLabelOrPageName: 'cpgn',//跳转到本页的标签位置 或者 跳转到其他页面
        validOrNot: 1,//是否有效 1表示有效  2表示无效
        secondaryMenuList: [
          {
            title: '客户体验管理',//标题
            imageUrl: '',//图片路径
            describe: '多渠道全触点聆听用户心声',//描述
            jumpInfo: '{jumpRouteName:Template,parmas:{solitaireId:30014}}',//跳转的信息
            validOrNot: 1,//是否有效 1表示有效  2表示无效
          },
          {
            title: '客户体验管理',//标题
            imageUrl: '',//图片路径
            describe: '多渠道全触点聆听用户心声',//描述
            jumpInfo: '{jumpRouteName:Template,parmas:{solitaireId:30014}}',//跳转的信息
            validOrNot: 1,//是否有效 1表示有效  2表示无效
          },
        ],//二级菜单列表
      },
    ],
    wxIcon: {//微信图标
      iconURL: '',//图片路径
      iconURL1: '',//图片路径
      validOrNot: 1,//是否有效 1表示有效  2表示无效
    },
    QQIcon: {//QQ图标
      iconURL: '',//图片路径
      iconURL1: '',//图片路径
      validOrNot: 1,//是否有效 1表示有效  2表示无效
    },
    login: {
      text: '登录',
      validOrNot: 1,//是否有效 1表示有效  2表示无效
    },
    register: {
      text: '免费注册',
      validOrNot: 1,//是否有效 1表示有效  2表示无效
    }
  }


let test =
  [{
    text: '应用展示',
    jumpType: 1,//跳转类型 1本页跳转 2跳转到其他页面
    jumpLabelOrPageName: 'yyzs',//跳转到本页的标签位置 或者 跳转到其他页面
    validOrNot: 1,//是否有效 1表示有效  2表示无效
  },
    {
      text: '样本服务',
      jumpType: 1,//跳转类型 1本页跳转 2跳转到其他页面
      jumpLabelOrPageName: 'ybfw',//跳转到本页的标签位置 或者 跳转到其他页面
      validOrNot: 1,//是否有效 1表示有效  2表示无效
    },
    {
      text: '产品价格',
      jumpType: 1,//跳转类型 1本页跳转 2跳转到其他页面
      jumpLabelOrPageName: 'cpjg',//跳转到本页的标签位置 或者 跳转到其他页面
      validOrNot: 1,//是否有效 1表示有效  2表示无效
    },
    {
      text: '模板中心',
      jumpType: 2,//跳转类型 1本页跳转 2跳转到其他页面
      jumpLabelOrPageName: 'mbzx',//跳转到本页的标签位置 或者 跳转到其他页面
      validOrNot: 1,//是否有效 1表示有效  2表示无效
    }]


//项目配置
let configuration = {
  logoURL: '',//图片
  logoURL1: '',//图片
  backgroundImage: '',//背景图片
  kefuQrCode: '',//客服二维码图片
  partenCode:'',//小程序的简码
  introductionText: '',//介绍内容  多行文本
  isShowWXIcon: 1,//微信图标是否显示 1表示显示 2表示不显示
  isShowQQIcon: 1,//QQ图标是否显示 1表示显示 2表示不显示
  isShowLogin: 1,//登录按钮是否显示 1表示显示 2表示不显示
  isShowRegister: 1,//注册按钮是否显示 1表示显示 2表示不显示
  isShowWhySelectInfo: 1,//为什么选择 是否显示 1表示显示 2表示不显示
  whySelectInfo: '',//为什么选择... 的信息   多行文本
  homeBtnText1:'',//首页按钮文本1    如果为空则是不显示此按钮
  homeBtnText2:'',//首页按钮文本2    如果为空则是不显示此按钮
  address: '',//公司地址  多行文本
  phone: '',//电话号码
  facsimile: '',//传真
  postcode: '',//邮编
  mailbox: '',//邮箱
  recordNumberInfo: '',//备案号信息
  recordNumber: '',//备案号
}

// 版本对比
let versionComparison = {
  bigTitle: '版本对比',
  versionsList: [
    {
      imageURL:'',//图片
      eventType: 1,//事件类型 1本页跳转 2跳转到其他页面 3 点击显示客服二维码
      jumpLabelOrPageName: 'cpgn',//跳转到本页的标签位置 或者 跳转到其他页面路径
      validOrNot: 1,//是否有效 1表示有效  2表示无效  （无效时，后台就不要返回这个数据给前端了）
    },
    {
      imageURL:'',//图片
      eventType: 1,//事件类型 1本页跳转 2跳转到其他页面 3 点击显示客服二维码
      jumpLabelOrPageName: '{jumpRouteName:Template,parmas:{solitaireId:30014}}',//跳转到本页的标签位置 或者 跳转到其他页面
      validOrNot: 1,//是否有效 1表示有效  2表示无效  （无效时，后台就不要返回这个数据给前端了）
    },
  ]
}
*/
