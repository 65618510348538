import {isArray} from 'vuescroll/src/shared/util';

const {OSSHOST} = require('../../../utils/constant');

/**
 *为远程路径 拼接域名
 * @param url 字符串 没有http的路径
 */

export function spliceOSSHOST(url) {
  if (!url) {
    return url
  }
  if(url.includes('http')){
    return url ;
  }
  if (!/^\//.test(url)) {
    url = `/${url}`
    console.warn('路径缺少 ”/“，已补全 ')
  }
  return `${OSSHOST}${url}`
}

/**
 * 数组位置交换
 * @param arr 数组
 * @param index1 交换位置的数组索引 无所谓顺序大小
 * @param index2 交换位置的数组索引 无所谓顺序大小
 * @return
 * 1. 所传参数类型不符合  返回 原数据
 * 2. 所传的索引超出数组的取值范围：返回原数组
 * 3. 符合交换位置的条件：返回 位置交换后的数据
 */
export function arrayLocationExchange(arr, index1, index2) {
  if (isArray(arr) && !isNaN(index1) && !isNaN(index2)) {
    let len = arr.length
    if (len > index1 && len > index2) {
      [arr[index1], [arr[index2]]] = [arr[index2], [arr[index1]]]
      return arr
    }
    console.warn('arrayLocationExchange 数组位置交换 ==> 所传的索引超出数组的取值范围')
    return arr
  }
  console.warn('arrayLocationExchange 数组位置交换==>交换位置所传的参数不符合')
  return arr;
}
